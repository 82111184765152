import React from 'react'

function TermsScreen() {
  return (
    <>
    <div className="page t_condition">
  <div className="container">
    <div className="row">
      <div className="content_page">
        <div className="col-md-12">
          <h1>Terms and Conditions for Online Booking and Payment</h1>
          <p>
            These terms and conditions ("<strong>Terms</strong>”) are applicable
            and binding on any user/visitor/prospective customer (individual
            user or company/firm) ("<strong>User</strong>” or “
            <strong>You</strong>” or “<strong>Your</strong>”) of Revolt
            Intellicorp Private Limited ("<strong>Revolt</strong>”), who
            accesses or uses{" "}
            <a href="/">www.revoltmotors.com</a>{" "}
            ("Platform”) to make the payment of the booking amount in accordance
            with the payment plan selected against the specific colour and model
            of Revolt’s electric motorcycle (hereinafter referred to as “
            <strong>Revolt RV</strong>”), in accordance with:
          </p>
          <ol type="i">
            <li>
              The payment plan selected ("<strong>My Revolt Plan</strong>”); or
            </li>
            <li>
              The cash down option selected ("<strong>Revolt Cash Down</strong>”
              or “<strong>RCD</strong>”){" "}
            </li>
          </ol>
          <p>
            This booking option is made available on the condition that the user
            accepts these Terms.
          </p>
          <p>&nbsp;</p>
          <div className="t_condition">
            <ol className="p-0 m-0">
              <li>
                <h4 className="list-heading m-0">TERMS AND CONDITIONS </h4>
                <p>
                  The User hereby agrees, acknowledges and confirms that the
                  User is: (i) a holder of a valid driving licence issued under
                  the Motor Vehicles Act, 1988; (ii) not prohibited from owning
                  a vehicle in the jurisdiction of purchase; (iii) the Revolt RV
                  (model and colour) and dealership details (“Dealership”) are
                  as per the selection of the User; (iv) can understand, read
                  and write in the English language; and (v) has read,
                  understood and agrees to be bound by these clickable Terms.
                </p>
                <p>
                  The User acknowledges the images and colours on the Platform
                  are representation of the actual Revolt RV. Revolt has taken
                  every effort to make a true representation. However the actual
                  images and colour depends on the purchased vehicle of the User
                  and cannot be guaranteed by Revolt.
                </p>
                <p>
                  If the User is booking or purchasing on behalf of another
                  person, the necessary details of such other person is required
                  to be mentioned and the requisite details of such other person
                  are required to be produced at the Dealership.
                </p>
                <p>
                  The User agrees and confirms that the below information shared
                  by the User on the Platform for the booking of the Revolt RV
                  is true and accurate:
                </p>
                <ol>
                  <li>Full Name</li>
                  <li>Mobile No</li>
                  <li>Email Address and City</li>
                </ol>
                <p>
                  The User agrees and understands that these details as provided
                  by the User would be used by Revolt and the Dealership to
                  communicate with the User.
                </p>
                <p>
                  All formalities with respect to purchase of the Revolt RV,
                  including but not limited to the registration process shall be
                  performed at the respective dealership. The sale and delivery
                  of the Revolt RV shall be subject to fulfilment of all
                  applicable statutory obligations and submission of requisite
                  supporting documents. The originals of these documents will
                  need to be produced at dealership. In the event of the user’s
                  failure to ensure compliance of these requirements, the
                  booking or purchasing amount paid by the user is liable to be
                  forfeited. The user warrants that the user has the required
                  documents.
                </p>
                <p>
                  <strong>
                    APPLICABLE TO USERS WHO HAVE MADE THE PRE-BOOKING.
                  </strong>{" "}
                  The User agrees and understands that the pre-booking amount of
                  INR. 1000/- (Rupees One Thousand Only) shall be deducted from
                  the My Revolt Plan as selected by the User and on payment of
                  the booking for the Revolt RV, the User would have to pay the
                  remaining amount as per the My Revolt Plan selected by the
                  User.
                </p>
              </li>
              <li>
                <h4 className="list-heading m-0">ACCEPTANCE OF BOOKING</h4>
                <p>
                  On completion of payment of the booking amount in accordance
                  with:
                </p>
                <p>
                  <strong>My Revolt Plan:</strong>
                  <br /> User would be required to complete the KYC process with
                  the lending partner facilitating the financing of the User’s
                  selected My Revolt Plan. On successful completion of the
                  lending partner’s KYC, the User would have to visit the
                  Dealership to complete the subsequent paperwork.
                </p>
                <p>
                  The user must complete the verification of documents at
                  dealership within 7 (seven) days for users who have made
                  prebooking and 10 (ten) days for other users, from the date of
                  completion of KYC.{" "}
                </p>
                <p>
                  Please note, if the user fails to make payment in a timely
                  manner in accordance with the My Revolt Plan selected by the
                  user and confirmed by the lending partner, revolt reserves the
                  right to disconnect the user’s access to the Revolt RV and
                  stall it from being used till payment is completed to the
                  satisfaction of the lending partner. Further, failure to make
                  payment may result in repossession.
                </p>
                <p>
                  The booking amount paid by the User in accordance with the My
                  Revolt Plan selected by the User is made through a payment
                  gateway and accordingly the User is subject to the terms and
                  conditions as provided by the payment gateway intermediary/
                  merchant service provider. Revolt would not be responsible for
                  any non-payment or wrong payment/accuracy or completeness of
                  the information, services or process of the third party site.
                </p>
                <p>
                  Until the completion of the lending partner’s KYC and the
                  completion of submission of accurate documentation and
                  completion of paperwork at the Dealership, the online booking
                  is merely a request on part of the User and an indication of
                  an intention to sell on the part of Revolt and does not result
                  in a booking or purchasing confirmation or contract of sale
                  and does not impose financial implications on Revolt, the
                  lending partner or the Dealership except as provided herein in
                  these terms and conditions.
                </p>
              </li>
              <li>
                <h4 className="list-heading m-0">REVOLT CASH DOWN</h4>
                <p>
                  User would be required to complete the subsequent paperwork
                  and process of submitting their Aadhaar Card, PAN Card and
                  address proof (carry both original and copies of all
                  documentation for verification) and make payment of the
                  remaining amount at the Dealership at the earliest, but no
                  later than 15 (fifteen) days from the date of making the
                  payment of the booking amount.
                </p>
                <p>
                  The booking amount paid by the User in accordance with the RCD
                  option selected by the User is made through a payment gateway
                  and accordingly the User is subject to the terms and
                  conditions as provided by the payment gateway
                  intermediary/merchant service provider. Revolt would not be
                  responsible for any non-payment or wrong payment / accuracy or
                  completeness of the information, services or process of the
                  third party site.
                </p>
                <p>
                  Until the completion of the subsequent paperwork and process
                  of submitting Aadhaar Card, PAN Card and address proof (carry
                  copies for submission and originals for verification) along
                  with any additional documentation and payment of the remaining
                  amount at the Dealership, the online booking is merely a
                  request on part of the User and an indication of an intention
                  to sell on the part of Revolt and does not result in a booking
                  or purchasing confirmation or contract of sale and does not
                  impose financial implications on Revolt or the Dealership
                  except as provided herein in these terms and conditions.
                </p>
              </li>
              <li>
                <h4 className="list-heading m-0">BOOKING TERMS</h4>
                <p>
                  Revolt retains the right to revise the specification, standard
                  fitment and/or accessories of/for the Revolt RV or introduce
                  new variants or their versions or discontinue earlier variants
                  or versions.
                </p>
                <p>
                  Revolt will not be responsible for any delay, loss or
                  non-receipt of online booking information or any other form of
                  submission not contemplated herein Revolt reserves the right
                  to change the Dealership for the purpose of sale and delivery
                  of the Revolt RV as selected by the User and the same shall be
                  communicated to the User through email/message.
                </p>
                <p>
                  Irrespective of the payment method selected (My Revolt Plan or
                  Revolt Cash Down), the waiting period and the expected
                  delivery slot are communicated by Revolt prior to the User
                  making payment of the booking amount. For priority in the
                  selected delivery slot, Users are requested to complete the
                  KYC (where applicable) and relevant paperwork process at the
                  Dealership at the earliest.
                </p>
                <p>
                  <strong>CANCELLATION POLICY</strong>
                  <br />
                  Booking cannot be cancelled after the delivery date has been
                  communicated to the “User” by “Revolt”. However, if the user
                  wishes to cancel the booking, the user can do the same within
                  14 days (2 weeks) before the delivery date. For example, if
                  the delivery date of the preferred RV is October 10th, 2019,
                  the user is only entitled to cancel the booking until
                  September 27th, 2019. The booking amount, as applicable, will
                  be refunded net of transaction charges to the same account
                  from where the booking was generated.
                </p>
                <p>
                  Cancellation request, if any, should be placed by the user via
                  clicking on the{" "}
                  <a href="/searchbooking">
                    Cancel My Revolt
                  </a>{" "}
                  tab on revoltmotors.com.
                </p>
                <p>
                  The booking amount shall be refunded to the User through the
                  same mode that was used for making the payment of the booking
                  amount. It may take 25-30 days for the amount to be refunded.
                  No cash payments would be made by Dealership or Revolt to the
                  User. If any amount is paid offline by you to the dealership
                  towards the price of the Revolt RV, accessories or statutory
                  requirement, then you have to claim the said amount offline
                  from the Dealership itself.
                </p>
                <p>
                  Please note, no changes and/or modification are allowed online
                  for the revolt rv after the booking amount has been paid and
                  the booking has been made. For any change/modification, the
                  existing booking is to be cancelled and a fresh booking is
                  required to be made.
                </p>
                <p>
                  Please note there shall be no interest payable by revolt or
                  the dealership for any repayment against cancellations made in
                  accordance with these terms.
                </p>
              </li>
              <li>
                <h4 className="list-heading m-0">COMMUNICATION</h4>
                <p>
                  The User agrees, understands and acknowledges that
                  transmission of information through internet is not a secure
                  means of sending information and the same may be subject to
                  tampering and unauthorized access, fraudulently or mistakenly
                  written, altered or sent, and not be received in whole or in
                  part by the intended recipient and which may affect the
                  availability and performance of this online facility provided
                  by Revolt. Accordingly, the User shall not hold Revolt liable
                  for any loss/damage, whether direct or indirect, arising out
                  of or resulting from such tampering and unauthorized access,
                  frauds, mistakes, etc.
                </p>
                <p>
                  The User authorizes Revolt to exchange or share information
                  and details relating to the User’s booking of the Revolt RV to
                  the Dealership, Revolt’s group companies and other third-party
                  agents/representatives as may be required or as it may deem
                  fit for the purpose of facilitating the booking and/or related
                  offerings or other products/services that the User may apply
                  for from time to time. The User agrees and confirms that the
                  User shall not hold Revolt (or any of its group companies or
                  its/their agents/representatives) liable for use/sharing of
                  such information.
                </p>
                <p>
                  The User understands that by sharing the User’s contact
                  information, the User hereby provides consent for receiving
                  communications from Revolt, its group companies, agents/
                  representatives in relation to the Revolt RV including
                  promotional communications through any mode (including without
                  limitation through telephone calls/SMSs/WhatsApp/Emails). The
                  User confirms that the User shall not challenge receipt of
                  such communications as unsolicited communication, defined
                  under TRAI Regulations on Unsolicited Commercial
                  Communications.
                </p>
                <p>
                  The User understands and undertakes to keep the User’s
                  credentials including order details/ booking information, as
                  confidential and shall not share the same with any third
                  party. Any use or misuse of the same including the
                  transactions carried shall be the sole responsibility of the
                  User and Revolt shall not be held responsible for any
                  loss/damage incurred on account of such use, misuse and/or
                  transactions.
                </p>
                <p>
                  All parties shall ensure the compliance of the applicable
                  provisions of the Information Technology Act, 2000 and its
                  allied rules as amended from time to time.
                </p>
              </li>
              <li>
                <h4 className="list-heading m-0">GENERAL TERMS</h4>
                <p>
                  Also, as per the Government Regulations, to avail the benefit
                  of the subsidy under Phase II of the Faster Adoption and
                  Manufacturing of Hybrid and Electric Vehicles (FAME II),
                  Aadhaar Card is mandatory. The Aadhar card submitted can be
                  used only once to avail a FAME II subsidy, if the subsidy had
                  been availed in the past, the User shall not be eligible.
                </p>
                <p>
                  Revolt reserves the right to alter/change the terms and
                  conditions of the process/ offer without prior notice at its
                  sole discretion as and when considered necessary.
                </p>
                <p>
                  In an event of the demise of User, the booking of the Revolt
                  RV stands cancelled and the booking amount will be refunded as
                  per Revolt’s refund policy.
                </p>
                <p>
                  In case of any dispute, inconvenience or loss occurs due to
                  User negligence, non-compliance or breach of applicable law,
                  the User agrees to indemnify Revolt, the lending partner and
                  the Dealership.
                </p>
                <p>
                  In the case of non-individual User, delivery would be made to
                  the same authorized signatory, who booked the Revolt RV.
                </p>
                <p>
                  User agrees to defend, indemnify and hold Revolt harmless from
                  and against any and all claims, damages, costs and expenses,
                  including attorneys’ fees, arising from or related to User’s
                  use of the Platform.
                </p>
                <p>
                  Unless otherwise specified, the platform and its contents
                  thereof are displayed solely for the purpose of promoting
                  revolt’s products and services.
                </p>
                <p>
                  In case of any dispute relating to the transaction or any
                  other matter arising for use of this Platform for the purpose
                  of booking, the same will be subject to the exclusive
                  jurisdiction of the appropriate Court at Delhi.
                </p>
                <p>
                  Revolt reserves the right to amend these Terms and/ or
                  withdraw or discontinue the online booking service without
                  prior notice.
                </p>
                <p>
                  Non-acceptance of any of these terms and conditions will
                  result in disqualification.
                </p>
                <p>
                  The offer to make a booking of the Revolt RV subject to these
                  terms and conditions is optional and the User may choose not
                  to book the Revolt RV in response to the offer.
                </p>
              </li>
              <ol>
                <h2>Revolt Motors Referral Program</h2>
                <p>&nbsp;</p>
                <p>
                  <span style={{ textDecoration: "underline" }}>
                    <strong>Terms &amp; Conditions</strong>
                  </span>
                </p>
                <ol>
                  <li>
                    Revolt Referral Program ("<strong>Program</strong>”) is
                    provided by Revolt Intellicorp Private Limited ("
                    <strong>Revolt</strong>”) at its sole discretion. This
                    Program is valid in 70 cities except in the cities where
                    prohibited by law.
                  </li>
                  <li>
                    Validity period for the Program will be from 21st Oct ‘2021
                    – 31st Oct ‘2021
                  </li>
                  <li>
                    This Program is valid for Indian residents who have attained
                    the age of 18 years or above except for Revolt employees or
                    other corporate employees.
                  </li>
                  <li>
                    Under the Revolt Referral Program, participant(s) may refer
                    their friends or family to earn benefits. Refer Annexure A
                    for the benefits.
                  </li>
                  <li>
                    The participant referring under this Program will be called
                    as “Referrer” and the referred person will be called as
                    “Beneficiary”.
                  </li>
                  <li>
                    Participation Criteria: Upon fulfillment of either of the
                    below mentioned conditions the participant may start
                    referring others-
                  </li>
                  <table
                    style={{ borderCollapse: "collapse", width: "100%" }}
                    border={1}
                    cellPadding="4px"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "5%" }}>
                          <strong>S. No.</strong>
                        </td>
                        <td style={{ width: "20%" }}>
                          <strong>Condition Precedent</strong>
                        </td>
                        <td style={{ width: "20%" }}>
                          <strong>Eligible for Referring</strong>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "5%" }}>1.</td>
                        <td style={{ width: "20%" }}>
                          Must be a Revolt Bike Owner
                        </td>
                        <td style={{ width: "20%" }}>
                          Immediately from the date of launch of the Program
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "5%" }}>2.</td>
                        <td style={{ width: "20%" }}>
                          Must have booked a Revolt Bike in the past by paying
                          the booking amount.
                        </td>
                        <td style={{ width: "20%" }}>
                          Upon completion of purchase
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <li>
                    Every Referrer will get a unique code which he/she can share
                    with his/her friend or family, who in this case will be
                    called as the Beneficiary.
                  </li>
                  <li>
                    The Beneficiaries will be eligible to receive incentives, in
                    the form of discounts on the product value, as described in
                    Annexure A (refer clauses below).
                  </li>
                  <li>
                    Referrer may pass on his/her unique code to as many
                    Beneficiaries. Referrers cannot redeem their own referral
                    codes.
                  </li>
                  <li>
                    The Referral code can be redeemed by the Beneficiaries on
                    purchase of RV400 as listed in Annexure A, from{" "}
                    <a href="/">
                      www.revoltmotors.com
                    </a>
                  </li>
                  <li>
                    Referrer will be eligible to receive INR 1000 on his/her
                    registered email id or mobile number approximately 10 days
                    after the Beneficiary books their discounted product as long
                    as the Beneficiary does not cancel the product purchased.
                  </li>
                  <li>
                    In case the Beneficiary cancels the product for any reasons
                    whatsoever, the Referrer will not be eligible to receive the
                    benefit against that purchase.
                  </li>
                  <li>
                    In case the Beneficiary cancels the product post the benefit
                    is passed on to Referrer; Referrer needs to pass back the
                    amount to Revolt within 7 working days.{" "}
                  </li>
                  <li>
                    Revolt reserves the right to reverse any accumulated rewards
                    believed to be earned through suspicious activity.
                  </li>
                  <li>
                    Revolt reserves the right to substitute, change, cancel or
                    add to any part of these Terms at any time, including
                    terminating the Program or modifying these Terms. Revolt
                    reserves the right to decline or cancel your participation
                    in the Program, with or without advance notice, if we
                    believe that your participation is enabling a fraudulent or
                    illegal purpose.
                  </li>
                </ol>
                <p>&nbsp;</p>
                <h2>ANNEXURE A</h2>
                <p>
                  Eligible Bikes for Beneficiary purchase highlighted below. All
                  variants of the below mentioned Bike are included in this
                  Program.
                </p>
                <br />
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    height: 54
                  }}
                  border={1}
                  cellPadding="4px"
                >
                  <tbody>
                    <tr style={{ height: 18 }}>
                      <td style={{ width: "20%", height: 18 }}>
                        <strong>Eligible Models</strong>
                      </td>
                      <td style={{ width: "20%", height: 18 }}>
                        <strong>Beneficiary Discount</strong>
                      </td>
                      <td style={{ width: "20%", height: 18 }}>
                        <strong>Referrer Incentive</strong>
                      </td>
                    </tr>
                    <tr style={{ height: 18 }}>
                      <td style={{ width: "20%", height: 18 }}>
                        Revolt RV400 (Rebel Red, Cosmic Black &amp; Mist Grey)
                      </td>
                      <td style={{ width: "20%", height: 18 }}>₹1000</td>
                      <td style={{ width: "20%", height: 18 }}>₹1000</td>
                    </tr>
                  </tbody>
                </table>
                <ol style={{ listStyleType: "lower-alpha" }}>
                  <li>
                    The validity of these Codes will be till Oct 31<sup>st</sup>
                    .
                  </li>
                  <li>
                    Referrer can receive INR 1000 for every successful
                    beneficiaries (referrals, who complete successful bookings,
                    without cancellation).
                  </li>
                  <li>
                    Beneficiaries receive upfront discount in the booking amount
                    in the form of INR 1000.
                  </li>
                </ol>
              </ol>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    
    </>
  )
}

export default TermsScreen
