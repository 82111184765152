import Axios from 'axios';
//import Razorpay from "razorpay";
//import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CheckoutSteps from '../components/CheckoutSteps';

import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
export default function OrderScreen(props) {
  const navigate = useNavigate();
  const params = useParams();
  const { id: orderId } = params;
  const [amount, setAmount] = useState('');
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, setLoading, error } = orderDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  const dispatch = useDispatch();
  useEffect(() => {
    function loadRazorpay(order) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onerror = () => {
        alert('Razorpay SDK failed to load. Are you online?');
      };
      script.onload = async () => { 
      var razorpayKey = 'rzp_test_p9dRfJfoIuwhPL';  
         
       // var razorpayKey='rzp_live_pGsso02Apeh5X3';
       
       // let  orderAmount =100;
        try {
/*
       {  "error": {    "code": "BAD_REQUEST_ERROR",    "description": "Authentication failed due to incorrect otp",    "field": null,    "source": "customer",    "step": "payment_authentication",    "reason": "invalid_otp",    "metadata": {      "payment_id": "pay_EDNBKIP31Y4jl8",      "order_id": "order_DBJKIP31Y4jl8"    }  }}
        var headers = {};

        const options_order = {
            amount: 10,
            currency: 'INR',
            reciept:"reciept_"+orderId.toString(),
            mode: 'cors',
            headers: headers

        };
        const result = await instance.orders.create(options_order);
        const { amount, id: order_id, currency } = result.data;
        console.log(result); 
       //  setLoading(true);
       const result = await Axios.post('https://api.razarpay.com/v1/orders', {
            "amount": amoun,
            "currency":"INR",
            "reciept":"reciept_"+orderId.toString()
          });  
          const { amount, id: order_id, currency } = result.data; 
          const {
            data: { key: razorpayKey },
          } = await Axios.get('/get-razorpay-key'); */
          
         //id: "order_JShbM2Yihhj7o7"
         //const { amount, id: order_id, currency } = order.order_gen;
        // console.log(order.order_gen);
         // console.log(order.order_gen.id);
          const options = {
            key: razorpayKey,
            amount: order.order_gen.amount.toString(),
            currency: order.order_gen.currency,
            name: 'Revolt Motors',
            description: 'testing transaction',
            //image: '/images/p1.png',
           //order_id: order.order_gen.id,
           handler: async function (response) {
              // console.log(response);
              let razorpay_payment_id =  response.razorpay_payment_id;
              let razorpay_signature = response.razorpay_signature;
              let reason ="";
              /*
              if(!response.razorpay_payment_id && error.code==='BAD_REQUEST_ERROR') {
                razorpay_payment_id = error.metadata.payment_id;
                reason =error.reason;
              }*/
            
                const result_pay = await Axios.post(`${URL_API}/api/v1/customer/orders/pay`, {
                  order_id: order.order[0].id,
                  customer_id:order.order[0].user_id,
                  amount: order.order_gen.amount,
                  razorpayPaymentId: razorpay_payment_id,
                  razorpayOrderId: order.order_gen.id,
                  razorpaySignature: razorpay_signature
                
                });
               console.log(result_pay.data.data.order_id);
                if(result_pay.status===200)
                {
                  // navigate(`/order/${order.order[0].id}`);
                 navigate(`/thankyou/${result_pay.data.data.order_id}`);
                }
               //dispatch(payOrder(order, paymentResult));
             
              
            },
            prefill: {
              name: order.shippingAddress[0].name,
              email: order.shippingAddress[0].email,
              contact: order.shippingAddress[0].mobile,
            },
            notes: {
              address: order.shippingAddress[0].city,
            },
            theme: {
              color: '#3a3939', //"callback_url": 
            }
           // "callback_url": `${URL_API}/thankyou/${order.order[0].id}`
          };
  
      //   setLoading(false);
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } catch (err) {
          console.log(err);
        //  setLoading(false);
        }
      };
      document.body.appendChild(script);
    }

    
    if (
     (!order ||
      successPay ||
      successDeliver )
      //|| (order && order.id !== orderId)
      //|| 
      //&& window.orderId !=orderId
    ) {
      //console.log(order.id);
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(detailsOrder(orderId));
      window.orderId =orderId;
    } else {
      
      if (order.order[0].isPaid==0 || !order.order[0].isPaid) {
       // if (!window.paypal) {
           //addPayPalScript();
          
          loadRazorpay(order);
       /* } else {
          setSdkReady(true);
        }*/
      }
    }
}, [dispatch, orderId, sdkReady, successPay, successDeliver, order]);

const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
  };
  const deliverHandler = () => {
    dispatch(deliverOrder(orderId));
  };

  
  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <>
       {/* new design start */}
       <section className="signinnsignup detailsflow">
    <div className ="container">
    <div className="row justify-content-center">  
      <div className="col-12 col-sm-9 col-md-7 col-lg-11 col-xl-12 text-center p-0    ">  
          <div className="card px-0   pb-5    " id="msform">
                 
              {/* <form id="msform" className="form" onSubmit={submitHandler}>  */}
                  <ul id="progressbar">  
                      <li className="active"  id="account"><strong>Your Details </strong></li>  
                      <li  className="active" id="personal"><strong> Choose Model & MRP</strong></li>  
                      <li className="active" id="payment"><strong> Booking Payment </strong></li>  
                      <li className="active"  id="confirm"><strong> Start My Revolt Plan </strong></li>  
                  </ul>  

{/* 3rd tab start */}
<fieldset>
  <div className="form-card">
    
  <button
   
   type="button"
   className="small_button   mr-5"
   value="Back"
     // onClick={(e) => checkOTP(e.target)}
     onClick={() =>  navigate(`/placeorder`)}
     >
        
   Back
        </button>
  </div>
</fieldset>


{/* 3rd tab end  */}
                               {/* </form>   */}
          </div>  
      </div>  
  </div>  
</div>

</section>
 
{/* new design end */}

    
    </>
  );
}
