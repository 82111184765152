import React from 'react'
import Gallery from '../components/Gallery';
// import OwlCarousel from 'react-owl-carousel';  
// import $ from 'jquery';
import RevoltMap from '../components/revoltmap';
function Mainproduct() {

//   // //////////
//   const buttons = document.querySelectorAll("[data-modal-id]")

// buttons.forEach(button => {
//   button.addEventListener("click", () => {
//     const modalId = button.dataset.modalId
//     const modal = document.getElementById(modalId)
//     modal.classList.add("show")
//   })
// })

// /////////////////////
//   let slideIndex = 1;
  
  
//   // function plusSlides(n) {
//     const plusSlides = (n) => {
//       showSlides(slideIndex += n);
//   }
  
//   // function currentSlide(n) {
//     const currentSlide = (n) => {
      
//       showSlides(slideIndex = n);
//   }
  
//   // function showSlides(n) {
//     const showSlides = (n) => {
      
//       let i;
//       let slides = document.getElementsByClassName("mySlides");
//       let dots = document.getElementsByClassName("demo");
//       let captionText = document.getElementById("caption");
//       if (n > slides.length) { slideIndex = 1 }
//       if (n < 1) { slideIndex = slides.length }
//       for (i = 0; i < slides.length; i++) {
//           slides[i].style.display = "none";
//       }
//       for (i = 0; i < dots.length; i++) {
//           dots[i].className = dots[i].className.replace(" active", "");
          
//       }
//       if(slides.length>0){
        
//       slides[slideIndex - 1].style.display = "block";
//       dots[slideIndex - 1].className += "";
//       if(captionText?.length>0){
//       captionText.innerHTML = dots[slideIndex - 1].alt;
//       }
//     }
//   }
  
  const pauseAudio = () => {
       
  };
//   showSlides(slideIndex);
//   ///////////////////

//   function closemodel(){
//     $("html").css({
//       "overflow": "unset",
//       "margin-right": "0"
//   });
//   $(this).closest('.owl_sld_right').fadeOut().animate({
//       right: "-100%",
//   }, 100);
//   swiper.stopAutoplay();
//   }
  return (
    <>
      {/*bannersection main product-*/}
  <section id="mainbroduct_banner">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" />
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div className="mainproduct_banner_box">
            <h1 className="mainproduct_heading">RV400</h1>
            <a
              className="mainproduct_heading_btn"
              href="/booking"
               
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*end bannersection main product-*/}
  {/*start breadcrum main product-*/}
  <section id="">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="mainproduct_breadcrum">
            <span>
              <a href="#">Home</a>
            </span>{" "}
            &gt;{" "}
            <span>
              <a href="#">You Details</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*end breadcrum main product-*/}
  {/*start section body main product-*/}
  <section id="">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="mainproduct_section_content zipmg">
            <h3 className="font38 zipsize hd_mbl_vw">
              Zip around the city or cruise along,
              <br /> You control your mobility.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*end section body main product-*/}
  {/*start map body main product-*/}
  <section id="mainproduct_evening_banner">
    <div className="">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="rv-videosection">
            <video id="custvideo1"  muted
  autoPlay
  loop  >
              <source
                src="https://www.revoltmotors.com/assets/video/rv400-video-final.mp4"
                type="video/mp4"
              />
              <source
                src="https://www.revoltmotors.com/assets/video/rv400-video-final.ogg"
                type="video/ogg"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*end section body main product-*/}
  <section className="tabsection">
    <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
        <a
          className="nav-link  active"
          href="#profile"
          role="tab"
          data-toggle="tab"
          aria-selected="true"
        >
          ECO
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#buzz" role="tab" data-toggle="tab">
          NORMAL
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#references" role="tab" data-toggle="tab">
          Sports
        </a>
      </li>
    </ul>
    {/* Tab panes */}
    <div className="tab-content tabstatus">
      <div role="tabpanel" className="tab-pane active" id="profile">
        <div className="float-left">
          TOP SPEED <span>45 kmph</span>{" "}
        </div>
        <div className="float-right">
          RANGE <span>150 kms*</span>{" "}
        </div>
      </div>
      <div role="tabpanel" className="tab-pane fade" id="buzz">
        <div className="float-left">
          TOP SPEED <span>65 kmph</span>{" "}
        </div>
        <div className="float-right">
          RANGE <span>100 kms*</span>{" "}
        </div>
      </div>
      <div role="tabpanel" className="tab-pane fade" id="references">
        <div className="float-left">
          TOP SPEED <span>65 kmph</span>{" "}
        </div>
        <div className="float-right">
          RANGE <span>80 kms*</span>{" "}
        </div>
      </div>
    </div>
  </section>
  {/*start Collapse body main product-*/}
  <section id="mainproduct_Collapse">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="mainproduct_Collapse_box">
            <a
              id="showHidden"
              
              className="openhidden use-man-down"
            >
              <img className="plusIcon" src="images/round-plus-icon.png" />
              <img
                className="minusIcon"
                style={{ display: "none" }}
                src="images/round-minus-icon.png"
              />
              <span className="" />
            </a>
            <div id="demo" className="grid-container collapse hide_img">
              <div className="row">
                <div className="col-md-4 pl-0 pr-0 ">
                  <img src="images/wheel.jpg" alt="" />
                  <div className="collapse_box_content">
                    <h6>Brakes(CBS)</h6>
                    <p>Front Disc(240mm) /Rear Disc(240mm)</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/tyreimg.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Tyres</h6>
                    <p>Front - 90/80-17 /Rear - 120/80-17</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/front-fronk.png" alt="" />
                  <div className="collapse_box_content last">
                    <h6>Front Fork</h6>
                    <p>Upside Down Forks</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/RearSuspension.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Rear Suspension</h6>
                    <p>Monoshock(Adjustable)</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/BatteryType.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Battery Type</h6>
                    <p>Lithium Ion</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/VoltageWattage.png" alt="" />
                  <div className="collapse_box_content last">
                    <h6>Voltage/Wattage</h6>
                    <p>72V, 3.24KWh</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/Chargingimg.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Charging Time</h6>
                    <p>0-75% in 3 Hours And 0-100% in 4.5 Hours</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/Motor.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Motor</h6>
                    <p>3KW (Mid Drive)</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/weighting.png" alt="" />
                  <div className="collapse_box_content last">
                    <h6>Weight</h6>
                    <p>108Kg</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/whetbase.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Wheel Base</h6>
                    <p>1350mm</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/SeatHeight.png" alt="" />
                  <div className="collapse_box_content">
                    <h6>Seat Height (Rider)</h6>
                    <p>814mm</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/CarryingCapacity.png" alt="" />
                  <div className="collapse_box_content last">
                    <h6>Carrying Capacity</h6>
                    <p>2 Persons/Maximum 150Kg</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/lightingimg.png" alt="" />
                  <div className="collapse_box_content bottomlst">
                    <h6>Lighting</h6>
                    <p>
                      LED Head Lamp(Projection for High beam), Tail Lamps And
                      Indicators (All LED)
                    </p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <img src="images/GroundClearance.png" alt="" />
                  <div className="collapse_box_content bottomlst">
                    <h6>Ground Clearance</h6>
                    <p>215 mm</p>
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0 ">
                  <img src="images/rangeimg.png" alt="" />
                  <div className="collapse_box_content last bottomlst">
                    <h6>Range</h6>
                    <p>
                      150kms(Eco Mode), 100kms(Normal Mode), 80kms(Sports Mode)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="mySlides">
            <img
              src="images/slidshowmainproduct.jpg"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mySlides">
            <img
              src="images/slidshowmainproduct.jpg"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mySlides">
            <img
              src="images/slidshowmainproduct.jpg"
              style={{ width: "100%" }}
            />
          </div>
          <div className="row">
            <div className="column" 
            // onClick="currentSlide(1)"
            >
              <a href="/comfort">
                <div
                  className="demo demo1 cursor slide_open_btn"
                  data-modalid="feature-sec1"
                >
                  <h5>THE ERGONOMICS OF COMFORT</h5>
                  <p>
                    Crafted around your comfort, a ride which won't let you down
                  </p>
                  <p className="playbutton">
                    {/*<img src="https://www.revoltmotors.com/assets/images/playvideo1.png" class="img-fluid" alt="">*/}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </p>
                </div>
              </a>
            </div>
            <div className="column" 
            // onClick={currentSlide(2)}
            >
              <a href="/aesthetic">
                <div
                  className="demo demo2 cursor slide_open_btn"
                  data-modalid="feature-sec2"
                >
                  <h5>SCRIPTED AESTHETICALLY</h5>
                  <p>
                    A design which assists the rider's comfort, the motorcycle's
                    performance and also makes heads &amp; delusions turn 360
                    degrees
                  </p>
                  <p className="playbutton">
                    {/*<img src="https://www.revoltmotors.com/assets/images/playvideo1.png" class="img-fluid" alt="">*/}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </p>
                </div>
              </a>
            </div>
            <div className="column" 
            // onClick={currentSlide(3)}
            >
              <a href="/safety">
                <div
                  className="demo demo3 cursor slide_open_btn"
                  data-modalid="feature-sec3"
                >
                  <h5>SAFETY FIRST, ALWAYS</h5>
                  <p>
                    Safety is our top most priority, both of RV400's and yours
                  </p>
                  <p className="playbutton">
                    {/*<img src="https://www.revoltmotors.com/assets/images/playvideo1.png" class="img-fluid" alt="">*/}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*end Collapse body main product-*/}
  {/*-=============start -moterbick sound=============--*/}
  <section className="choicesound">
    <div className="container">
      <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <h3 className="font38 hd_mbl_vw">
            YOU MAKE THE CHOICE.
            <br />
            FOUR SOUNDS OR, NO SOUND AT ALL.
          </h3>
          <p>
            A first-in class innovation where Revolt brings to you four sounds
            in a motorcycle, all controlled through the MyRevolt App. Go on,
            select one of the four sounds of Revolt*
          </p>
          <div className="angle scl">
            <ul>
              <li data-src="A">
                <a  className="hide-new">
                  <span className="activesound" />
                  <span className="soundname">Revolt</span>
                </a>
              </li>
              <li data-src="B">
                <a  className="">
                  <span className="activesound" />
                  <span className="soundname">Rebel</span>
                </a>
              </li>
              <li data-src="C">
                <a  className="">
                  <span className="activesound">
                    {/* <i class="fa fa-check" aria-hidden="true"></i> */}
                  </span>
                  <span className="soundname">Roar</span>
                </a>
              </li>
              <li data-src="D">
                <a  className="">
                  <span className="activesound" />
                  <span className="soundname">Rage</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="sound" onClick={pauseAudio()} />
          <audio controls="" id="audio-outer1">
            <source
              id="audio-play1"
              src="https://www.revoltmotors.com/assets/audio/Revolt.mp3"
              type="audio/mpeg"
            />
          </audio>
          <audio controls="" id="audio-outer2">
            <source
              id="audio-play2"
              src="https://www.revoltmotors.com/assets/audio/Roar.mp3"
              type="audio/mpeg"
            />
          </audio>
          <audio controls="" id="audio-outer3">
            <source
              id="audio-play3"
              src="https://www.revoltmotors.com/assets/audio/Rage.mp3"
              type="audio/mpeg"
            />
          </audio>
          <audio controls="" id="audio-outer4">
            <source
              id="audio-play4"
              src="https://www.revoltmotors.com/assets/audio/Rebel.mp3"
              type="audio/mpeg"
            />
          </audio>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12"></div>
      </div>
    </div>
  </section>
  {/*-=============End -moterbick sound=============--*/}
  {/*-=============start -moterbick  charging Point===========--*/}
  <section id="mainproduct_charging_banner">
    <div className="container">
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div className="charginbox">
            <h5>THE MOST RELIABLE CHARGING ECOSYSTEM</h5>
            <p>
              A revolutionary charging ecosystem consisting of multiple methods
              to charge your Revolt motorcycle. No matter wherever you are in
              the city, we've made sure that you never stop revolting.
            </p>
            <a
              className="most_readmore "
              href="/booking"
               
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*-=============end -moterbick  charging Point=============--*/}
  {/*start gallerybody main product-*/}
  {/* <section className="gallery">
    <div className="container">
      <h3 className="font38 hd_mbl_vw">GALLERY</h3>
      <div className="row">
        <div className="col-md-8 pr-0">
          <div
            id="demo1"
            className="carousel slide overlay-colr"
            data-ride="carousel"
          >
         
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="images/videoslider.png"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  className="overlay-colr"
                />
                <div className="video-hover" />
              </div>
              <div className="carousel-item">
                <img
                  src="images/videoslider.png"
                  data-toggle="modal"
                  data-target="#exampleModal2"
                  className="overlay-colr"
                />
                <div className="video-hover" />
              </div>
            </div>
           
            <a
              className="carousel-control-prev"
              href="#demo1"
              data-slide="prev"
            ></a>
            <a
              className="carousel-control-next"
              href="#demo1"
              data-slide="next"
            ></a>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div
                id="demo2"
                className="carousel slide overlay-colr"
                data-ride="carousel"
              >
                 
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <a
                      className="fancybox"
                      href="images/imageslider.png"
                      data-fancybox-group="gallery"
                      title=""
                    >
                      <img
                        src="images/imageslider.png"
                        className="overlay-colr"
                      />
                    </a>
                    <div className="image-hover" />
                  </div>
                  <div className="carousel-item">
                    <a
                      className="fancybox"
                      href="images/imageslider.png"
                      data-fancybox-group="gallery"
                      title=""
                    >
                      <img
                        src="images/imageslider.png"
                        className="overlay-colr"
                      />
                    </a>
                    <div className="image-hover" />
                  </div>
                  <div className="carousel-item">
                    <a
                      className="fancybox"
                      href="images/imageslider.png"
                      data-fancybox-group="gallery"
                      title=""
                    >
                      <img
                        src="images/imageslider.png"
                        className="overlay-colr"
                      />
                    </a>
                    <div className="image-hover" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div
                id="demo3"
                className="carousel slide overlay-colr"
                data-ride="carousel"
              >
                
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <a
                      className="fancybox"
                      href="images/imageslider1.jpg"
                      data-fancybox-group="gallery"
                      title=""
                    >
                      <img
                        className="heightdecrease"
                        src="images/imageslider1.jpg"
                      />
                    </a>
                    <div className="image-hover" />
                  </div>
                  <div className="carousel-item">
                    <a
                      className="fancybox"
                      href="images/imageslider1.jpg"
                      data-fancybox-group="gallery"
                      title=""
                    >
                      <img
                        className="heightdecrease"
                        src="images/imageslider1.jpg"
                      />
                    </a>
                    <div className="image-hover" />
                  </div>
                  <div className="carousel-item">
                    <a
                      className="fancybox"
                      href="images/imageslider1.jpg"
                      data-fancybox-group="gallery"
                      title=""
                    >
                      <img
                        className="heightdecrease"
                        src="images/imageslider1.jpg"
                      />
                    </a>
                    <div className="image-hover" />
                  </div>
                </div>
                
                <a
                  className="carousel-control-prev"
                  href="#demo3"
                  data-slide="prev"
                ></a>
                <a
                  className="carousel-control-next"
                  href="#demo3"
                  data-slide="next"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}

  <Gallery />



  {/*end gallerybody main product-*/}
  {/*start map body main product-*/}
  <RevoltMap></RevoltMap>
  {/*end map body main product-*/}
  </>
  
  )
}

export default Mainproduct

 
 
 