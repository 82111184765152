import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { listProductCategories } from '../actions/productActions';
import { signout } from '../actions/userActions';
import { ROOT_PATH } from '../constants/cartConstants';
import LoadingBox from './LoadingBox';
import MessageBox from './MessageBox';
 
function Header() {
   
   const cart = useSelector((state) => state.cart);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const { cartItems } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };
   const productCategoryList = useSelector((state) => state.productCategoryList);
   const {
     loading: loadingCategories,
     error: errorCategories,
     categories,
   } = productCategoryList;
   useEffect(() => {
     dispatch(listProductCategories());
   }, [dispatch]);
   //console.log(categories);

  return (
    <header className="navbar-fixed">
    <div className="container-fluid">
       <div className="row">
          <div className="col-md-2">
          <Link className="brand" to="/" >
          <img src={`${ROOT_PATH}/images/revolt_logo.png`}/>
            </Link></div>


            <div className="col-md-5">
            <marquee className="markt"> Disclaimer: Beware of fraudulent websites and offers misusing Revolt’s name . Please read the disclaimer notice carefully before using this website and making enquiries about dealership/products. </marquee>
            </div>

          <div className="col-md-4 text-center">
          <div className="testdrive">
             
             <div className="testdrive-wrapper">
               <Link to="/test-ride">Test Ride</Link>
             </div>
             <div className="book-now">
                
                <Link to="/booking">Book Now</Link>
             </div>
          </div>
          <div className="float-right hhh">
          {
          
         ////////////////////// 
          userInfo ?  (
          <>
<div className="dropdown">
    <button
    className="btn btn-secondary dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    >
      {userInfo?.name}
    </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" id="header_dropdown">
   
  {userInfo  && (
    <a className="dropdown-item" href="/userdashboard">
     Dashboard
    </a>
  )}



    {/* admin */}
    {userInfo && userInfo?.isAdmin && (
              
              <>
               
                <Link className="dropdown-item" to="/orderlist">Bookings</Link>
               
              
                <Link className="dropdown-item" to="/testridelist">Test Rides</Link>
             
               
                <Link className="dropdown-item" to="/notifylist">Unlock City</Link>
               
               
                <Link className="dropdown-item" to="/requestlist">Request</Link>
                <Link className="dropdown-item" to="/productlist">Product</Link>
               
              </>
            
        )}
    {/* admin end */}

    {/* seller */}
    {userInfo && userInfo?.isSeller && (
            <> 
             <Link  className="dropdown-item" to="/productlist/dealer">Products</Link>
             <Link  className="dropdown-item" to="/orderlist/dealer">Bookings</Link>
             </>   
            )}
    {/* seller end */}

    <Link className="dropdown-item" to="#signout" onClick={signoutHandler}>
                      Sign Out
    </Link>
    </div>


  </div> {/* dropdown end */}
           
      {/* <div className="revolt-booknowtext"><span style={{width:"200px"}}></span></div> */}
  </>
   ) 
    // ///////////////
    : (
        <div className="revolt-booknowtext">
        <Link to={`/signin?redirect=/`}>Login</Link>
        </div>
      )}

 
           
           
          </div>
          </div> 
          <div className="col-md-1 text-right spacingTop">
          <div id="mySidenav" style={{width: sidebarIsOpen ? '350px' : '0'}} className="sidenav selectdiv ">
    <a  className="closebtn" onClick={() => setSidebarIsOpen(false)}>&times;</a>
    <a href="/Mainproduct"><h2>RV400</h2></a>
    <ul>
    <li><a href="/usp"><span>REVOLUTIONISED</span>CHARGING</a></li>
    <li><a href={`${ROOT_PATH}/#first-aibike`}><span>BEAUTY WITH</span>INTELLIGENCE</a></li>
    {/* <li><a href="#"><span>BRAND</span>STORY</a></li> */}
    <li className="coabout"><a href="/aboutus">ABOUT US</a></li>
    <li className="coabout"><a href="/contactus">CONTACT US</a></li>
   {/* {loadingCategories ? (
              <LoadingBox></LoadingBox>
            ) : errorCategories ? (
              <MessageBox variant="danger">{errorCategories}</MessageBox>
            ) : (
            )}

              <li >
              <Link
                to={`/search/category/${categories[0].category}`}
                onClick={() => setSidebarIsOpen(false)}
              >
                {categories[0].category}
              </Link>
            </li> 
              // categories.map((c,k) => (
              //   <li key={k}>
              //     <Link
              //       to={`/search/category/${c.category}`}
              //       onClick={() => setSidebarIsOpen(false)}
              //     >
              //       {c.category}
              //     </Link>
              //   </li>
              // ))
            )}*/}

                <hr/>
            {/*  usermenu start*/}
            {/* <div>
            <Link to="/cart">
              
              {cartItems.length > 0 && (
                <span className="badge">{cartItems.length}</span>
              )}
            </Link>
           
          </div> */}
            {/*  usermenu end*/}
    </ul>
    
    <div className="social d-flex justify-content-end align-items-end">
               <div><a href="https://www.facebook.com/revoltmotorsin/" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></div>
               <div><a href="https://twitter.com/RevoltMotorsIN" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></div>
               <div><a href="https://www.linkedin.com/company/revolt-intellicorp/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></div>
               <div><a href="https://www.instagram.com/revoltmotorsin/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></div>
               <div><a href="https://www.youtube.com/channel/UC2tW9kRUr1mGhS8oA_IQEnQ" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></div>
           </div>
    
    </div>
    
    
    <span   onClick={() => setSidebarIsOpen(true)}><img src={`${ROOT_PATH}/images/Group_149.png`}/></span>
          </div>
       </div>
    </div>
    </header>
  )
}

export default Header