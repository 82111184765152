import Axios from 'axios';
//import Razorpay from "razorpay";
//import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CheckoutSteps from '../components/CheckoutSteps';
import Pdf from "react-to-pdf";


import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
import NumberFormat from 'react-number-format';

 
export default function ThankyouScreenNotify(props) {
 
 

   
  const [notify_data, setNotify_data] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  const { id } = params;
  

  const getData = async (id) => {
    try {
      const { data } = await Axios.get(`${URL_API}/api/v1/customer/notifyme/${id}`  );
    
      setNotify_data(data.data.notify[0])
  console.log(data);
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData(id)
   
}, []);
 

 

 
  return  (
    <>
         <section className="signinnsignup">
	  <div className="container">
	  <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumbs">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    {/* <li className="breadcrumb-item active" aria-current="page">You Details</li> */}
  </ol>
</nav>
     
	  <div className="thankyoupage">
	  <div className="col-12 thankyou_heading">
        <h2 >
        <img src="/images/thankyou.png" alt=""/>   THANK YOU
        </h2>
        <div className="pdf_wrapper" >

        {/* <Pdf targetRef={ref} filename="Reciept.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Download Reciept <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
</button>}
      </Pdf> */}
        </div>
         
      </div>
	  <p>We have registered your city on your behalf! We will notify you once Revolt is live in your city. Stay tuned!</p>
	  
    {/* <div className="col-12 mt-5" >
        <table className="table table-striped table-bordered tableNSE">
            <thead>
            <tr>
              <th>Order Id </th>
              <th style={{ background: "#f1f3f6" }}>
              {order.order[0].id}
                </th>
            </tr>
          </thead>  
          <tbody>
          
          <tr>
              <td style={{ background: "#fff" }}>Name</td>
              <td style={{ background: "#f1f3f6" }}>
                  {notify_data?.name}
                </td>
            </tr>
            
            <tr>
              <td style={{ background: "#fff" }}>Email Id</td>
              <td style={{ background: "#f1f3f6" }}>
              {notify_data?.email}
                </td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td style={{ background: "#f1f3f6" }}>
                { notify_data?.mobile}
                </td>
            </tr>
            
            <tr>
              <td>City</td>
              <td style={{ background: "#f1f3f6" }}>
                
                 { notify_data?.city} 
                  
                   
                </td>
            </tr>
            <tr>
              <td> Model</td>
              <td style={{ background: "#f1f3f6" }}> {notify_data?.bike_model}</td>
            </tr>
          

          </tbody>
        </table>
      </div> */}
	  </div>
	  </div>

	  
	  </section>

   
    </>
   );
}
