import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Product from '../components/Product';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../actions/productActions';
import { listTopSellers } from '../actions/userActions';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import RevoltMap from '../components/revoltmap';
import Gallery from '../components/Gallery';

// import fancybox from 'react-fancybox';

export default function HomeScreen() {

  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const userTopSellersList = useSelector((state) => state.userTopSellersList);
  
  const {
    loading: loadingSellers,
    error: errorSellers,
    users: sellers,
  } = userTopSellersList;

  useEffect(() => {
    dispatch(listProducts({}));
    dispatch(listTopSellers());
  }, [dispatch]);

  // console.log(listProducts);
  const pauseAudio = () => {
     
  };
  

  return (
    <>
      {/* Modal */}
  <div
    className="modal fade video-modalbox"
    id="exampleModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                        document.getElementById('exampleModal').style.display = 'none';
                        document.getElementById('exampleModal').style.opacity = 0;
                 }}>
          <img
            src="https://www.revoltmotors.com/assets/images/closebtn.png"
            loading="lazy"
            alt=""
          />
        </div>
        <div className="modal-body">
          <iframe
            width="100%"
            height={500}
            src="https://www.youtube.com/embed/8evSC5jNq_o?autoplay=1"
            frameBorder={0}
          />
        </div>
      </div>
    </div>
  </div>
  {/* Modal */}
  <div
    className="modal fade video-modalbox"
    id="exampleModal2"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg" role=" document">
      <div className="modal-content">
        <div className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                        document.getElementById('exampleModal2').style.display = 'none';
                        document.getElementById('exampleModal2').style.opacity = 0;
                 }}>
          <img
            src="https://www.revoltmotors.com/assets/images/closebtn.png"
            loading="lazy"
            alt=""
          />
        </div>
        <div className="modal-body">
          <iframe
            width="100%"
            height={500}
            frameBorder={0}
            src="https://www.youtube.com/embed/7hrigocQh38?autoplay=1"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  {/*  */}
    {/* // <div style={{textAlign: 'center'}}>
    //   {loadingSellers ? (
    //     <LoadingBox></LoadingBox>
    //   ) : errorSellers ? (
    //     <MessageBox variant="danger">{errorSellers}</MessageBox>
    //   ) : (
    //     <>
    //     { products === undefined || products.length === 0 && <MessageBox>No Product Found</MessageBox>}
    //     { products   && 
    //       <Carousel   autoPlay showThumbs={false}  >

    //      
             {products.map((product,k) => (
              
    //           <Product id={product.model_id} key={k} product={product}></Product>
    //         ))}
    //       
 
    //       </Carousel>
    //       } 
    //     </>
    //   )} */}

<section className="banner">
          <div  >
            <div id="demo" className="carousel slide" data-ride="carousel">
              {/* Indicators */}
              <ul className="carousel-indicators">
                <li data-target="#demo" data-slide-to={0} className="active" />
                <li data-target="#demo" data-slide-to={1} />
              </ul>
              {/* The slideshow */}
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="images/banner3.jpg" alt="" />
                </div>
                <div className="carousel-item">
                  <img src="images/banner4.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>    
        
        <section className="our-motorcycles" id="our-motorcycles" 
        // data-aos="fade-up" 
        // data-aos-duration={800}
        >
    
          <div className="container position-relative">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10">
                <div className="owl-carousel" id="productinfo">
                  <div className="item">
                    <div className="blacktheme">
                      <div className="brand-info text-center">
                        <div className="bikeimg">
                          <div className="outer360">
                            <section className="my-360">
                              <div className="cd-product-viewer-wrapper">
                                <section className="container-fluid for-360 container1">
                                  <div className="threesixty product1">
                                    <div className="loadingimg">
                                      <img src="https://www.revoltmotors.com/assets/360/img/redbike-mac/1.png" loading="lazy" alt="" />
                                    </div>
                                    <div className="spinner"><span>0%</span></div>
                                    <ol className="threesixty_images" />
                                  </div>
                                </section>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div className="image-360">
                        </div>
                      </div>
                      <div className="kilometershare">
                        <div className="hoursebike">
                          <img src="images/calenderhours.png" alt="" />
                          <h5>0-100%</h5>
                          <h6>IN 4.5 HOURS*</h6>
                        </div>
                        <div className="hoursebike">
                          <img src="images/speed.png" alt="" />
                          <h5>50km/h</h5>
                          <h6>TOP SPEED</h6>
                        </div>
                        <div className="hoursebike">
                          <img src="images/battery.png" alt="" />
                          <h5>150 kms*</h5>
                          <h6>PER CHARGE</h6>
                        </div>
                      </div>
                    </div>
                    {/* FOR NEW Mist Grey RV 400 */}
                    <div className="whitetheme" style={{display: 'none'}}>
                      <div className="brand-info text-center">
                        <div className="bikeimg">
                          <div className="outer360">
                            <section className="my-360">
                              <div className="cd-product-viewer-wrapper">
                                <section className="container-fluid for-360 container1">
                                  <div className="threesixty product5">
                                    <div className="loadingimg">
                                      <img src="/images/mist_grey/1.png" loading="lazy" alt="" />
                                    </div>
                                    <div className="spinner"><span>0%</span></div>
                                    <ol className="threesixty_images" />
                                  </div>
                                </section>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div className="image-360">
                        </div>
                        <div className="kilometershare">
                          <div className="hoursebike">
                            <img src="images/calenderhours.png" alt="" />
                            <h5>0-100%</h5>
                            <h6>IN 4.5 HOURS*</h6>
                          </div>
                          <div className="hoursebike">
                            <img src="images/speed.png" alt="" />
                            <h5>50km/h</h5>
                            <h6>TOP SPEED</h6>
                          </div>
                          <div className="hoursebike">
                            <img src="images/battery.png" alt="" />
                            <h5>150 kms*</h5>
                            <h6>PER CHARGE</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END OF NEW Mist Grey RV 400 */}
                    <div className="redtheme" style={{display: 'none'}}>
                      <div className="brand-info text-center">
                        <div className="bikeimg">
                          <div className="outer360">
                            <section className="my-360">
                              <div className="cd-product-viewer-wrapper">
                                <section className="container-fluid for-360 container1">
                                  <div className="threesixty product2">
                                    <div className="loadingimg">
                                      <img src="https://www.revoltmotors.com/assets/360/img/blackbike-mac/1.png" loading="lazy" alt="" />
                                    </div>
                                    <div className="spinner">
                                      <span>0%</span>
                                    </div>
                                    <ol className="threesixty_images" />
                                  </div>
                                </section>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div className="image-360">
                        </div>
                        <div className="kilometershare">
                          <div className="hoursebike">
                            <img src="images/calenderhours.png" alt="" />
                            <h5>0-100%</h5>
                            <h6>IN 4.5 HOURS*</h6>
                          </div>
                          <div className="hoursebike">
                            <img src="images/speed.png" alt="" />
                            <h5>50km/h</h5>
                            <h6>TOP SPEED</h6>
                          </div>
                          <div className="hoursebike">
                            <img src="images/battery.png" alt="" />
                            <h5>150 kms*</h5>
                            <h6>PER CHARGE</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rv400-bike-switch">
                      <ul>
                        <li className="rv400red active" data-bikeclr="blacktheme">
                          <div data-bikename="Rebel Red" className="bikename"><span><i className="fa fa-check" aria-hidden="true" /></span></div>
                        </li>
                        <li className="rv400black" data-bikeclr="redtheme">
                          <div data-bikename="Cosmic Black" className="bikename"><span><i className="fa fa-check" aria-hidden="true" /></span></div>
                        </li>
                        <li className="rv400grey" data-bikeclr="whitetheme">
                          <div data-bikename="Mist Grey" className="bikename"><span><i className="fa fa-check" aria-hidden="true" /></span></div>
                        </li>
                        {/* <li className="explorebikenow text-center">
                                            <a href="https://www.revoltmotors.com/rv400?color=red" id="explore_product">
                                                Explore</a>
                                        </li> */}
                      </ul>
                      <div className="colorname" id="colorname-400">
                        <span>Rebel Red</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="text-center2 explore_now"><a href="#">Explore Now <i className="fa fa-long-arrow-right" aria-hidden="true" /></a></div>
          </div></section>
        <section className="first-aibike sec-gap"  id="first-aibike" >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-7 col-12">
                <div className="aibikeinfo">
                  <h3 className="font38 hd_mbl_vw">INDIA'S FIRST<br /> 
                    AI ENABLED <br /> MOTORCYCLE</h3>
                  <ul className="bike-ai-features">
                    <li className="feature1 active">Why can't you Geo-Locate your motorcycle?</li>
                    <li className="feature2">Why can't you Change your motorcyle's sound?</li>
                    <li className="feature3">Why can't you Geo-Fence your motorcycle?</li>
                    <li className="feature4">Why can't you switch your motorcycle's battery for a fresh one?</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-sm-5 col-12 ai-enabled-shadow" data-aos="fade-up" data-aos-duration={900} data-aos-easing="ease-in-out-cubic">
                <div id="bikefeatures123">
                  <div className="mobileslider123">
                    <div className="owl-carousel" id="febikebox">
                      <div className="item" data-bike="feature1">
                        <img src="images/locate-your-motorcycle.jpg" loading="lazy" alt="" />
                      </div>
                      <div className="item" data-bike="feature2">
                        <img src="images/motorcycle-roar.jpg" loading="lazy" alt="" />
                      </div>
                      <div className="item" data-bike="feature3">
                        <img src="images/geo--fence-your-revolt.jpg" loading="lazy" alt="" />
                      </div>
                      <div className="item" data-bike="feature4">
                        <img src="images/locate-a-swap-station.jpg" loading="lazy" alt="" />
                      </div>
                      {/* <div className="item" data-bike="feature5">
                                        <img src="https://www.revoltmotors.com/assets/images/order-a-battery-home.jpg"
                                            alt="">
                                    </div> */}
                    </div>
                  </div>
                </div>
                {/*  <div className="play-ai-video" data-toggle="modal" data-target="#aienabled-modalbox">
                            <img src="images/ai-enabled-play.png" loading="lazy" alt="">
                        </div> */}
              </div>
            </div>
          </div>
        </section>
        <div className="colorbggrey">
          <section className="revolution-in-dna sec-gap" id="mobilerotate" style={{background: '#ffffff', backgroundSize: '100%', paddingTop: '50px'}}>
             
            <div className="bike-features-sec">
              <div className="container">
                <div className="row m-0 mobile-orderchnage1">
                  <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                    <h3 className="font38 hd_mbl_vw">WHY CHOOSE <br />Revolt</h3>
                    <div className="slidertext">
                      <div className="owl-carousel" id="revo-dna1">
                        <div className="item">
                          <div className="product-caption">
                            <div className="heading-and-caption">
                              {/* <div className="d-none d-block768">
                                                    <img src="https://www.revoltmotors.com/assets/images/battery-perfomance-v1.jpg"
                                                        alt="">
                                                </div> */}
                              <p className="rider">Battery</p>
                              <div className="media">
                                <img className="mt-3 mr-3" src="images/plusminusiconwhite.png" alt="Generic placeholder image" />
                                <div className="media-body gradtext">
                                  <h5>0-100%<span /></h5>
                                  <p className="gr-below-text">in 4.5 Hours*</p>
                                  <p className="subtitle">Revolt motorcycles' lithium-ion batteries have been
                                    made keeping in mind the riding behavior and usage of the Indian riders.
                                    The batteries have been certified as waterproof, damage proof, shock
                                    proof and all-weather friendly according to ARAI standards. It's the
                                    power which powers Revolt.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="product-caption">
                            <div className="heading-and-caption">
                              {/* <div className="d-none d-block768">
                                                    <img src="https://www.revoltmotors.com/assets/images/performance-new-v1.jpg"
                                                        alt="">
                                                </div> */}
                              <p className="rider">Performance</p>
                              <div className="gradtext">
                                <h5>80km/h<span /></h5>
                                <p className="gr-below-text">Of top speed with an incredible torque</p>
                              </div>
                            </div>
                            <p className="subtitle">
                              A motor which pumps adrenaline into  a design and body which are
                              first of its kind in the industry.
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="product-caption">
                            <div className="heading-and-caption">
                              {/* <div className="d-none d-block768">
                                                    <img src="https://www.revoltmotors.com/assets/images/revolt-slideimage-v1.jpg"
                                                        alt="">
                                                </div> */}
                              <p className="rider">Charging</p>
                              <div className="gradtext">
                                <h5>150 kms*<span /></h5>
                                <p className="gr-below-text">per charge</p>
                              </div>
                            </div>
                            <p className="subtitle">Charge the RV400's battery like a phone, switch them at
                              our Revolt switch stations and when a dead battery gets you grounded,
                              just send us an SOS battery request. We've made sure that you are always
                              in charge.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0">
                    <div className="owl-carousel remodena_R" id="revo-dna">
                      <div className="item">
                        <img src="images/battery-perfomance-v2.png" loading="lazy" alt="" />
                      </div>
                      <div className="item">
                        <img src="images/battery-perfomance-v2.png" loading="lazy" alt="" />
                      </div>
                      <div className="item">
                        <img src="images/battery-perfomance-v2.png" loading="lazy" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="choicesound">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <h3 className="font38 hd_mbl_vw">YOU MAKE THE CHOICE.<br />
                  FOUR SOUNDS OR, NO SOUND AT ALL.</h3>
                <p>A first-in class innovation where Revolt brings to you four sounds in a motorcycle, all controlled through the MyRevolt App. Go on, select one of the four sounds of Revolt*</p>
                <div className="angle scl">
                  <ul>
                    <li data-src="A"><a  onClick={e => e.preventDefault()} className="hide-new">
                        <span className="activesound" />
                        <span className="soundname">Revolt</span></a></li>
                    <li data-src="B"><a  onClick={e => e.preventDefault()}  >
                        <span className="activesound" />
                        <span className="soundname">Rebel</span></a></li>
                    <li data-src="C"><a  onClick={e => e.preventDefault()}  >
                        <span className="activesound">{/* <i className="fa fa-check" aria-hidden="true"></i> */}</span>
                        <span className="soundname">Roar</span></a></li>
                    <li data-src="D"><a  onClick={e => e.preventDefault()}  >
                        <span className="activesound" />
                        <span className="soundname">Rage</span></a></li>
                  </ul>
                </div>
                <div className="sound" onClick={pauseAudio()} />
                <audio controls id="audio-outer1">
                  <source id="audio-play1" src="/audio/Revolt.mp3" type="audio/mpeg" />
                </audio>
                <audio controls id="audio-outer2">
                  <source id="audio-play2" src="/audio/Roar.mp3" type="audio/mpeg" />
                </audio>
                <audio controls id="audio-outer3">
                  <source id="audio-play3" src="/audio/Rage.mp3" type="audio/mpeg" />
                </audio>
                <audio controls id="audio-outer4">
                  <source id="audio-play4" src="/audio/Rebel.mp3"  />
                </audio>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              </div>
            </div>
          </div>
        </section>
        
        {/*  */}
        {/* <section className="gallery">
          <div className="container">
            <h3 className="font38 hd_mbl_vw">GALLERY</h3>
            <div className="row">
              <div className="col-md-8 pr-0">
                <div id="demo1" className="carousel slide overlay-colr" data-ride="carousel">
                  
                  <div className="carousel-inner">
                    <div className="carousel-item active"  >
                      <img src="images/videoslider.png" 
                      data-toggle="modal" 
                      data-target="#exampleModal" 
                      className="overlay-colr" 

                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                       
                      />
                      <div className="video-hover"  onClick={() => {
                        document.getElementById('exampleModal').style.display = 'block';
                        document.getElementById('exampleModal').style.opacity = 1;
                       }}/>
                    </div>
                    <div className="carousel-item">
                      <img src="images/videoslider.png" 
                      // data-toggle="modal" 
                      // data-target="#exampleModal2" 
                      className="overlay-colr" 
                      
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      
                      />
                      <div className="video-hover" onClick={() => {
                        document.getElementById('exampleModal2').style.display = 'block';
                        document.getElementById('exampleModal2').style.opacity = 1;
                 }}/>
                    </div>
                  </div>
                  
                  <a className="carousel-control-prev" href="#demo1" data-slide="prev">
                  </a>
                  <a className="carousel-control-next" href="#demo1" data-slide="next">
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div id="demo2" className="carousel slide overlay-colr" data-ride="carousel">
                      
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <a className="fancybox" href="images/imageslider.png" data-fancybox data-fancybox-group="gallery"  ><img src="images/imageslider.png" className="overlay-colr" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider.png" data-fancybox data-fancybox-group="gallery"  ><img src="images/imageslider.png" className="overlay-colr" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider.png" data-fancybox data-fancybox-group="gallery"  ><img src="images/imageslider.png" className="overlay-colr" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div id="demo3" className="carousel slide overlay-colr" data-ride="carousel">
                      
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <a className="fancybox" href="images/imageslider1.jpg" data-fancybox data-fancybox-group="gallery"  ><img className="heightdecrease" src="images/imageslider1.jpg" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider1.jpg" data-fancybox data-fancybox-group="gallery"  ><img className="heightdecrease" src="images/imageslider1.jpg" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider1.jpg" data-fancybox data-fancybox-group="gallery"  ><img className="heightdecrease" src="images/imageslider1.jpg" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                      </div>
                      
                      <a className="carousel-control-prev" href="#demo3" data-slide="prev">
                      </a>
                      <a className="carousel-control-next" href="#demo3" data-slide="next">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Gallery/>
        {/*  */}


         {/* map section starts  */}
        
         <RevoltMap></RevoltMap>
        
        {/* map section ends  */}
        <section className="test-a-ride">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 p-0">
                <a href="/test-ride"  >
                  <div className="title-n-icon d-flex align-items-center justify-content-center">
                    <span className="bikeride-v1" />
                    <span>Schedule A Test Ride</span>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 p-0">
                <a href="/notifyme"  >
                  <div className="title-n-icon d-flex align-items-center justify-content-center border-bothside">
                    <span className="bikeride-v4" />
                    <span>Unlock Your City</span>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 border-bothside p-0">
                <a href="https://www.youtube.com/channel/UC2tW9kRUr1mGhS8oA_IQEnQ" target="_blank">
                  <div className="title-n-icon d-flex align-items-center justify-content-center">
                    <span className="bikeride-v2" />
                    <span>Revolt Times </span>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 p-0">
                <a href="/contactus"  >
                  <div className="title-n-icon d-flex align-items-center justify-content-center">
                    <span className="bikeride-v3" />
                    <span>Contact Us</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
   
    </>
  );
}
