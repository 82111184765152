import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams  } from 'react-router-dom';
import { createReview, detailsProduct,productBycolor } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Rating from '../components/Rating';
import { PRODUCT_REVIEW_CREATE_RESET,PRODUCT_DETAILS_RESET } from '../constants/productConstants';
import { addToCart, removeFromCart } from '../actions/cartActions';
import ProductEditScreen from './ProductEditScreen';
import { productUpdateReducer, productDetailsReducer } from '../reducers/productReducers';
import {
  ROOT_PATH
} from '../constants/cartConstants';
import { bookingout } from '../actions/userActions';
import NumberFormat from 'react-number-format';



export default function ProductScreen(props) {

   
  const userInfo_info = localStorage.getItem("bookinginfo");
  
  const cartItem_arr=  localStorage.getItem("cartItems");
  const cartitem_myArr = JSON.parse(cartItem_arr);

  const userInfo_myArr = JSON.parse(userInfo_info);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { id: productId } = params;
  
  const [qty, setQty] = useState(1);
  const [modelColor,setModelcolor] = useState(cartitem_myArr?.product[0].color ? cartitem_myArr?.product[0].color : "Cosmic Black");
  const [selectedPlan,setSelectedPlan] =  useState(cartitem_myArr?.purchasee_plan[0]?.id ? cartitem_myArr?.purchasee_plan[0]?.id : '');
  const productDetails = useSelector((state) => state.productDetails);
  // const [city_stock,setCity_stock] = cartitem_myArr?.city_stock == "ok" ? 1 : 0;
  
  const { loading, error, product } = productDetails;
  
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
 
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productReviewCreate;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');



  useEffect(() => {
    if (!userInfo_info) {
    navigate(`/product/RM0400RRCP1CWBLK`);
    }
     
    dispatch(productBycolor(productId,modelColor,selectedPlan,userInfo_myArr?.token));
       
    // if(city_stock!="ok"){
    //   alert("Booking closed in your selected city. Please try someother city.");
    //   navigate(`/booking`);
    // }
    
    
  }, [dispatch, productId, successReviewCreate,modelColor,selectedPlan,PRODUCT_DETAILS_RESET]);
   
  

  const addToCartHandler = () => {

    navigate(`/placeorder`);

   // navigate(`/signin?redirect=/placeorder`);

    dispatch({ type: PRODUCT_DETAILS_RESET });
    //navigate(`/placeorder?pl=${selectedPlan}`);
    //navigate(`/cart/${productId}?qty=${qty}`);
    //navigate('/signin?redirect=/shipping');
  };

   
  var active ="";
  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating) {
      dispatch(
        createReview(productId, { rating, comment, name: userInfo.name })
      );
    } else {
      alert('Please enter comment and rating');
    }
  };
  
  //const productvalue='';
    if(product!==undefined && window.productvalue===undefined)
    {
      window.productvalue = product;

    } 

  return (    
    <div>

 {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
       
          
          {/*  */}

  {/* new design start */}

  <section className="signinnsignup detailsflow">
    <div className ="container">
    <div className="row justify-content-center">  
      <div className="col-12 col-sm-9 col-md-7 col-lg-11 col-xl-12 text-center p-0   ">  
          <div className="card px-0  pb-0   " id="msform">
                 
              {/* <form id="msform" className="form" onSubmit={submitHandler}>  */}
                  <ul id="progressbar">  
                      <li className="active"  id="account"><strong>Your Details </strong></li>  
                      <li className="active" id="personal"><strong> Choose Model & MRP</strong></li>  
                      <li id="payment"><strong> Booking Payment </strong></li>  
                      <li id="confirm"><strong> Start My Revolt Plan </strong></li>  
                  </ul>  

               {/* product detail start  */}
            {product?.product.length < 1 || product?.product == undefined ? (
             <MessageBox >
               No data Found!
             </MessageBox>
           ) : (
            <>
           {/* {product.product.map((item,i) => (   */}
            
            {product?.subscription.map((item,i) => (      
                  <fieldset className="bikemodeL product_page" key={i}>

              {product?.city_stock!="ok"?
                 
                <div className="col-12">
                <MessageBox variant="danger">
                {product.city_stock}
                </MessageBox>
                </div>
                
                :''
              }

      <section
        className="our-motorcycles"
        // data-aos="fade-up"
        // data-aos-duration={800}
      >
     
     
    <div className="container position-relative">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-md-10 rv400-bike-wrapper" >
       
                  {/* custom */}
      <div className="rv400-bike-switch">
   <ul key={i}>
       {product.colors.map((productcolor,k) => { 
         active = (productcolor.color == modelColor) ? "active" : "";
        return( 



                    <li className="product_blacktheme"  data-bikeclr="blacktheme" key={k} >
                        <label className={`container ${active}`}
                        
                      
                      
                        onClick={(e)=>setModelcolor(productcolor.color)}
                        >
                       { productcolor.color}
                        </label>
                          {/* <input className="" onChange={(e)=>setModelcolor(e.target.value)} type="radio" name="color" value={productcolor.color}  checked={ (productcolor.color == modelColor  ? 'checked' : '')} ></input> */}
                          
                      
                    </li>
                     
); } ) }  
           </ul>  
           <div className="colorname" id="colorname-400">
                                        <span className=" " style={{fontSize:"18px",fontWeight:"bold"}}  >Select Color</span>
                                    </div>  
           </div>  
           <img src={`/images/${cartitem_myArr?.product[0]?.image}`} className="fit-image" style={{width: '360px'}} />
                {/* custom end  */}
                {/* <img src={`/images/1.png`} className="fit-image" style={{width: '300px'}} /> */}
  {/* { ( modelColor == "Cosmic Black") ?
         
         <img src={`/images/cosmic-black.png`} className="fit-image" style={{width: '300px'}} />
        
       :
         <img src={`/images/rebel-red.png`} className="fit-image" style={{width: '300px'}} />
            } */}
             
        {/* <img src={`/images/${modelColor}.png`} className="fit-image" style={{width: '300px'}} /> */}
          {/*  */}

          {/* {
            ( modelColor == "Cosmic Black") &&
            <img src={`/images/cosmic-black.png`} className="fit-image" style={{width: '300px'}} />
          }
           {
            ( modelColor == "Cosmic Black") &&
            <img src={`/images/cosmic-black.png`} className="fit-image" style={{width: '300px'}} />
          }
           {
            ( modelColor == "Cosmic Black") &&
            <img src={`/images/${cartitem_myArr.product[0].image}`} className="fit-image" style={{width: '300px'}} />
          }
           {console.log(cartitem_myArr.product[0].image)} */}
        </div>
      </div>
    </div>
  </section>
  <div className="form-card bgwhitenone mt-2">
    <div className="row bookib-proceS">
      <div className="col-6">
        <p className="fs-title stepnumbering">
          Select your Revolt Purchase Plan
        </p>
        </div>
        <div className="col-6"> 
        <p className="fs-title stepnumbering">
        <select 
              className="form-control"
              value={selectedPlan?selectedPlan:''}
          
             onChange={(e) => setSelectedPlan(e.target.value)}
              >

               
          {product.purchasee_plan.map((value, key) => {
            return (
              <option value={value?.id} key={key}>
                {value?.plan_type}
              </option>
            );
          })}
             
              </select>


        </p>
      </div>

       
      <table className="table table-striped table-bordered tableNSEbooking">
        <thead>
          <tr>
            <th style={{ fontWeight: "normal" , fontSize: "25px"}}>Booking Amount </th>
            <th
              style={{
                background: "#fff",
                textAlign: "right",
                fontSize: "25px",
                color: "#ed1c24"
              }}
            >
             {/* &#8377; {item.booking_amount} */}
             
             <NumberFormat  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;" value={item.booking_amount} />

            </th>
          </tr>
        </thead>
        <tbody>
        
          <tr>
            <td  >EMI Amount -</td>
            <td style={{  textAlign: "right" }}>-</td>
          </tr>
          <tr>
            <td>Ex Showroom Price (a)</td>
            <td style={{ background: "#fff", textAlign: "right" }}>
            {/* &#8377; {item.ex_price} */}
            <NumberFormat value={item.ex_price}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
            </td>
          </tr>
          <tr>
            <td  >Fame II Incentive 1 (b)</td>
            <td style={{   textAlign: "right" }}>
              
              {/* &#8377; {item.fame_subsidy_at_booking} */}
              <NumberFormat value={item.fame_subsidy_at_booking}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
              </td>
          </tr>
          <tr>
            <td>Cost before on-Road 2 (a-b)</td>
            <td style={{   textAlign: "right" }}>

              
            {/* &#8377; {item.ex_price-item.fame_subsidy_at_booking} */}
            <NumberFormat value={item.ex_price-item.fame_subsidy_at_booking}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />


          </td>
          </tr>

          <tr>
            <td>State Incentive</td>
            <td style={{   textAlign: "right" }}>

              
            {/* &#8377; {item.ex_price-item.fame_subsidy_at_booking} */}
            <NumberFormat value={userInfo_myArr.incentive}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />

             {/* cost a-b */}
            {/* {userInfo_myArr === 'undefined' || userInfo_myArr===null ? (
                   <div>
                   <li>
                     <h2>State Incentive: &#8377;{0}</h2></li>
                   <li>
                     <h2>Effective Cost before on Raod: &#8377;{item.ex_price-item.fame_subsidy_at_booking}</h2>
                   </li>
                   </div>
                 ) : (
                   <div>
                   <li>
                     <h2>State Incentive: &#8377;{userInfo_myArr.area}</h2>
                   </li>
                   <li>
                     <h2>Effective Cost before on Raod: &#8377;{item.ex_price-item.fame_subsidy_at_booking-userInfo_myArr.area}</h2>
                   </li>
                   </div>
                 )} */}

                 {/*  */}
            </td>
          </tr>
          <tr>
            <td>Effective Cost before on Raod</td>
            <td style={{ background: "#fff", textAlign: "right" }}>

              
            {/* &#8377; {item.ex_price-item.fame_subsidy_at_booking} */}
            <NumberFormat value={item.ex_price-item.fame_subsidy_at_booking-userInfo_myArr.incentive}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />


          </td>
          </tr>
          
        </tbody>
      </table>
      {/* <div className="selectbtn">Select sanm</div> */}
      <ul className="mt-2">
        <li>
          * The price of motorcycle shall be applicable as prevailing on the
          date of delivery of motorcycle to customer.
        </li>
        <li>1. Claimable only once per Aadhar card. T&amp;C apply.</li>
        <li>
          2. Your booking amount will be adjusted with the On-Road price.
          Registration and applicable Road Tax will be additional based on
          actuals.
        </li>
        <li>
          3. On-Road Price for Revolt Purchase Plan = “Cost Before On-Road” +
          Add. cost of Registration/RTO (On Actuals) + Insurance + Smart Card +
          4G Connectivity Charges + other statutory applicable charges.
        </li>
      </ul>
    </div>
    <div className="deliveryslot">
      <div className="row">
        <div className="col-md-2">
          <div className="slottime">Delivery Slot</div>
        </div>
        <div className="col-md-7">
          <img src={`${ROOT_PATH}/images/delveryslotimg.png`} alt="" />
        </div>
        <div className="col-md-3">
          <div className="julyaugust"  >
          {/* Tentative - July/August */}
           Tentative {product?.delivery_batch[0]?.name}
          </div>
        </div>
      </div>
    </div>
  </div>{" "}

  <button
   
   type="button"
   className="small_button   mr-5"
   value="Back"
     // onClick={(e) => checkOTP(e.target)}
     onClick={() =>  navigate(`/booking`)}
     >
        
   Back
        </button>
   
  <input
    type="button"
    name="next"
    className="next action-button"
    defaultValue="Next"
    
    onClick={addToCartHandler}
    
    disabled={product?.subscription?.length === 0 || product?.city_stock!="ok"}
  />
   
 
</fieldset>
))}
</>
)}
{/* // product detail end  */}
            
              {/* </form>   */}
          </div>  
      </div>  
  </div>  
</div>

</section>

{/* new design end */}

          
          </>

      )}
    </div>
  );
}
