import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes,useParams,useNavigate  } from 'react-router-dom';
import { listProductCategories } from '../../actions/productActions';
import { signout } from '../../actions/userActions';
import NumberFormat from 'react-number-format';
// import LoadingBox from '../../components/LoadingBox';
// import MessageBox from '.../../components/MessageBox';
import Axios from 'axios';
import {
  URL_API,ROOT_PATH
} from '../../constants/cartConstants';

function UserBookingScreen() {

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [order_list, setOrder_list] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  // const { id } = params;
  

  const getData = async () => {
    try {

      const { data } = await Axios.post(`${URL_API}/api/v1/customer/orderlist/${userInfo.id}`,
      { 
        //user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub
      }
      , {
        headers: { Authorization: userInfo.token },
      }
      );
      // const { data } = await Axios.post(`${URL_API}/api/v1/customer/contactlist/${userInfo.mobile}`  );
      console.log(data);
      setOrder_list(data.data)
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData()
   
}, []);

  return (
    <section className="signinnsignup">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-12 col-xs-12">
          <div className="dashboard">
            <a href="/userdashboard">
              <div className="media">
                <img className="mr-3" src="images/sadboard-icon.png" alt="" />
                <div className="media-body">
                  <h5 className="mt-0"> Hello</h5>
                  <p>{userInfo.name}</p>
                </div>
              </div>
            </a>
            <div className="bookingride">
              <ul>
                <a href="/userbooking">
                  <li className="active">
                    <img src="images/bookingimg.png" /> <span>Bookings</span>
                  </li>
                </a>
                <a href="/usertestride">
                  <li>
                    <img src="images/testrideimg.png" /> <span>Test Rides</span>
                  </li>
                </a>
                <a href="/usercontactus">
                  <li>
                    <img src="images/requestimg.png" /> <span>Request</span>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 col-xs-12">
          <div className="requestid">
            
            
            {/* <div className="rvbooking">
              <div className="row">
                <div className="col-md-6">
                  <img src="images/bookingbike.png" alt="" />
                </div>
                <div className="col-md-6 modelfour rvmodelfour">
                  <h6>Booking ID : 45181328383</h6>
                  <h5>Model</h5>
                  <h1>RV400</h1>
                </div>
              </div>
            </div> */}


      {order_list?.map((order) => (
            <div className="rvbooking-confirm mb-4">
              <div className="bookwrap">Booking ID : {order.booking_id}</div>
              <div className="row pt2">
                <div className="col-md-6 modelfour ">
                  <img src={`images/${order.image}`} />
                  <ul className="bookinglunckow">
                  <li>
                      <strong>Tentative Delivery</strong> | &nbsp; 
                      {order.delivery_batch}
                    </li>
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                      {order.area_name} |
                      {order.city_name}, {order.state_name}
                    </li>
                    <li>
                    <i className="fa fa-calendar" aria-hidden="true" /> 
                      Booking &nbsp;
                      
                      {order.createdAt}
                    </li>
                    <li>
                    <i className="fa fa-calendar" aria-hidden="true" /> 
                      Cancel &nbsp;
                     
                      {order.updatedAt}
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 modelfour rvmodelfour">
                  <h5>Model</h5>
                  <h1>{order.item_name}</h1>
                  <table className="table table-striped tableNSEbooking">
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "normal" }}>Booking Amount </th>
                        <th
                          style={{
                            background: "#fff",
                            textAlign: "right",
                            fontSize: 25,
                            color: "#ed1c24"
                          }}
                        >
                          
                         <NumberFormat  value={order.booking_amount} displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                       
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                        <td style={{ background: "#fff" }}>EMI Amount -</td>
                        <td style={{ background: "#fff", textAlign: "right" }}>
                          -
                        </td>
                      </tr> */}
                      <tr>
                        <td>Ex Showroom Price (a)</td>
                        <td style={{ background: "#fff", textAlign: "right" }}>
                        
                        <NumberFormat  value={order.itemsPrice} displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                       
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: "#fff" }}>
                          Fame II Incentive 1 (b)
                        </td>
                        <td style={{ background: "#fff", textAlign: "right" }}>
                       
                        <NumberFormat  value={order.fame_subsidy_at_booking} displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                        
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: "#fff" }}>
                        State Incentive	
                        </td>
                        <td style={{ background: "#fff", textAlign: "right" }}>
                        
                        <NumberFormat  value={order.state_incentive_amount} displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                        
                        </td>
                      </tr>
                      <tr>
                        <td  style={{ background: "#fff" }}>Cost before on-Road </td>
                        <td style={{ background: "#fff", textAlign: "right" }}>
                        
                        <NumberFormat  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;" value={order.totalPrice} />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {order.order_status=="Cancel" ? 

                  <div className="cancel_biooking mb-4 disabled"><a >Cancelled</a></div>
                  : 
                  <div className="cancel_biooking mb-4"><a href={`/searchbooking/${order.booking_id}`}>Cancel Booking</a></div>
                        }
                </div>
              </div>
            </div>


))}

          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default UserBookingScreen