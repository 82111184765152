import Axios from 'axios';
//import Razorpay from "razorpay";
//import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CheckoutSteps from '../components/CheckoutSteps';
import Pdf from "react-to-pdf";


import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
import NumberFormat from 'react-number-format';

 
export default function ThankyouContactusScreen(props) {
 
 

  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [contactus_data, setcontactus_data] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  const { id } = params;
  

  const getData = async (id) => {
    try {
      const { data } = await Axios.get(`${URL_API}/api/v1/customer/contactus/${id}`  );
    
  setcontactus_data(data.data.contactus[0])
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData(id)
   
}, []);
 

 

 
  return  (
    <>
         <section className="signinnsignup">
	  <div className="container">
	  <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumbs">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    {/* <li className="breadcrumb-item active" aria-current="page">You Details</li> */}
  </ol>
</nav>
    {console.log(contactus_data)}
	  <div className="thankyoupage">
    <div className="col-12 thankyou_heading">
        <h2 >
        <img src="/images/thankyou.png" alt=""/>   THANK YOU
        </h2>
        <div className="pdf_wrapper" >

        {/* <Pdf targetRef={ref} filename="Reciept_Testride.pdf"    y={20} scale={0.7}>
        {({ toPdf }) => <button onClick={toPdf}>Download Reciept <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
</button>}
      </Pdf> */}
        </div>
         <p>Thanks for reaching out. Our concerned team will get back to you shortly.</p>
         <h4>Reference Id : {contactus_data?.contact_ref_id}</h4>
      </div>
    
	 
	  
	  
    {/* <div className="col-12 mt-5" ref={ref} >
        <table className="table table-striped table-bordered tableNSE">
           
          <tbody>
          <tr>
              <td> Reference Id</td>
              <td style={{ background: "#f1f3f6" }}> {contactus_data?.contact_ref_id}</td>
            </tr>
          <tr>
              <td style={{ background: "#fff" }}>Name</td>
              <td style={{ background: "#f1f3f6" }}>
                  {contactus_data?.name}
                </td>
            </tr>
            
            <tr>
              <td style={{ background: "#fff" }}>Email Id</td>
              <td style={{ background: "#f1f3f6" }}>
              {contactus_data?.email}
                </td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td style={{ background: "#f1f3f6" }}>
                { contactus_data?.mobile}
                </td>
            </tr>
            
            <tr>
              <td>City</td>
              <td style={{ background: "#f1f3f6" }}>
                
                 { contactus_data?.city} 
                  
                   
                </td>
            </tr>
           
          <tr>
              <td> Date</td>
              <td style={{ background: "#f1f3f6" }}> {contactus_data?.contactus_date}</td>
            </tr>
             
             
          </tbody>
        </table>
      </div> */}


	  </div>
	  </div>

	  
	  </section>

   
    </>
   );
}
