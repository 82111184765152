import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useParams, useLocation } from 'react-router-dom';
// import { deleteOrder, listcontact_datas } from '../../actions/orderActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
// import { ORDER_DELETE_RESET } from '../../constants/orderConstants';
import Pdf from "react-to-pdf";
import Axios from 'axios';
import {
  URL_API,ROOT_PATH
} from '../../constants/cartConstants';


export default function ContactUsListScreen(props) {
 
  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [contact_list, setContact_list] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  // const { id } = params;
    // const deleteHandler = (order) => {
  //   if (window.confirm('Are you sure to delete?')) {
  //     dispatch(deleteOrder(order.order_id));
  //   }
  // };

  const getData = async () => {
    try {
      const { data } = await Axios.post(`${URL_API}/api/v1/customer/contactlist`  );
      console.log(data);
      setContact_list(data.data.contactus)
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData()
   
}, []);
 

 

  return (
    <div>
      <h1>Requests</h1>
      
      {/* {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : ( */}
        <table className="table">
          <thead>
            <tr>
              <th>REF ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>MOBILE</th>
              <th>CITY</th>
              <th>INTERESTED IN </th>
              <th>DATE</th>
              
              {/* <th>ACTIONS</th> */}
            </tr>
          </thead>
          <tbody>
            {contact_list?.map((contact_data) => (
              <tr key={contact_data?.id}>
                <td><a href={`/contactusdetail/${contact_data?.id}`}>{contact_data?.contact_ref_id}</a></td>
                <td>{contact_data?.name}</td>
                <td>{contact_data?.email}</td>
                <td>{contact_data?.mobile}</td>
                <td>{contact_data?.city}</td>
                

                <td>{contact_data?.Interestedin}</td>
             
                
                 
                <td>{contact_data?.created_on?.substring(0, 10)}</td>
                {/* <td>
                  {contact_data.isDelivered
                    ? contact_data.deliveredAt.substring(0, 10)
                    : 'No'}
                </td> */}
                {/* <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      navigate(`/thankyou/${contact_data.contact_data_id}`);
                    }}
                  >
                    Details
                  </button>
                  <button
                    type="button"
                    className="small"
                    onClick={() => deleteHandler(contact_data)}
                  >
                    Cancel contact_data
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      {/* )} */}
    </div>
  );
}
