import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useParams, useLocation } from 'react-router-dom';
import { deleteOrder, listTestrides } from '../../actions/orderActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { ORDER_DELETE_RESET } from '../../constants/orderConstants';
import Axios from 'axios';
import {
  URL_API,ROOT_PATH
} from '../../constants/cartConstants';

export default function TestrideListScreen(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const sellerMode = pathname.indexOf('/dealer') >= 0;
  const testrideList = useSelector((state) => state.testrideList);
  const { loading, error, testrides } = testrideList;
  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete;
  const [selectfromdate, setSelectFromDate] = useState('');
  const [selecttodate, setSelectToDate] = useState('');
  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const [selectedHub, setSelectedHub] = React.useState('');
  
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  useEffect(() => {
   // console.log(userInfo);
    // dispatch({ type: ORDER_DELETE_RESET });
     
    dispatch(listTestrides( userInfo.id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub ));
   //
  }, [dispatch, sellerMode, successDelete, userInfo.id]);
  // const deleteHandler = (order) => {
  //   if (window.confirm('Are you sure to delete?')) {
  //     dispatch(deleteOrder(order.order_id));
  //   }
  // };
  console.log(testrides);
  return (
    <div>
      <h1>Test Rides</h1>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>BOOKED ON</th>
              <th>RIDE DATE</th>
              <th>TIME</th>
              <th>HUB</th>
              <th>CITY</th>
              <th>STATE</th>
              {/* <th>ACTIONS</th> */}
            </tr>
          </thead>
          <tbody>
            {testrides?.data?.map((testride) => (
              <tr key={testride?.id}>
                <td><a href={`/testridedetail/${testride?.id}`}>{testride?.id}</a></td>
                <td>{testride?.name}</td>
                <td>{testride?.created_on?.substring(0, 10)}</td>
                <td>{testride?.ride_date}</td>
                <td>{testride?.slot}</td>
                <td>{testride?.hub_name}</td>
                <td>{testride?.city_name}</td>
                <td>{testride?.state_name}</td>
                {/* <td>
                  {testride.isDelivered
                    ? testride.deliveredAt.substring(0, 10)
                    : 'No'}
                </td> */}
                {/* <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      navigate(`/thankyou/${testride.testride_id}`);
                    }}
                  >
                    Details
                  </button>
                  <button
                    type="button"
                    className="small"
                    onClick={() => deleteHandler(testride)}
                  >
                    Cancel testride
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
