import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { booking } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { USER_BOOK_RESET } from '../constants/userConstants';
import  { userBookingReducer } from '../reducers/userReducers';
//import Casecading from '../components/state_city';
//import CascadingDropdown from '../components/Cascading';
 
import Axios from 'axios';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';





export default function BookingScreen(props) {


  // 
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;
  // 
  const userInfo_info = localStorage.getItem("userInfo");
  const userInfo_myArr = JSON.parse(userInfo_info);

  const bookinginfo = useSelector((state) => state.bookinginfo);
  const { bookInfo,error} = bookinginfo;
  
  const navigate = useNavigate();
  
  const [usertoken, setUsertoken] = useState(userInfo_myArr?.token ? userInfo_myArr?.token :'');
  const [isDealer, setIsDealer] = useState(userInfo_myArr?.isSeller ? 'Y' : 'N');

  const [name, setName] = useState( isDealer == "N" ? userInfo_myArr?.name : '');
  const [mobile, setMobile] = useState(userInfo_myArr?.mobile ? userInfo_myArr?.mobile :'');
  const [email, setEmail] = useState(userInfo_myArr?.email ? userInfo_myArr?.email :'');
 
  //const usertoken = userInfo_myArr?.token ? userInfo_myArr?.token : '';
  //const isDealer = userInfo_myArr?.isSeller ? 'Y' : 'N';


    // 
  const [selectedState, setSelectedState] = React.useState(userInfo_myArr?.state ? userInfo_myArr?.state :'');
  const [selectedCity, setSelectedCity] = React.useState(userInfo_myArr?.city ? userInfo_myArr?.city :'');
  // const [selectedHub, setSelectedHub] = React.useState(userInfo_myArr?.area ? userInfo_myArr.area :'');
  const [selectedHub, setSelectedHub] = React.useState('');

 
  const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;
  // 

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  
  const redirect = redirectInUrl ? redirectInUrl :  '/';
  ///
 

  const [SlotAvilable, setSlotAvilable] =  useState([]);
  
  //const userRegister = useSelector((state) => state.userRegister);
  //const { userInfo } = userRegister;

  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
   if(SlotAvilable?.city_stock=="ok"){
        await dispatch(booking(name,mobile, email,  selectedState, selectedCity, selectedHub,usertoken,isDealer));
          console.log(error);
        if(error)
        {
          //dispatch({ type: USER_BOOK_RESET });
          alert('Your are having account with Revolt, Please SignIn!')
          navigate(`/product/RM0400RRCP1CWBLK`);
        }else
        { 
          //if(bookInfo) {
            
            navigate(`/product/RM0400RRCP1CWBLK`);
          //}
        }
  }
  else{
     
  }
   
  // navigate(`/product/RM0400RRCP1CWBLK`);
   //navigate(`/cart/${productId}?qty=${qty}`);
   //zconsole.log(error)
  };

  const checkBookingSlot = async (e) =>{
    setSelectedHub(e);
    try {
      const { data } = await Axios.get(`${URL_API}/api/v1/products/checkbookingslots/${e}`  );
      console.log(data.data);
      setSlotAvilable(data.data);
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
     
  };
  // useEffect(() => {
 
 
  //   console.log(error)
  //   if (error) { 
     
  //   //   // navigate('/product/RM0400RRCP1CWBLK');/signin?redirect=
  //   //   // navigate(`/product/RM0400RRCP1CWBLK`);
  //   dispatch({ type: USER_BOOK_RESET });
    
  //    }
  // }, [navigate, redirect, bookInfo,error]);
  useEffect(() => {
    localStorage.removeItem('cartItems');
    if (error) {
      //navigate(redirect);
      navigate(`/booking`);
    }
  }, [navigate, redirect, bookInfo,error]);

  
  return (
    <div>
      {/*  */}
      <section className="signinnsignup detailsflow">
    <div class="container">
    <div className="row justify-content-center">  
      <div className="col-12 col-sm-9 col-md-7 col-lg-11 col-xl-12 text-center p-0    ">  
          <div className="card px-0  pb-0   ">
                 
              <form id="msform" className="form" onSubmit={submitHandler}> 
                  <ul id="progressbar">  
                      <li className="active" id="account"><strong>Your Details </strong></li>  
                      <li id="personal"><strong> Choose Model & MRP</strong></li>  
                      <li id="payment"><strong> Booking Payment </strong></li>  
                      <li id="confirm"><strong> Start My Revolt Plan </strong></li>  
                  </ul>  
                    
                  <fieldset>  
                      <div className="form-card">  
                          <div className="row">  
                              <div className="col-12">  
                              {error && <MessageBox variant="danger">{error}</MessageBox>}
                 
                                  <h2 className="fs-title text-center">Hey! Great to see you in the future.<br/>
Let's help you book your Revolt.</h2>  
      <p>Please enter your personal details below. Make sure you enter the information with which you want to purchase & register your Revolt Motorcycle. All payment related information and other important updates will be shared on those contact details only. Booking your Revolt involves a nominal cost — an amount which is completely refundable upon cancellation.

</p>  
                              </div>  
                                
                          </div> 
                      <div className="login-space">	
                      <div className="row">	
                          <div className="form-group col-md-4 name-group">
      <img src="images/users.png" className="iconleft" alt=""/>
        <div className="palceholder">
        
          <label htmlFor="name">User Name / Mobile Number</label>
           <span className="star">*</span>
        
        </div>
       
         
        <input
            type="text"
            className="form-control inputfield"
            id="name"
            placeholder="Name"
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
      </div>
      <div className="form-group col-md-4 name-group">
         <img src="images/phone-icon.png" className="iconleft" alt=""/>
        <div className="palceholder"  >
       
          <label htmlFor="Phone">Mobile </label>
          <span className="star">*</span>
        </div>
       
         <input
            type="text"
            id="mobile"
            className="form-control inputfield"
            placeholder="Mobile"
            value={userInfo_myArr?.mobile}
            name="mobile"
            minLength="10" maxLength="10"
            required
            onChange={(e) => setMobile(e.target.value)}
          ></input>
      </div>
      
      <div className="form-group col-md-4 email-group">
      <img src="images/email-icon.png" className="iconleft emails" alt=""/>
        <div className="palceholder" >
        
          <label htmlFor="email">Email </label>
          <span className="star">*</span>
          
        </div>
          <input
            type="email"
            id="email"
            className="form-control inputfield"
            placeholder="Email"
            name="email"
            required
            value={userInfo_myArr?.email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
      </div>
      <div className="form-group col-md-4">
      
      <select id="state"
      className="form-control"
          placeholder="Select State"
          required
          value={selectedState}
          name="state"
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option>Choose State</option>
          {stateList.map((value, key) => {
            return (
              <option value={value.state_id} key={key}>
                {value.state_name}
              </option>
            );
          })}
        </select>
</div>
<div className="form-group col-md-4">
      
      <select   id="city"
      className="form-control"
          placeholder="City"
          value={selectedCity}
          name="city"
           
          onChange={(e) => setSelectedCity(e.target.value)}
              
          required
        >
          <option>Choose City</option>
          {availableCity.map((e, key) => {
            return (
              <option value={e.city_id} key={key}
              >
                {e.city_name}
              </option>
            );
          })}
        </select>
</div>
<div className="form-group col-md-4">
       
      <select  id="area"
          placeholder="Dealer Hub"
          className="form-control"
          name="area"
          required
          value={selectedHub}
          // onChange={(e) => setSelectedHub(e.target.value)}
          onChange={(e) => checkBookingSlot(e.target.value)}
        >
          <option value="">Dealer Hub</option>
          {availableHub.map((e, key) => {
            return (
              <option value={e.hub_id} key={key}>
                {e.hub_name}
              </option>
            );
          })}
        </select>
        <span id="message" className="mt-1 text-left pl-2">{SlotAvilable?.city_stock!="ok"?SlotAvilable?.city_stock:""}</span>
</div>
<div className="form-group col-md-4 checkboxihave">
<label><input type="checkbox" required />I have read and I agree to the <a target="_blank" href="/terms">Terms & Conditions</a> for this booking.</label>
</div>
      </div>
      </div>
                      </div> <input type="submit" 
                      name="next" 
                      className="next action-button" 
                      value="Next" 
                      disabled={SlotAvilable?.city_stock!="ok"}
                      /> 
                       
                  </fieldset>  
            
              </form>  
          </div>  
      </div>  
  </div>  
</div>

</section>



      {/*  */}
    </div>
  );
}
