import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { register } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
//import Casecading from '../components/state_city';
//import CascadingDropdown from '../components/Cascading';
// const data = {
//   countries: [
//     //{ name: "Assam", Key:18, states: [{ name:"Guwahati", cities: [{name:"Revolt Hub Guwahati", discount:20000}] }] },
//     {
//       name: "Andhara Pradesh", Key:10,
//       states: [
//         { name: "Visakhapatnam",key:16, cities: [{name:"Visakhapatnam", Key:16}] },
//         { name: "Vijayawada",key:16, cities: [{name:"Visakhapatnam", Key:17}] }
       
//       ]
//     },
//     {
//       name: "Bihar", Key:10,
//       states: [
//         { name: "Patna",key:7, cities: [{name:"Patna", Key:22}] },
       
//       ]
//     },
//     {
//       name: "Delhi", Key:1,
//       states: [

//         { name: "North Delhi", key:2, cities: [{name:"G.T. Karnal Road", Key:"2"} 
//       ] },
//         { name: "West Delhi", key:2, cities: [  {name:"Preet Vihar", discount:"3"},{name:"Dwarka Sec-5", Key:"4"} 
//       ] },
     
        
//       ]
//     }, 
//     {
//       name: "Gujarat", Key:4,
//       states: [
//         { name: "Ahmedabad", cities: [{name:"Naroda", discount:12},{name:"Himalaya mall", Key:13}] },
//         { name: "Surat", cities: [{name:"Surat", Key:81}] }, 
//         { name: "Vadodara", cities: [{name:"Vadodara", Key:27}] },
//         { name: "Rajkot", cities: [{name:"Rajkot", Key:28}] }
//       ]
//     }, 
//     {
//       name: "Karnataka", Key:6,
//       states: [
//         { name: "Bengaluru", key:10, cities: [{name:"Kormangala", Key:"79"}] }
       
//       ]
//     },
//     {
//       name: "Kerala", Key:23,
//       states: [
//         { name: "Kochi", key:80, cities: [{name:"Kochi", Key:42}] },
//         { name: "Thrissur", key:81, cities: [{name:"Thrissur", Key:43}] },
//         { name: "Kozhikode", key:82, cities: [{name:"Kozhikode", Key:44}] }
         
//       ]
//     },
//     {
//       name: "Madhya Pradesh", Key:12,
//       states: [
//         { name: "Indore", key:83, cities: [{name:"Indore", Key:45}] }
       
//       ]
//     },
//     {
//       name: "Maharashtra", Key:5,
//       states: [
//         { name: "Pune", cities: [{name:"ICC Tech Park", "discount":6},{name:"Kalyani Nagar", Key:7}] },
//         { name: "Mumbai", cities: [{name:"Andheri(West)", Key:15}] },
//         { name: "Nagpur", cities: [{name:"Nagpur", Key:47}] },
//         { name: "Navi Mumbai", cities: [{name:"Navi Mumbai", Key:50}] }
//       ]
//     },
//     {
//       name: "NCR", Key:17,
//       states: [
//         { name: "Gurugram", key:10, cities: [{name:"Gurugram", Key:52}] },
//         { name: "Noida", key:10, cities: [{name:"Noida", Key:53}] }
       
//       ]
//     },
//     {
//       name: "Rajasthan", Key:7,
//       states: [ 
//         { name: "Jaipur", cities: [{name:"Jaipur", Key:59}] },
//         { name: "Jodhpur", cities: [{name:"Jodhpur", Key:60}] },
//         { name: "Udaipur", cities: [{name:"Udaipur", Key:61}] } 
 
//       ]
//     } ,
    
//     {
//       name: "Tamilnadu", Key:2,
//       states: [
//         { name: "Chennai",key:53, cities: [{name:"Velachery", Key:10},
//         {name:"Anna nagar", Key:11}] },
//         { name: "Coimbatore",key:55, cities: [{name:"Coimbatore", Key:63}] },
//         { name: "Madurai",key:55, cities: [{name:"Madurai", Key:64}] }
//       ]
//     },
 
//     {
//       name: "Telangana", Key:3,
//       states: [
//         { name: "Hyderabad", key:10, cities: [{name:"Jubilee Hills", Key:8}]},
//         { name: "Warangal", key:10, cities: [{name:"Warangal", Key:67}] }
       
//       ]
//     },
//     {
//       name: "Uttar Pradesh", Key:9,
//       states: [
//         { name: "Lucknow", key:10, cities: [{name:"Lucknow", Key:71}] },
//         { name: "Ghaziabad", key:10, cities: [{name:"Ghaziabad", Key:76}] }  
//       ]
//     },
//     {
//       name: "West Bengal", Key:8,
//       states: [
//         { name: "Kolkata", key:10, cities: [{name:"Kolkata", Key:77}] }
       
//       ]
//     } 
//   ]
// };

export default function RegisterScreen(props) {


  // 
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;
  // 
 // 
 const [selectedState, setSelectedState] = React.useState('');
 const [selectedCity, setSelectedCity] = React.useState('');

 const [selectedHub, setSelectedHub] = React.useState('');
 const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;
  // 


  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
  

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  
  const redirect = redirectInUrl ? redirectInUrl :  '/';
 

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;
  const isEnabled = name.length>0 && mobile.length>0 && email.length>0 && password.length>0 ;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
   
      dispatch(register(name,mobile, email,selectedState,selectedCity,selectedHub,password));
   

  };
  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);
  return (
    <div>

      {/*  */}
      <section className="signinnsignup">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pd-0 pr-0 loginimgH">
              <img src="images/signinlogin_img.jpg" alt="" />
            </div>
            <div className="col-md-6 pd-0 pr-0 pl-0">
              <div className="card">
                <div className="login-box">
                  <div className="login-snip">
                    <input
                      // id="tab-1"
                      type="radio"
                      name="tab"
                      className="sign-in"
                      
                    />
                    <label 
                    // htmlFor="tab-1"
                     className="tab">
                      
                      <Link to={`/signin?redirect`} style={{color:"#000"}}>Sign In</Link>
                    </label>
                    <input
                      // id="tab-2"
                      type="radio"
                      name="tab"
                      className="sign-up"
                      checked
                    />
                    <label 
                    // htmlFor="tab-2" 
                    className="tab">
                    Sign Up
                    </label>
                    <div className="login-space">
                        {/* signup start */}
                            <div className="sign-up-form">
                            <form className="form" onSubmit={submitHandler}>

                              {/*  */}
                              {loading && <LoadingBox></LoadingBox>}
                              {error && <MessageBox variant="danger">{error}</MessageBox>}
                              {/*  */}
                              {/* fullname start */}
                              <div className="form-group name-group">
                              <img src="images/users.png" className="iconleft" alt=""/>
                              <div className="palceholder" >

                              <label htmlFor="name">Name</label>
                              <span className="star">*</span>

                              </div>

                               
                              <input
                              className="form-control inputfield"
                              type="text"
                              id="name"
                              placeholder="Name"
                              required
                              onChange={(e) => setName(e.target.value)}
                            ></input>
                              </div>
                              {/* fullname end */}

                              {/* Mobile start */}
                              <div className="form-group name-group">
                              <img src="images/phone-icon.png" className="iconleft" alt=""/>
                              <div className="palceholder" >

                              <label htmlFor="Phone">Mobile</label>
                              <span className="star">*</span>
                              </div>

                               
                              <input
                              className="form-control inputfield"
                              type="text"
                              id="mobile"
                              placeholder="Mobile"
                              minLength="10" maxLength="10"
                              required
                              onChange={(e) => setMobile(e.target.value)}
                              ></input>
                              </div>
                            {/* mobile end */}

                              {/* email start  */}
                              <div className="form-group email-group">
                              <img src="images/email-icon.png" className="iconleft" alt=""/>
                              <div className="palceholder" >

                              <label htmlFor="email">Email </label>
                              <span className="star">*</span>

                              </div>
                               
                              <input
                              type="email"
                              id="email"
                              className="form-control inputfield"
                              required
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                               ></input>
                              </div>
                              {/* email start  */}

                              {/*  States start */}
                              
                              <div className="form-group name-group dropdn"   >
                               <div className="row">
                               <div className="col-md-6">
                               <div className="palceholder">

<label htmlFor="pass">Select State</label>
<span className="star">*</span>

</div>
  {/* <select id="state"
    required
    className="form-control"
    value={selectedCountry}
    onChange={(e) => setSelectedCountry(e.target.value)}
  >
    <option value="">--Choose State--</option>
    {data.countries.map((value, key) => {
      return (
        <option value={value.key} key={key}>
          {value.name}
        </option>
      );
    })}
  </select> */}
  <select id="state"
      className="form-control"
          placeholder="Select State"
          required
          value={selectedState}
          name="state"
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option>Choose State</option>
          {stateList.map((value, key) => {
            return (
              <option value={value.state_id} key={key}>
                {value.state_name}
              </option>
            );
          })}
        </select>
                               </div>
<div className="col-md-6">
     
<div className="palceholder">

<label htmlFor="pass">City</label>
<span className="star">*</span>

</div>

{/* <select   id="city"
className="form-control"
  placeholder="City"
  value={selectedState}
  onChange={(e) => setSelectedState(e.target.value)}
>
  <option>--Choose City--</option>
  {availableState?.states.map((e, key) => {
    return (
      <option value={e.name} key={key}>
        {e.name}
      </option>
    );
  })}
</select> */}

<select   id="city"
      className="form-control"
          placeholder="City"
          value={selectedCity}
          name="city"
           
          onChange={(e) => setSelectedCity(e.target.value)}
              
          required
        >
          <option>Choose City</option>
          {availableCity.map((e, key) => {
            return (
              <option value={e.city_id} key={key}
              >
                {e.city_name}
              </option>
            );
          })}
        </select>
</div>
</div>        
                                </div>
                              {/* State end */}
                              
                               {/* City start  */}
                               {/* <div className="form-group name-group"  style={{width:"45%"}}  >
                               
                              <div className="palceholder">

                              <label htmlFor="pass">City</label>
                              <span className="star">*</span>

                              </div>

                              <select   id="city"
                              className="form-control"
                                placeholder="City"
                                value={selectedState}
                                onChange={(e) => setSelectedState(e.target.value)}
                              >
                                <option>--Choose City--</option>
                                {availableState?.states.map((e, key) => {
                                  return (
                                    <option value={e.name} key={key}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                              </select>
                              </div> */}
                            {/* City end  */}
 

                              {/* area starts */}
                              {/* <div className="form-group dropdn">  

                              <select  id="area"
                              className="form-control"
                                placeholder="Hub City"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                              >
                                <option>--Revolt Hub City--</option>
                                {availableCities?.cities.map((e, key) => {
                                  return (
                                    <option value={e.discount} key={key}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                              </select>
                              </div> */}
                              {/* area end */}


                                {/* Password start  */}
                              <div className="form-group name-group">
                              <img src="images/password-icon.png" className="iconleft" alt=""/>
                              <div className="palceholder">

                              <label htmlFor="password">Password</label>
                              <span className="star">*</span>

                              </div>

                              <input type="password" placeholder="Password" minlength="8" maxlength="8" className="form-control inputfield" id="password" name="password" required  onChange={(e) => setPassword(e.target.value)}/>
                              </div>
                              {/* Password end  */}

                               

                              <div className="group">
                              <input type="submit" className="button" value="Sign Up"
                              disabled={!isEnabled}
                              />
                              </div>
                              <div className="hr"></div>
                              {/* <div className="foot">
                              <label htmlFor="tab-1">Already Member?    </label>
                              <Link to={`/signin?redirect=${redirect}`}> Sign-In</Link>
                              </div> */}

                            </form> 
                            </div>

                        {/* signupend */}
                    </div>
                    {/*  */}
                    </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      {/*  */}

      
    </div>
  );
}
