import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios'; 
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { booking } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import  { userBookingReducer } from '../reducers/userReducers';
import { URL_API } from '../constants/cartConstants';
 import OtpTimer from 'otp-timer';
 import RevoltMap from '../components/revoltmap';

 const data = {
  countries: [
      
    {
      name: "Delhi", Key:1,
      states: [
        { name: "G.T. Karnal Road", key:2, cities: [{name:"Revolt Hub G.T. Karnal", discount:"16200"}] },
        { name: "Dwarka Sec-5", key:4, cities: [{name:"Revolt Hub Dwarka", discount:"16200"}] },
         
      ]
    },
     
    {
      name: "Pune", Key:2,
      states: [
        { name: "ICC Tech Park",key:6, cities: [{name:"Revolt Hub ICC Tech Park", discount:20000}] },
        { name: "Kalyani Nagar",key:7, cities: [{name:"Revolt Hub Kalyani", discount:20000}] }
         
      ]
    },
     
    {
    name: "Ahmedabad", Key:3,
    states: [
      { name: "Naroda",key:12, cities: [{name:"Revolt Hub Naroda", discount:20000}] },
      { name: "Ashram Road",key:13, cities: [{name:"Revolt Hub Ashram", discount:20000}] }
        
    ]
  },
  
  {
      name: "Hyderabad", Key:4,
      states: [
        { name: "Jubilee Hills",key:8, cities: [{name:"Revolt Hub Jubilee", discount:20000}] },
        
          
      ]
    },
  
    {
      name: "Chennai", Key:5,
      states: [
          { name: "Velachery ",key:10, cities: [{name:"Revolt Hub Velachery", discount:20000}] },
          { name: "Anna nagar",key:11, cities: [{name:"Revolt Hub Anna", discount:20000}] }
          
      ]
      },

      {
          name: "Mumbai", Key:6,
          states: [
          { name: "Andheri(West)",key:15, cities: [{name:"Revolt Hub Andheri", discount:20000}] },
          
              
          ]
      },

      {
          name: "Bengaluru", Key:7,
          states: [
          { name: "Kormangala",key:79, cities: [{name:"Revolt Hub Kormangala", discount:20000}] },
          
              
          ]
      } ,

      {
          name: "Jaipur", Key:8,
          states: [
          { name: "Jaipur",key:59, cities: [{name:"Revolt Hub Jaipur", discount:20000}] },
          
              
          ]
      },
      {
          name: "Surat", Key:8,
          states: [
          { name: "Surat",key:81, cities: [{name:"Revolt Hub Surat", discount:20000}] },
          
              
          ]
      }   
    
  ]
};


function ContactUs() {
   // 
   const statecityhub = localStorage.getItem("state_city_hub");
   const stateList_myArr = JSON.parse(statecityhub);
   const stateList = stateList_myArr.state;
   const cityList = stateList_myArr.city;
   const hubList = stateList_myArr.hub;
   // 

  const userInfo_info = localStorage.getItem("userInfo");
  const userInfo_myArr = JSON.parse(userInfo_info);


  const navigate = useNavigate();
  const [name, setName] = useState(userInfo_myArr?.name ? userInfo_myArr.name : '');
  const [mobile, setMobile] = useState(userInfo_myArr?.mobile ? userInfo_myArr.mobile :'');
  const [email, setEmail] = useState(userInfo_myArr?.email ? userInfo_myArr.email :''); 
    
  const [comment,setComment] = useState('');
  const [interestedin,setInterestedin] = useState('');
  const [whatsapp,setWhatsapp] = useState(false);
  
  const [selectedState, setSelectedState] = React.useState(userInfo_myArr?.state ? userInfo_myArr.state :'');
  const [selectedCity, setSelectedCity] = React.useState(userInfo_myArr?.city ? userInfo_myArr.city :'');
  const [selectedHub, setSelectedHub] = React.useState(userInfo_myArr?.area ? userInfo_myArr.area :'');

 
  const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;
  
  
  const [otp, setOtp] = useState('');
  const [otpbtn, setOtpbtn] = useState(true);
  const [mobileverify, setMobileverify] = useState(false);
 
 
  async function submitcontactus(name,mobile, email,state,city,interestedin,whatsapp,comment) {
    

    
    
    //insert test ride start 
     const { data } = await Axios.post(`${URL_API}/api/v1/customer/contactus`, 
     {name,mobile, email, state,city,interestedin,whatsapp,comment});
      
    
     console.log(data)
     if(data){
    //  navigate('/thankyouride');
     navigate(`/thankyoucontact/${data.contactus_id}`);
     }

    //  insert test ride end
   }


  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    //verfit otp 
    try {
      const { data } = await Axios.post(`${URL_API}/api/v1/auth/verifyotp`, { mobile, otp });
      
      
       
    if(data.status === true)
    {
      message.innerHTML = "";
       
      dispatch(submitcontactus(name,mobile, email, selectedState,selectedCity,interestedin,whatsapp,comment));
   
    }
    else
    {
     
    
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid OTP Number."  ;
      
    }

    } catch (error) {
       
    }

    // otp verify end 


     
  };

  async function checkOTP(checkbox) {




    
    let mobile_no =  document.getElementById('mobile').value;
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    
    if(mobile_no == "" || mobile_no == null || mobile_no.length != 10)  {
       
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid Mobile Number."  ;
    }
      else{
        
        const {data} = await Axios.get(`${URL_API}/api/v1/auth/sendotp/${mobile}`)
         
        setOtpbtn(false);
        // message.innerHTML = "";
      }
   

  };

  return (
   <>
    <section>
          <div>
            <img src="images/Contact_Us.png" alt="" width="100%" />
          </div>
        </section>
        <section className="contactus-grey">
          <div className="contactus-grey-box">
            <div className="contactus-white-box">
              <div className="contactus-partone">
                <div className="contactus-partone-contentbox">
                  <h3>Get in touch</h3>
                  <div className="contactus-partone-content">
                    <img src="images/location2.png" alt="" />
                    <p><span>Revolt House</span> <br />No.4, Sector 8 Rd, Imt Manesar,<br />Gurugram, Haryana 122051</p>
                    {/* <p>No.4, Sector 8 Rd, Imt Manesar,<br>Gurugram, Haryana 122051</p> */}
                  </div>
                  {/* test  */}
                  <div className="contactus-partone-content">
                    <img src="images/contact_email.png" alt="" />
                    <p><span>contact@revoltmotors.com </span><br />Email Us</p>
                    {/* <hr> */}
                    {/* <p>No.4, Sector 8 Rd, Imt Manesar,<br>Gurugram, Haryana 122051</p> */}
                  </div>
                  <div className="contactus-partone-content">
                    <img src="images/call.png" alt="" />
                    <p><span>+91-9873050505</span><br />Helpline (Mon-Sun, 10 AM-7 PM)</p>
                    {/* <vr className="underline" style="color: white;"> */}
                    {/* <p>No.4, Sector 8 Rd, Imt Manesar,<br>Gurugram, Haryana 122051</p> */}
                  </div>
                  <div className="contactus-partone-content">
                    <img src="images/whatsapp.png" alt="" />
                    <p><span>+91-9873050505</span><br />WhatsApp Us</p>
                    {/* <p>No.4, Sector 8 Rd, Imt Manesar,<br>Gurugram, Haryana 122051</p> */}
                  </div>
                  <div className="contactus-partone-content">
                    <img src="images/rbike.png" alt="" />
                    <p><span>Schedule a test ride</span> <br />Schedule a test ride online</p>
                    {/* <p>No.4, Sector 8 Rd, Imt Manesar,<br>Gurugram, Haryana 122051</p> */}
                  </div>
                  {/* test  */}
                </div>
                {/* test 2  */}
                <div className="social">
                  <h3>Follow us</h3>
                  <div><a href="https://www.facebook.com/revoltmotorsin/" target="_blank"><i className="fa fa-facebook" aria-hidden="true" /></a></div>
                  <div><a href="https://twitter.com/RevoltMotorsIN" target="_blank"><i className="fa fa-twitter" aria-hidden="true" /></a></div>
                  <div><a href="https://www.linkedin.com/company/revolt-intellicorp/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true" /></a></div>
                  <div><a href="https://www.instagram.com/revoltmotorsin/" target="_blank"><i className="fa fa-instagram" aria-hidden="true" /></a></div>
                  <div><a href="https://www.youtube.com/channel/UC2tW9kRUr1mGhS8oA_IQEnQ" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true" /></a></div>
                </div>
              </div>
              {/* test 2  */}
              <div className="contactus-parttwo">
                {/* try  */}
                <div className="login-space new-contact">	
                <form  id="contactus" onSubmit={submitHandler}> 
                  <div className="row rwo">	
                    <div className="form-group col-md-12 name-group">
                      <img src="images/users.png" className="iconleft" alt="" />
                      <div className="palceholder">
                        {/* <label htmlFor="name">Name</label>
                        <span className="star">*</span> */}
                      </div>
                      
                      <input
                      type="text"
                      className="form-control inputfield"
                      id="name"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                    </div>
                    <div className="form-group col-md-12 name-group">
                      <img src="images/email-icon.png" className="iconleft" alt="" />
                      <div className="palceholder" style={{display: 'block'}}>
                        {/* <label htmlFor="email">Email</label>
                        <span className="star">*</span> */}
                      </div>
                      <input
                        type="email"
                        id="email"
                        className="form-control inputfield"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group col-md-12 email-group">
                      <img src="images/phone-icon.png" className="iconleft emails" alt="" />
                      <div className="palceholder" style={{display: 'block'}}>
                        {/* <label htmlFor="mobile">Mobile</label>
                        <span className="star">*</span> */}
                      </div>
                      <input
                        type="text"
                        id="mobile"
                        className="form-control inputfield"
                        placeholder="Mobile"
                        value={mobile}
                        minLength="10" maxLength="10"
                        required
                        onChange={(e) => setMobile(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group col-md-12 email-group">
                      <img src="images/City.png" className="iconleft emails" alt="" />
                      <div className="palceholder" style={{display: 'block'}}>
                        {/* <label htmlFor="city">Select City </label>
                        <span className="star">*</span> */}
                      </div>
                      <select   id="state"
                      className="form-control"
                          placeholder="State"
                          value={selectedState}
                          
                          
                          onChange={(e) => setSelectedState(e.target.value)}
                              
                          required
                        >
                          <option value="" >Choose State</option>
                          {stateList.map((value, key) => {
                            return (
                              <option value={value.state_id} key={key}>
                                {value.state_name}
                              </option>
                            );
                          })}
        </select>
                    </div>

                    <div className="form-group col-md-12 email-group">
                      <img src="images/City.png" className="iconleft emails" alt="" />
                      <div className="palceholder" style={{display: 'block'}}>
                        {/* <label htmlFor="city">Select City </label>
                        <span className="star">*</span> */}
                      </div>
                      <select   id="city"
                      className="form-control"
                          placeholder="City"
                           
                          
                          
                          value={selectedCity}
          
           
                          onChange={(e) => setSelectedCity(e.target.value)}
                        >
                          <option value="" >Choose City</option>
                          {availableCity.map((e, key) => {
                          return (
                            <option value={e.city_id} key={key}
                            >
                              {e.city_name}
                            </option>
                          );
                        })}
                      </select>
                      </div>

                    <div className="form-group col-md-12 email-group">
                      <img src="images/Interested.png" className="iconleft emails" alt="" />
                      <div className="palceholder" style={{display: 'block'}}>
                        {/* <label htmlFor="interestedin">Im interested in</label>
                        <span className="star">*</span> */}
                      </div>
                      <select className="form-control" id="interested_in" name="interestedin" onChange={(e) => setInterestedin(e.target.value)}>
                      <option value="">I'm Interested In</option>
                      <option value="The Motorcycle">The Motorcycle</option>
                      <option value="A Presser">Getting in touch with PR Team</option>
                      <option value="Business Query">Other Business Query</option>
                      <option value="Career">Career</option>
                      </select>
                    </div>
                    <div className="form-group col-md-12 email-group">
                      <img src="images/email-icon.png" className="iconleft emails" alt="" />
                      <div className="palceholder" style={{display: 'block'}}>
                        {/* <label htmlFor="message">Message</label>
                        <span className="star">*</span> */}
                      </div>
                      <input type="textarea" placeholder="Message" className="form-control inputfield" id="textarea" required height="200px"  onChange={(e) => setComment(e.target.value)}/>
                    </div>
                    <div className="form-group col-md-12 email-group contactus-whatsapp">
                      <p>Get <img className="wp" src="images/wp.png" alt="" /> WhatsApp Remainders &amp; updates on everything that's relevant.</p>
                      <label htmlFor="check" className="contactus-label">
                      <input 
                      type="checkbox" 
                      name="whatsapp"
                      className="input" 
                        
                      onChange={(e) => setWhatsapp(e.target.checked)}
                      
                      />
                     Yes, opt me in</label>
                    </div>
                    <div className="contactus-message">

                      {/* otp field start */}
{ !otpbtn ? 
   <>
   <div className="otp_wraper">
  <div className="form-group col-md-4 name-gro/up otp_field">
        
        <div className="palceholder"  >
       
          <label  >OTP </label>
          <span className="star">*</span>
        </div>
       
         <input
            type="text"
            id="otp"
            className="form-control inputfield"
            placeholder="OTP"
           
            onChange={(e) => setOtp(e.target.value)}
            
            required
            
          ></input>
           <span id="message"></span>
      </div>
      </div>
      </>
  : ''
  }

   {/* otp field end */}



            {/*  */}
 <div className="otp_btn_wrapper">
{ otpbtn ? 
                          <input
                            type="button"
                            className="next action-button "
                            value="Send OTP"
                            
                            disabled={!(mobile.length==10)}
                            onClick={(e) => checkOTP(e.target)}
                            
                          />     

                          :
                          <div className="group otp_wrapper">
                            <div>
                          <OtpTimer seconds= {5} minutes={0} resend={checkOTP} text="Resend OTP After" />
                         </div></div>
                        }
 
 </div>


                      <button type="submit"  disabled={otpbtn}   style={{backgroundColor: 'black', color: '#fff'}} className="btn mt-2">SEND MESSAGE</button>
                    </div>
                    <div className="contactus-last">
                      <p>*By Clicking "Book Test ride", I give my consent to Revolt Motors Pvt. Ltd and its service providers to hold my personal data and communicate with me by e-mail, Whatsapp, SMS or phone call for test ride purposes. </p>
                    </div>
                    
                  </div>
                  </form>
                </div>
              </div>
            </div></div></section>
        {/* map section starts  */}
        <RevoltMap></RevoltMap>
        {/* map section ends  */}
   </>
  )
}

export default ContactUs