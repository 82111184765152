import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes,useParams,useNavigate  } from 'react-router-dom';
import { listProductCategories } from '../../actions/productActions';
import { signout } from '../../actions/userActions';
 
// import LoadingBox from '../../components/LoadingBox';
// import MessageBox from '.../../components/MessageBox';
import Axios from 'axios';
import {
  URL_API,ROOT_PATH
} from '../../constants/cartConstants';


function UserContactusScreen() {

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [contact_list, setContact_list] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  // const { id } = params;
  

  const getData = async () => {
    try {
      const { data } = await Axios.post(`${URL_API}/api/v1/customer/contactlist/${userInfo.mobile}`  );
      console.log(data);
      setContact_list(data.data.contactus)
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData()
   
}, []);


  return (
    <>
    <section className="signinnsignup">
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-12 col-xs-12">
        <div className="dashboard">
          <a href="/userdashboard">
            <div className="media">
              <img className="mr-3" src="images/sadboard-icon.png" alt="" />
              <div className="media-body">
                <h5 className="mt-0"> Hello</h5>
                <p>{userInfo.name}</p>
              </div>
            </div>
          </a>
          <div className="bookingride">
            <ul>
              <a href="/userbooking">
                <li>
                  <img src="images/bookingimg.png" /> <span>Bookings</span>
                </li>
              </a>
              <a href="/usertestride">
                <li >
                  <img src="images/testrideimg.png" /> <span>Test Rides</span>
                </li>
              </a>
              <a href="/usercontactus">
                <li className="active">
                  <img src="images/requestimg.png" /> <span>Request</span>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
        <div className="col-md-9 col-sm-12 col-xs-12">
          <div className="requestid">
            <div className="row">

            {contact_list?.map((contact) => (
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="requestride">
                  <h6>Request ID</h6>
                  <h4>{contact.contact_ref_id}</h4>
                  <h5>Message</h5>
                  <p>
                  {contact.comment}
                  </p>
                  <h5>Interestedin</h5>
                  <p>{contact.Interestedin}
                  </p>
                  <h5>Date</h5>
                  <p>{contact.contactus_date}
                  </p>
                  
                  <h5>Status</h5>
                  <h3>
                    <b>{contact.state=="1"?"Active":"Close"}</b>
                  </h3>
                </div>
              </div>
             
             ))}
            </div>
          </div>
        </div>

    </div>
  </div>
</section>

    </>
  )
}

export default UserContactusScreen