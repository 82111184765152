import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser, updateUserProfile } from '../../actions/userActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants';

export default function UserProfileEditScreen() {
   
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [sellerLogo, setSellerLogo] = useState('');
  const [sellerDescription, setSellerDescription] = useState('');
  
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;

  const userInfo_info = localStorage.getItem("userInfo");
  const userInfo_myArr = JSON.parse(userInfo_info);
 

  const [name, setName] = useState(userInfo_myArr?.name ? userInfo_myArr.name : '');
  const [mobile, setMobile] = useState(userInfo_myArr?.mobile ? userInfo_myArr.mobile :'');
  const [email, setEmail] = useState(userInfo_myArr?.email ? userInfo_myArr.email :''); 
  const [selectModel, setSelectModel] =  useState('');
  const [selectTestDate, setSelectTestDate] = useState('');
  const [selecttimeSlot, setSelectTimeSlot] = useState('');
   
  const [isSeller, setIsSeller] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  

  const [selectedState, setSelectedState] = React.useState(userInfo_myArr?.state ? userInfo_myArr.state :'');
  const [selectedCity, setSelectedCity] = React.useState(userInfo_myArr?.city ? userInfo_myArr.city :'');
  const [selectedHub, setSelectedHub] = React.useState(userInfo_myArr?.area ? userInfo_myArr.area :'');

  
  const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;
 
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;

  
  const dispatch = useDispatch();
  useEffect(() => {

    setName(userInfo?.name ? userInfo?.name:"");
    setEmail(userInfo?.email ? userInfo?.email :"" );
    setIsAdmin(userInfo?.isAdmin ? userInfo?.isAdmin :"");
    setIsSeller(userInfo?.isSeller ? userInfo?.isSeller : "");
    // console.log(userInfo);
    // if (userInfo) {
    //   console.log(user);
    //   dispatch({ type: USER_UPDATE_PROFILE_RESET });
    //   dispatch(detailsUser(userInfo.id));
    // } else {
    //   setName(userInfo.name);
    //   setEmail(userInfo.email);
    //  /* if (user.seller) {
    //     setSellerName(user.seller.name);
    //     setSellerLogo(user.seller.logo);
    //     setSellerDescription(user.seller.description);
    //   }*/
    // }
  }, [dispatch]);
  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch update profile
    if (password !== confirmPassword) {
      alert('Password and Confirm Password Are Not Matched');
    } else {
      dispatch(
        updateUserProfile({
          "userId": userInfo?.id,
          "name":name,
          "mobile":mobile,
          "email":email,
          "password":password,
          "state":selectedState, 
          "city":selectedCity, 
          "area":selectedHub,
          "sellerName":sellerName,
          "sellerLogo":sellerLogo,
          "sellerDescription":sellerDescription,
          "isSeller":isSeller,
          "isAdmin": isAdmin
        })
      );
    }
  };
  return (
    <>
 <section className="signinnsignup">
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-12 col-xs-12">
        <div className="dashboard">
          <a href="/userdashboard">
            <div className="media">
              <img className="mr-3" src="images/sadboard-icon.png" alt="" />
              <div className="media-body">
                <h5 className="mt-0"> Hello</h5>
                <p>{userInfo?.name}</p>
              </div>
            </div>
          </a>
          <div className="bookingride">
            <ul>
              <a href="/userbooking">
                <li>
                  <img src="images/bookingimg.png" /> <span>Bookings</span>
                </li>
              </a>
              <a href="/usertestride">
                <li>
                  <img src="images/testrideimg.png" /> <span>Test Rides</span>
                </li>
              </a>
              <a href="/usercontactus">
                <li className="active">
                  <img src="images/requestimg.png" /> <span>Request</span>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-9 col-sm-12 col-xs-12">
  <div className="requestid">
    <div className="personalinfo">
      <h5>Personal Information</h5>
      {/* <h4 className="float-right editoption"><a href="/profile">Edit</a></h4> */}
      
      <>
            {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && (
              <MessageBox variant="danger">{errorUpdate}</MessageBox>
            )}
            {successUpdate && (
              <MessageBox variant="success">
                Profile Updated Successfully
              </MessageBox>
            )}
            
  {/*  */}
      
  <table id="editprofile_table">
        <tbody>
          <tr>
            <td>
              <b>Name</b>
            </td>
            <td className="fwtd"> 
            <input
                id="name"
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <b>Email</b>
            </td>
            <td className="fwtd"> 
            <input
                id="email"
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            
            </td>
          </tr>
          <tr>
            <td>
              <b>Password</b>
            </td>
            <td className="fwtd"> 
            <input
                id="password"
                type="password"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            
            </td>
          </tr>
          <tr>
            <td>
              <b>Confirm Password</b>
            </td>
            <td className="fwtd"> 
            <input
                id="confirmPassword"
                type="password"
                placeholder="Enter confirm password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            
            </td>
          </tr>
        
          <tr>
            <td>
              <b>State</b>
            </td>
            <td className="fwtd"> 
            <select id="state"
                        className="form-control"
                            placeholder="Select State"
                            required
                            value={selectedState}
                            
                            onChange={(e) => setSelectedState(e.target.value)}
                          >
                            <option>--Choose State--</option>
                            {stateList.map((value, key) => {
                              return (
                                <option value={value.state_id} key={key}>
                                  {value.state_name}
                                </option>
                              );
                            })}
                          </select>
            
            </td>
          </tr>


          <tr>
            <td>
              <b>City</b>
            </td>
            <td className="fwtd"> 
            <select   id="city"
                        className="form-control"
                            placeholder="City"
                            value={selectedCity}
                            
                            
                            onChange={(e) => setSelectedCity(e.target.value)}
                                
                            required
                          >
                            <option>--Choose City--</option>
                            {availableCity.map((e, key) => {
                              return (
                                <option value={e.city_id} key={key}
                                >
                                  {e.city_name}
                                </option>
                              );
                            })}
                          </select>
            
            </td>
          </tr>

          <tr>
            <td>
              <b>Hub</b>
            </td>
            <td className="fwtd"> 
            <select  id="area"
                            placeholder="Dealer Hub"
                            className="form-control"
                            required
                            value={selectedHub}
                            onChange={(e) => setSelectedHub(e.target.value)}
                          >
                            <option value="">--Dealer Hub --</option>
                            {availableHub.map((e, key) => {
                              return (
                                <option value={e.hub_id} key={key}>
                                  {e.hub_name}
                                </option>
                              );
                            })}
                          </select>
            
            </td>
          </tr>

          <tr >
            <td>
              <b> </b>
            </td>
            <td className="fwtd"> 
          <a className="cancel_biooking"  onClick={submitHandler}>
                Update
              </a>
          </td>
          </tr>
        </tbody>
      </table>
    {/*  */}


          </>
    
    </div>
  </div>
</div>

    </div>
  </div>
</section>
    {/*  */}
      
         
      
        
       
       
    </>
  );
}
