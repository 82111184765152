import React from 'react'

function Gallery() {
  return (
   <>
      {/* Modal */}
  <div
    className="modal fade video-modalbox"
    id="exampleModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                        document.getElementById('exampleModal').style.display = 'none';
                        document.getElementById('exampleModal').style.opacity = 0;
                 }}>
          <img
            src="https://www.revoltmotors.com/assets/images/closebtn.png"
            loading="lazy"
            alt=""
          />
        </div>
        <div className="modal-body">
          <iframe
            width="100%"
            height={500}
            src="https://www.youtube.com/embed/8evSC5jNq_o?autoplay=1"
            frameBorder={0}
          />
        </div>
      </div>
    </div>
  </div>
  {/* Modal */}
     <section className="gallery">
          <div className="container">
            <h3 className="font38 hd_mbl_vw">GALLERY</h3>
            <div className="row">
              <div className="col-md-8 pr-0">
                <div id="demo1" className="carousel slide overlay-colr" data-ride="carousel">
                  {/* The slideshow */}
                  <div className="carousel-inner">
                    <div className="carousel-item active"  >
                      <img src="images/videoslider.png" 
                      data-toggle="modal" 
                      data-target="#exampleModal" 
                      className="overlay-colr" 

                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                       
                      />
                      <div className="video-hover"  onClick={() => {
                        document.getElementById('exampleModal').style.display = 'block';
                        document.getElementById('exampleModal').style.opacity = 1;
                       }}/>
                    </div>
                    <div className="carousel-item">
                      <img src="images/videoslider.png" 
                      // data-toggle="modal" 
                      // data-target="#exampleModal2" 
                      className="overlay-colr" 
                      
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      
                      />
                      <div className="video-hover" onClick={() => {
                        document.getElementById('exampleModal2').style.display = 'block';
                        document.getElementById('exampleModal2').style.opacity = 1;
                 }}/>
                    </div>
                  </div>
                  {/* Left and right controls */}
                  <a className="carousel-control-prev" href="#demo1" data-slide="prev">
                  </a>
                  <a className="carousel-control-next" href="#demo1" data-slide="next">
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div id="demo2" className="carousel slide overlay-colr" data-ride="carousel">
                      {/* The slideshow */}
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <a className="fancybox" href="images/imageslider.png" data-fancybox data-fancybox-group="gallery"  ><img src="images/imageslider.png" className="overlay-colr" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider.png" data-fancybox data-fancybox-group="gallery"  ><img src="images/imageslider.png" className="overlay-colr" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider.png" data-fancybox data-fancybox-group="gallery"  ><img src="images/imageslider.png" className="overlay-colr" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div id="demo3" className="carousel slide overlay-colr" data-ride="carousel">
                      {/* The slideshow */}
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <a className="fancybox" href="images/imageslider1.jpg" data-fancybox data-fancybox-group="gallery"  ><img className="heightdecrease" src="images/imageslider1.jpg" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider1.jpg" data-fancybox data-fancybox-group="gallery"  ><img className="heightdecrease" src="images/imageslider1.jpg" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                        <div className="carousel-item">
                          <a className="fancybox" href="images/imageslider1.jpg" data-fancybox data-fancybox-group="gallery"  ><img className="heightdecrease" src="images/imageslider1.jpg" /></a>
                          <div className="image-hover"  ></div>
                        </div>
                      </div>
                      {/* Left and right controls */}
                      <a className="carousel-control-prev" href="#demo3" data-slide="prev">
                      </a>
                      <a className="carousel-control-next" href="#demo3" data-slide="next">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
   
   </>
  )
}

export default Gallery