import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteOrder, listOrders } from '../../actions/orderActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { ORDER_DELETE_RESET } from '../../constants/orderConstants';

export default function OrderListScreen(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const sellerMode = pathname.indexOf('/dealer') >= 0;
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;
  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete;

  
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;

  const [selectfromdate, setSelectFromDate] = useState('');
  const [selecttodate, setSelectToDate] = useState('');
  const [selectedState, setSelectedState] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState([]);
  const [selectedHub, setSelectedHub] = React.useState([]);
  
  
  const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;
 
  
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  useEffect(() => {
   // console.log(userInfo);
    // dispatch({ type: ORDER_DELETE_RESET });

    const user_type = userInfo?.isSeller ? 'Y' : 'N';

    //dispatch(listOrders({ seller: sellerMode ? userInfo.id : '' }));
    dispatch(listOrders(userInfo.id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub,user_type));
   //
  }, [  successDelete, userInfo.id]);
  const deleteHandler = (order) => {
    if (window.confirm('Are you sure to delete?')) {
      dispatch(deleteOrder(order.order_id));
    }
  };

  const search = () => {
    const user_type = userInfo?.isSeller ? 'Y' : 'N';
    dispatch(listOrders(userInfo.id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub,user_type));
  }
  console.log(orders);
  return (
    <div>
      <h1>Bookings</h1>

      
<div className="form-group col-md-4">
      <div className="dealer_search">
      <select id="state"
      className="form-control"
          placeholder="Select State"
          required
          value={selectedState}
          
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option>--Choose State--</option>
          {stateList.map((value, key) => {
            return (
              <option value={value.state_id} key={key}>
                {value.state_name}
              </option>
            );
          })}
        </select>
       
      <select   id="city"
      className="form-control"
          placeholder="City"
          value={selectedCity}
          
           
          onChange={(e) => setSelectedCity(e.target.value)}
              
          required
        >
          <option>--Choose City--</option>
          {availableCity.map((e, key) => {
            return (
              <option value={e.city_id} key={key}
              >
                {e.city_name}
              </option>
            );
          })}
        </select>

       
      <select  id="area"
          placeholder="Dealer Hub"
          className="form-control"
          required
          value={selectedHub}
          onChange={(e) => setSelectedHub(e.target.value)}
        >
          <option value="">--Dealer Hub --</option>
          {availableHub.map((e, key) => {
            return (
              <option value={e.hub_id} key={key}>
                {e.hub_name}
              </option>
            );
          })}
        </select>

        <input
            type="date"
            id="fromdate"
            
            className="form-control inputfield"
            placeholder="from booking Date"
            required
            
           
            onChange={(e) => setSelectFromDate(e.target.value)}
          ></input>

<input
            type="date"
            id="todate"
            // min={disableDates()}
            
            min={selectfromdate}
            className="form-control inputfield"
            placeholder="Booking TO Date"
            required
            
            // onChange={(e) => setSelectTestDate(e.target.value)}
            onChange={(e) => setSelectToDate(e.target.value)}
          ></input>

          <button id="submit" onClick={search}>Search</button>
          </div>
</div>

      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>BOOKING ID</th>
              <th>NAME</th>
              <th>DATE</th>
              <th>BOOKING AMOUNT</th>
              {/* <th>TOTAL</th> */}
              <th>PAID</th>
              <th>STATUS</th>
              {/* <th>DELIVERED</th>*/}
              <th>ACTIONS</th> 
            </tr>
          </thead>
          <tbody>
            {orders?.data?.map((order) => (
              <tr key={order.order_id}>
                <td><a href={`/orderdetail/${order.order_id}`}>{order.booking_id}</a></td>
                <td>{order.customer_name}</td>
                <td>{order.createdAt.substring(0, 10)}</td>
                <td>{order.booking_amount.toFixed(2)}</td>
                {/* <td>{order.totalPrice.toFixed(2)}</td> */}
                <td>{order?.isPaid ? 'Paid' : 'Not Paid'}</td>
                <td>{order.order_status=="Created" ? 'Active' : order.order_status }</td>
                {/* <td>
                  {order.isDelivered
                    ? order.deliveredAt.substring(0, 10)
                    : 'No'}
                </td> */}
                <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      navigate(`/orderdetail/${order.order_id}`);
                    }}
                  >
                    Details
                  </button>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      navigate(`/cancelbooking/${order.booking_id}`);
                    }}
                  >
                    Cancel Order
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
