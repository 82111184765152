import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios'; 
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { booking } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import  { userBookingReducer } from '../reducers/userReducers';
import { URL_API } from '../constants/cartConstants';
 import OtpTimer from 'otp-timer';
  
export default function TestRideScreen(props) {
 
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;

  const userInfo_info = localStorage.getItem("userInfo");
  const userInfo_myArr = JSON.parse(userInfo_info);


  const navigate = useNavigate();
  const [name, setName] = useState(userInfo_myArr?.name ? userInfo_myArr.name : '');
  const [mobile, setMobile] = useState(userInfo_myArr?.mobile ? userInfo_myArr.mobile :'');
  const [email, setEmail] = useState(userInfo_myArr?.email ? userInfo_myArr.email :''); 
  const [selectModel, setSelectModel] =  useState('');
  const [selectTestDate, setSelectTestDate] = useState('');
  const [selecttimeSlot, setSelectTimeSlot] = useState('');
  

  const [selectedState, setSelectedState] = React.useState(userInfo_myArr?.state ? userInfo_myArr.state :'');
  const [selectedCity, setSelectedCity] = React.useState(userInfo_myArr?.city ? userInfo_myArr.city :'');
  const [selectedHub, setSelectedHub] = React.useState(userInfo_myArr?.area ? userInfo_myArr.area :'');

  
  const [TestRideSolts, setTestRideSlots] = useState([]);
  const [testride, setTestRide] = useState([]);
  const [otp, setOtp] = useState('');
  const [otpbtn, setOtpbtn] = useState(true);
  const [mobileverify, setMobileverify] = useState(false);
   
  const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;
 
  async function submittestride(name,mobile, email,model,state, city, hub,testdate, timeslot) {
    

    
    
    //insert test ride start 
     const { data } = await Axios.post(`${URL_API}/api/v1/customer/testRide`, 
     {name,mobile, email,model,state, city, hub,testdate, timeslot});
      
     setTestRide(data);
     //console.log(data)
     var message = document.getElementById('message');
     var goodColor = "#0C6";
     var badColor = "#ed1c24";

     if(data?.status==false){
      message.style.color = badColor;
      message.innerHTML = data.message  ;
      
    //  navigate('/thankyouride');
     
     }
     else
     {
      navigate(`/thankyouride/${data.ride_id}`);

     }

    //  insert test ride end
   }


  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    //verfit otp 

    try {
      const { data } = await Axios.post(`${URL_API}/api/v1/auth/verifyotp`, { mobile, otp });
      
      
       
    if(data.status === true)
    {
      message.innerHTML = "";
      dispatch(submittestride(name,mobile, email,selectModel, selectedState, selectedCity, selectedHub,selectTestDate, selecttimeSlot));
    
    }
    else
    {
     
    
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid OTP."  ;
      
    }

    } catch (error) {
       
    }

    // otp verify end 


     
  };

  const checkTimeSlots = async (e) =>{
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    setSelectTestDate(e);
    console.log(e)
    try {
       
      const { data } = await Axios.get(`${URL_API}/api/v1/customer/testrideslot/${selectedHub}/${e}`);
      //  console.log(data.data);
     
      
       
    if(data.status === true)
    {
      setTestRideSlots(data.data);
      message.innerHTML = "";
       
    }
    else
    {
      setTestRideSlots([]);
    
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid OTP."  ;
      
    }

    } catch (error) {
       
    }



  }

 async function checkOTP(checkbox) {




    
    let mobile_no =  document.getElementById('mobile').value;
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    if(mobile_no === "" || mobile_no === null || mobile_no.length != 10)  {
     
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid Mobile Number."  ;
    }
      else{
        
         
        const result_pay = await Axios.get(`${URL_API}/api/v1/auth/sendotp/${mobile}`)
        setOtpbtn(false);
        message.innerHTML = '';
        
      }
   

  };


 
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};
  return (
    <>
     
     <section className="unlockcity testride_banner">
	 

	  
   </section>
   
   <div className="unlock_bg ">
   
   <section>
   <div className="container">
   <nav aria-label="breadcrumb">
 <ol className="breadcrumb breadcrumbs">
   <li className="breadcrumb-item"><a href="#">Home</a></li>
   <li className="breadcrumb-item active" aria-current="page">Test Ride</li>
 </ol>
</nav>
</div>
   </section>
   
       <section className="unlockcity_inner">
   <div className="container">
      {/*  */}
              <form id="msform" className="form" onSubmit={submitHandler}> 
                   
                    
                  <fieldset>  
                      <div className="form-card">  
                      <div className="row">  
                                <div className="col-12">  
                                    <h2 className="fs-title text-center"><b>Hi, Please enter your details below to book a test ride
at a revolt hub near you.</b></h2>  
        <p>Please enter your personal details below. Make sure you enter the information with which you want to purchase & register your Revolt Motorcycle. All payment related information and other important updates will be shared on those contact details only. Booking your Revolt involves a nominal cost — an amount which is completely refundable upon cancellation.	</p>  
                                </div>  
                                  
                            </div> 
                      <div className="login-space">	
                      <div className="row">	
                          <div className="form-group col-md-4 name-group">
      <img src="images/users.png" className="iconleft" alt=""/>
        <div className="palceholder">
        
          <label  >User Name / Mobile Number</label>
           <span className="star">*</span>
        
        </div>
       
         
        <input
            type="text"
            className="form-control inputfield"
            id="name"
            placeholder="Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
      </div>
      <div className="form-group col-md-4 name-group">
         <img src="images/phone-icon.png" className="iconleft" alt=""/>
        <div className="palceholder"  >
       
          <label  >Mobile </label>
          <span className="star">*</span>
        </div>
       
         <input
            type="text"
            id="mobile"
            className="form-control inputfield"
            placeholder="Mobile"
            value={mobile}
            minLength="10" maxLength="10"
            required
            onChange={(e) => setMobile(e.target.value)}
          ></input>
      </div>
      
      <div className="form-group col-md-4 email-group">
      <img src="images/email-icon.png" className="iconleft emails" alt=""/>
        <div className="palceholder" >
        
          <label  >Email </label>
          <span className="star">*</span>
          
        </div>
          <input
            type="email"
            id="email"
            className="form-control inputfield"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
      </div>
      <div className="form-group col-md-4">
       
      <select  id="area"
          placeholder="Select your Preferred Model"
          className="form-control"
          required
          value={selectModel}
          onChange={(e) => setSelectModel(e.target.value)}
        >
          <option value="">Select your Preferred Model</option>
           
              <option value='RV400' key='RV400'>
              RV400
              </option>
             
        </select>
</div>
 
<div className="form-group col-md-4">
      
      <select id="state"
      className="form-control"
          placeholder="Select State"
          required
          value={selectedState}
          
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option>Choose State</option>
          {stateList.map((value, key) => {
            return (
              <option value={value.state_id} key={key}>
                {value.state_name}
              </option>
            );
          })}
        </select>
</div>
<div className="form-group col-md-4">
      
      <select   id="city"
      className="form-control"
          placeholder="City"
          value={selectedCity}
          
           
          onChange={(e) => setSelectedCity(e.target.value)}
              
          required
        >
          <option>Choose City</option>
          {availableCity.map((e, key) => {
            return (
              <option value={e.city_id} key={key}
              >
                {e.city_name}
              </option>
            );
          })}
        </select>
</div>
<div className="form-group col-md-4">
       
      <select  id="area"
          placeholder="Dealer Hub"
          className="form-control"
          required
          value={selectedHub}
          onChange={(e) => setSelectedHub(e.target.value)}
        >
          <option value="">Dealer Hub </option>
          {availableHub.map((e, key) => {
            return (
              <option value={e.hub_id} key={key}>
                {e.hub_name}
              </option>
            );
          })}
        </select>
</div>
{/*  */}
<div className="form-group col-md-4 email-group">
      
        <div className="palceholder" >
        
          <label  >Test Ride Date </label>
          <span className="star">*</span>
          
        </div>
          <input
            type="date"
            id="testdate"
            // min={disableDates()}
            min={disablePastDate()}
            // min={"04-06-2022"}
            className="form-control inputfield"
            placeholder="Test Ride Date"
            required
            
            // onChange={(e) => setSelectTestDate(e.target.value)}
            onChange={(e) => checkTimeSlots(e.target.value)}
          ></input>
      </div>
<div className="form-group col-md-4">
       
      <select  id="timeslot"
          placeholder="Select Time Slot"
          className="form-control"
          required
          value={selecttimeSlot}
          
          onChange={(e) => setSelectTimeSlot(e.target.value)}
        >
          <option value="">Select Time Slot</option> 

          
          {TestRideSolts.map((e, key) => {
            return (
              <option value={e.slot} key={key}>
                {e.slot}
              </option>
            );
          })}
          
        </select>
</div>
  
<div className="col-md-12 whatsaapform ">
 

  <p>Get <b> <img src="images/whtsapp_icon.jpg"/>whatsApp</b> reminders & updates on everything that's relevant</p>
</div>

<div className="form-group col-md-4 checkboxihave">
<label><input type="checkbox"   /> Yes, opt me in</label>
</div>
 
</div>
</div>
           <div className="testride_submit_wrapper">
            {/*  */}
{/* otp field start */}
{ !otpbtn ? 
   <>
  <div className="form-group col-md-4 name-group">
         {/* <img src="images/phone-icon.png" className="iconleft" alt=""/> */}
        <div className="palceholder"  >
       
          <label  >OTP </label>
          <span className="star">*</span>
        </div>
       
         <input
            type="text"
            id="otp"
            className="form-control inputfield"
            placeholder="OTP"
           
            onChange={(e) => setOtp(e.target.value)}
            
            required
            
          ></input>
           <span id="message"></span>
      </div>

      </>
  : ''
  }

   {/* otp field end */}



            {/*  */}
 <div className="otp_btn_wrapper">
{ otpbtn ? 
                          <input
                            type="button"
                            className="next action-button   "
                            value="Send OTP"
                            
                            disabled={ name=="" || email=="" || selectedState=="" || selectedCity=="" || selectedHub=="" || selecttimeSlot=="" || selectTestDate=="" || selectModel=="" ? true : false}
                            onClick={(e) => checkOTP(e.target)}
                            
                          />     

                          :
                          <div className="group otp_wrapper">
                            <div>
                          <OtpTimer seconds= {30} minutes={0} resend={checkOTP} text="Resend OTP After" />
                         </div></div>
                        }
 
 </div>
 <span style={{display: 'none'}} id="message"></span>
            <input type="submit" 
            disabled={otpbtn}  
            name="next" 
            className="next action-button  " 
            value="Submit"
            // disabled={!mobileverify} 
             /> 
            </div>
            <p>*By clicking “Book Test ride”, I give my consent to Rvolt Motors Pvt. Ltd. and its service providers to hold my personal data and communicate with me by e-mail, WhatsApp, SMS or phone call for test ride purposes.</p>
            
            
            </div> 
        </fieldset>  
            
          </form>  
      {/*  */}
      </div>
	  </section>
	  
	  </div>
    </>
  );
}
