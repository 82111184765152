import React from 'react'
import RevoltMap from '../components/revoltmap';


function AboutusScreen() {
  return (
    <>
     {/* banner */}
     <section className="inner-banner" style={{textAlign: 'right', backgroundColor: '#121d19'}}>
          <img src="images/AboutUs.png" />
        </section>
        {/* banner */}
        <section className="signinnsignup inner-signinnsignup">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumbs">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">AboutUs</li>
              </ol>
            </nav>
          </div>
        </section>
        {/* get your motor */}
        <section className="get-your-motor">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="get-your-motor-text">
                  <h1>Get your motor runnin...Head out on the highway</h1>  
                  <p>Hair flowing with the wind, the smell of rubber burning over the tarmac and the feeling of being a free bird flying past everybody else. Irreplaceable! Biking is a passion - yes, we know! And we’re here to juice up your biking experience by giving you the pleasure of being on a motorbike, yet you playing the role of knight in shining armor in the mortal’s campaign of “Save The Earth!”. Seriously, let’s contribute to the society we’ve got so much from? Well, it all starts with you! </p>
                  <p>Revolt Motors is the next-gen mobility company, created for the smart world. With technology at its roots and a class-apart product in the works, Revolt has introduced India’s first AI-enabled motorcycle without compromising on the performance or aesthetics of a regular ride.</p>
                  <a className="book-now-btn" href="/booking">Book Now</a>   
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="get-your-motor-img">
                  <img src="images/motor-1.jpg" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="motor-box-1">
                  <img src="images/motor-2.png" />
                  <div className="motor-box-text">
                    <h1>Vision</h1>
                    <p>Democratising clean commute using next-gen
                      mobility solutions.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="motor-box-1">
                  <img src="images/motor-3.png" />
                  <div className="motor-box-text">
                    <h1>Mission</h1>
                    <p>To create a future of next-gen mobility with 100% accessibility and 0% fuel residue.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* get your motor */}
        {/* our identity */}
        <section className="our-identity" style={{backgroundImage: 'url("images/2nd-banner.png")'}}>
          <div className="container">
            <div className="row">
              <div className="our-identity-text">
                <h1>OUR IDENTITY</h1>
                <p>In with the new, is something achieved by a few. Merging passion with purpose, Revolt on the road will give city dwellers a revolutionary way to commute and connect, charting the future for generations to come.</p>
                <p>Its identity is founded at the convergence of two directions — the back arrow signifying learnings and values of the past and, the forward arrow, clean energy for the future. Together, like its namesake, Revolt takes the Revolution ahead, going against the grain to herald in a new dimension of the next-gen mobility.</p>
              </div>
            </div>
          </div>     
        </section>
        {/* our identity */}
         {/* map section starts  */}
        
         <RevoltMap></RevoltMap>
        
        {/* map section ends  */}
    
    </>
  )
}

export default AboutusScreen
