import React, { useEffect, useState } from 'react';

// import AOS from 'aos';
// import 'aos/dist/aos.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
// import 'swiper/swiper.min.css'

 
import SwiperCore, {
  Mousewheel,Pagination
} from 'swiper';

SwiperCore.use([Mousewheel,Pagination]);

function SafetyScreen() {
  
    return (
     <>
     <div className="hit-the-road-fullinfo owl_sld_right" id="feature-sec3">
     <p className="text-right   close-htr1">
      <a href="/Mainproduct#mainproduct_Collapse">
        <img src="https://www.revoltmotors.com/assets/images/arrow-Grey.png" className="img-fluid" alt=""/>
        </a>
    </p>
    <p className="rv400-pagination">RV400 &gt; SAFETY FIRST, ALWAYS</p>
    <Swiper
  // spaceBetween={50}
  // slidesPerView={3}
  // onSlideChange={() => console.log('slide change')}
  // onSwiper={(swiper) => console.log(swiper)}
  // pagination={true}
  // observer={true} observeParents={true}

  direction={'vertical'} 
  slidesPerView={1} 
  spaceBetween={30} 
  mousewheel={true} 
  pagination={{
    "clickable": true
  }} 
  className="owl_cstm_style"
>
<div className="swiper-wrapper">

  <SwiperSlide>
  <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 420px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Tire-mob-420.jpg"
            />
            <source
              media="(max-width: 860px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Tire-mob.jpg"
            />
            <img
              src="https://www.revoltmotors.com/assets/images/400-Tire-desk.jpg"
              alt="Tyres"
            />
          </picture>
          <div className="container">
            {/*<h4 class="slide_ttl txtBlck">safety first, always</h4>*/}
            <div
              className="slide_text black_txt left_center slide_tnew rv300tyresec"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>TYRES</h4>
              <p>
                RV400 comes with MRF zapper tyres. A front (90/80-17) and rear
                (120/80-17) tyre will always keep the grip and torque of the
                RV400 glued to the road.
              </p>
            </div>
          </div>
        </div>
  </SwiperSlide>

  
  <SwiperSlide>
  <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Suspension.jpg"
            />
            <img
              src="https://www.revoltmotors.com/assets/images/suspension.jpg"
              alt="Suspension"
            />
          </picture>
          <div className="container">
            <div
              className="slide_text left_center slide_tnew suspension-caption"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>SUSPENSION</h4>
              <p>
                First in its segment Upside Down Forks and Rear Adjustable
                Monoshock Suspension which can be changed manually to suspend
                any discomfort from your riding experience.
              </p>
            </div>
          </div>
        </div>
  </SwiperSlide>
  
  <SwiperSlide>
  <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Side-stand.jpg"
            />
            <img
              src="https://www.revoltmotors.com/assets/images/side-stand.jpg"
              alt="When on stand,it won’t move!"
            />
          </picture>
          <div className="container">
            <div
              className="slide_text left_center slide_tnew"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>The Safety Stand</h4>
              <p>
                Most riders in a hurry forget to put the stand off and run it
                on. The RV400 has a safety side stand which makes the motorcycle
                only engage with its rider once the stand goes back to its
                closed position. As always, safety first.
              </p>
            </div>
          </div>
        </div>
  </SwiperSlide>
  
  <SwiperSlide>
  <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 420px)"
              srcSet="https://www.revoltmotors.com/assets/images/cbs_red-mbl.png"
            />
            <source
              media="(max-width: 767px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Breaking-system.jpg"
            />
            <img
              className="mbl_break_point"
              src="https://www.revoltmotors.com/assets/images/Combination-Braking.jpg"
              alt="Combination Braking System"
            />
          </picture>
          <div className="container">
            <div
              className="slide_text slide_tnew Combination-Braking"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>Combination Braking System</h4>
              <p>
                The new industry defining Combination Braking System (CBS). No
                matter which brake you apply, you’re always in control of your
                safety.
              </p>
            </div>
          </div>
        </div>
  </SwiperSlide>
  
  
  
  
</div>
</Swiper>
{/*  */}
{/* <div className="swiper-container owl_cstm_style">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 420px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Tire-mob-420.jpg"
            />
            <source
              media="(max-width: 860px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Tire-mob.jpg"
            />
            <img
              src="https://www.revoltmotors.com/assets/images/400-Tire-desk.jpg"
              alt="Tyres"
            />
          </picture>
          <div className="container">
            
            <div
              className="slide_text black_txt left_center slide_tnew rv300tyresec"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>TYRES</h4>
              <p>
                RV400 comes with MRF zapper tyres. A front (90/80-17) and rear
                (120/80-17) tyre will always keep the grip and torque of the
                RV400 glued to the road.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Suspension.jpg"
            />
            <img
              src="https://www.revoltmotors.com/assets/images/suspension.jpg"
              alt="Suspension"
            />
          </picture>
          <div className="container">
            <div
              className="slide_text left_center slide_tnew suspension-caption"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>SUSPENSION</h4>
              <p>
                First in its segment Upside Down Forks and Rear Adjustable
                Monoshock Suspension which can be changed manually to suspend
                any discomfort from your riding experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Side-stand.jpg"
            />
            <img
              src="https://www.revoltmotors.com/assets/images/side-stand.jpg"
              alt="When on stand,it won’t move!"
            />
          </picture>
          <div className="container">
            <div
              className="slide_text left_center slide_tnew"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>The Safety Stand</h4>
              <p>
                Most riders in a hurry forget to put the stand off and run it
                on. The RV400 has a safety side stand which makes the motorcycle
                only engage with its rider once the stand goes back to its
                closed position. As always, safety first.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="rightbar_slide">
          <picture>
            <source
              media="(max-width: 420px)"
              srcSet="https://www.revoltmotors.com/assets/images/cbs_red-mbl.png"
            />
            <source
              media="(max-width: 767px)"
              srcSet="https://www.revoltmotors.com/assets/images/400-Breaking-system.jpg"
            />
            <img
              className="mbl_break_point"
              src="https://www.revoltmotors.com/assets/images/Combination-Braking.jpg"
              alt="Combination Braking System"
            />
          </picture>
          <div className="container">
            <div
              className="slide_text slide_tnew Combination-Braking"
              data-animation-in="slideInDown"
              data-animation-out="animate-out slideOutUp"
            >
              <h4>Combination Braking System</h4>
              <p>
                The new industry defining Combination Braking System (CBS). No
                matter which brake you apply, you’re always in control of your
                safety.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="swiper-pagination" />
     
  </div> */}

  {/*  */}
</div>

     </>
  )
}

export default SafetyScreen