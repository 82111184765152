import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios'; 
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { URL_API } from '../../constants/cartConstants';
import { set } from '../../../node_modules/express/lib/application';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';


export default function CancelBookingScreen(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //const [BookingId, SetBookingId] = useState('');
  const [OTP, setOTP] = useState('');
  const [reasontype, setReasontype] = useState('customer');
  const [comment, setComment] = useState('');
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState('')
  const [messagetype, setMessagetype] = useState('')
  const [showotp,setShowotp] = useState(false);
  const [confmessage, setConfmessage] = useState('')
  const [confmessagetype, setConfmessagetype] = useState('')
  const params = useParams();
  const userInfo_info = localStorage.getItem("userInfo");
  const userInfo_myArr = JSON.parse(userInfo_info);
  
  const { id: BookingId } = params;
   
  async function fetchOrders(BookingId) {
    if(BookingId === "")
    {
      setMessagetype("danger");
      setMessage("Please enter the booking id");
    }
    else{  
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/booking_order/${BookingId}`);
    setMessagetype("success");
    setMessage("OTP send.")
    setShowotp(true);
    }
  }

  
  async function fetchOrders_validotp(BookingId,OTP) {
      


     const { data } = await Axios.post(`${URL_API}/api/v1/customer/booking_order_validate`,
        {
          "BookingId":BookingId,
          "OTP":OTP

        }
        
        );

        if(!data){
          setMessagetype("danger");
          setMessage("Something went wrong!");
        }
        else{
          console.log(data);
          setMessagetype("success");
          setMessage("");
          //console.log('bijendra');
          setOrders(data.data.orderItems);
        }
     
   }

   async function Orders_Cancelled(BookingId,reasontype,comment, user_type) {
    // console.log(BookingId);
     const { data } = await Axios.post(`${URL_API}/api/v1/customer/order_cancel`,
     {
       "cancelled_by":"admin",
       "cancelled_userid":userInfo_myArr.id,
      "BookingId":BookingId,
      "reason_type":reasontype,
      "comment":comment,
      "user_type":user_type

     }
     
     );
     if(data){
      console.log(data);
      setMessagetype("success");
      setMessage("Order Cancel Successfully");
      setOrders([]);
          
      navigate(`/thankyoucancel/${data.data.booking_id}`);
    }
    else{
      
      

      setMessagetype("danger");
      setMessage("Something went wrong!");
    
    }
   }

  const dispatch = useDispatch();
  const submitHandler =  (e) => {
    e.preventDefault();
    fetchOrders(BookingId);
    //dispatch(signin(BookingId));
  };

  
  // const submitHandlerotp = (e) => {
  //   e.preventDefault();
  //   if(BookingId === "")
  //   {
  //     setMessagetype("danger");
  //     setMessage("Please enter the booking id");
  //   }
  //   else if(OTP === "")
  //   {
  //     setMessagetype("danger");
  //     setMessage("Please enter the OTP");
  //   }
  //   else{
  //   dispatch(fetchOrders_validotp(BookingId,OTP));
  //   } 
  // };

  
  const submitHandlercancel = (e) => {
    e.preventDefault();

    
    if(comment === "")
    {
      setConfmessagetype("danger");
       
      setConfmessage("Please enter the comment");
    }
     
    else{
      setConfmessagetype("");
      setConfmessage("");
      Orders_Cancelled(BookingId,reasontype,comment, "admin");
    }
    //dispatch(signin(BookingId));
  };


  useEffect(() => {
    
     fetchOrders_validotp(BookingId,"OTP") ;
    
  }, []);

   
  return (
    <>
       <section className="signinnsignup">
        <div className="container">
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumbs">
    <li className="breadcrumb-item"><a href="#">Home</a></li>
    <li className="breadcrumb-item " aria-current="page"><a href="/orderlist">Bookings</a></li>
    <li className="breadcrumb-item active" aria-current="page">Booking</li>
 
  </ol>
</nav>   
         { ( message && 
          <MessageBox variant={messagetype} >{message}</MessageBox>
          )}
          <div className="banner-search-box">
             
            
          </div>
        
      { (orders.length === 0 )  ?
       (
        <MessageBox >
          No data Found!
        </MessageBox>
      ) : 
      (
       <div>
         {/*  */}
     

          {/* detail start */}
          {console.log(orders)}
          { orders.map((item) => (
          <div className="bookingcancelaation booking_deatils">
            <div className="row">
              <div className="col-md-6">
              <img src={`/images/${item.image}`} />
              </div>
              <div className="col-md-6 modelfour redbikedetail">
                <span>Booking ID : {BookingId}</span>
                <h6>Model</h6>
                <h1>{item.item_name}</h1>
                <ul>
                  <li><i className="fa fa-map-marker" aria-hidden="true" /> {item.area_name}/{item.city_name}</li>
                  <li><i className="fa fa-calendar" aria-hidden="true" /> {item.createdAt}</li>
                </ul>
              </div>
              <table className="table table-striped table-bordered tableNSEbooking mt-5">
                <thead>
                  <tr>
                    <th style={{fontWeight: 'normal'}}>Booking Amount </th>
                    <th style={{background: '#fff', textAlign: 'right', fontSize: '25px', color: '#ed1c24'}}>
                    <NumberFormat value={item.booking_amount}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                      {/* {item.booking_amount} */}
                      
                      </th>
                  </tr>
                </thead>
                <tbody>
              {/* <tr>
                    <td style={{background: '#fff'}}>EMI Amount	-</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>-</td>
                  </tr>       */}
                  <tr>
                    <td>Ex Showroom Price (a)</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>
                      {/* {item.itemsPrice} */}
                      <NumberFormat value={item.itemsPrice}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                    
                      </td>
                  </tr>    
                  <tr>
                    <td>Price</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>
                      {/* {item.price} */}
                      <NumberFormat value={item.price}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                    
                      </td>
                  </tr>   
                  <tr>
                    <td>Name</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>{item.customer_name}</td>
                  </tr>  
                  <tr>
                    <td>Email Id</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>{item.email}</td>
                  </tr>  
                  <tr>
                    <td>Mobile</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>{item.mobile}</td>
                  </tr>  

                    {/*   <tr>
                    <td style={{background: '#fff'}}>Fame II Incentive 1 (b)</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>₹ 48,000</td>
                  </tr>      
                  <tr>
                    <td>Cost before on-Road 2 (a-b)</td>
                    <td style={{background: '#fff', textAlign: 'right'}}>₹ 124,9993</td>
                  </tr> 	   */}
                  { (item.order_status == "Cancel") && (
                  <tr>
                    <td style={{background: '#fff'}}>Order Status</td>
                    <td style={{background: '#fff', textAlign: 'right', fontSize: '25px', color: '#ed1c24'}}>Cancelled</td>
                  </tr>
                  )}
                </tbody>
              </table>
            
           { (item.order_status != "Cancel") && (
              <>
              <div className="textareabox">
              { ( confmessage && 
          <MessageBox variant={confmessagetype} >{confmessage}</MessageBox>
          )}
          <select  id="reasontype"
          placeholder="Reason Type"
          className="form-control"
          required
          value={reasontype}
          onChange={(e) => setReasontype(e.target.value)} >
          <option>--Select Reason Type --</option> 
              <option value='Reason 1' >
                Reason 1
              </option>
              <option value='Reason 1' >
                Reason 2
              </option>
          
        </select>

           
                <textarea name="comment" onChange={(e) => setComment(e.target.value)} required placeholder={"Please elaborate the reason for cancellation."} />
     
              </div>
              <div className="text-center mx-auto mt-5">
                <div className="cancel_biooking" onClick={submitHandlercancel}>Cancel Booking</div>
                
                
                </div>
                </>

              )
          } 

              <ul className="mt-5 pl-4 pr-4">
                <li>* The price of motorcycle shall be applicable as prevailing on the date of delivery of motorcycle to customer.</li>
                <li>1. Claimable only once per Aadhar card. T&amp;C apply.</li>
                <li>2. Your booking amount will be adjusted with the On-Road price. Registration and applicable Road Tax will be additional based on actuals.</li>
                <li>3. On-Road Price for Revolt Purchase Plan = “Cost Before On-Road” + Add. cost of Registration/RTO (On Actuals) + Insurance + Smart Card + 4G Connectivity Charges + other statutory applicable charges.</li>
              </ul>
            </div>
          </div>
           )) }
          {/* deatil end */}


       
      {/*  */}
       
                
      
          </div>       
  )
  }
  </div></section>
      
    </>
  );
}
