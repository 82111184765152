import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { signin } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Axios from 'axios';
import {URL_API} from '../constants/cartConstants';
import OtpTimer from 'otp-timer';


export default function SigninScreen(props) {
  const navigate = useNavigate();
  //const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [otpbtn, setOtpbtn] = useState(true);
  const isEnabled =  mobile.length>0  && (password.length>0 || otp.length>0);
  const [loginwithotp, setLoginwithotp] = useState(false);
  
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  let redirect = redirectInUrl ? redirectInUrl : '/';

  //const parsed_productId = new URLSearchParams(search).get('id');  

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(mobile, password, otp));
  };
  useEffect(() => {
    
    if (userInfo) {
      redirect = (userInfo?.name) ? redirect : `/signin?redirect=${redirect}` ;
       
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

   
  function checkOTP(checkbox) {




    let mobile = document.getElementById('mobile');
    let mobile_no =  document.getElementById('mobile').value;
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    if(mobile_no === "" || mobile_no === null || mobile_no.length != 10)  {
      //alert("Please Enter Valid Mobile Number.");
      
      
      // mobile.style.backgroundColor  = badColor;
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid Mobile Number."  ;
    }
      else{
        setOtpbtn(false);
        message.innerHTML = "";
        const result_pay = Axios.get(`${URL_API}/api/v1/customer/otp/${mobile_no}`
        )
      }
    // if(checkbox.checked) {
    // let mobile_no =  document.getElementById('mobile');
    // const result_pay = Axios.get(`${URL_API}/api/v1/customer/otp/${mobile_no.value}`
    // )
    // }

  // if(checkbox.checked){)
  //     document.getElementById('passwordverdict').style.display = "none";
  //     document.getElementById('otpverdict').style.display = "block";
  //     let randomnumber = Math.random().toString().substr(2, 6)
  //     document.getElementById('otpshow').innerHTML = randomnumber;
      
  //   }
  //   else{ 
  //     document.getElementById('passwordverdict').style.display = "block";
  //     document.getElementById('otpverdict').style.display = "none";
  //     document.getElementById('otpshow').innerHTML = "";
  //   }

  };

  const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
  return (
    <div>
      {/*  */}
      <section className="signinnsignup">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pd-0 pr-0 loginimgH">
              <img src="images/signinlogin_img.jpg" alt="" />
            </div>
            <div className="col-md-6 pd-0 pr-0 pl-0">
              <div className="card">
                <div className="login-box">
                  <div className="login-snip">
                    <input
                      // id="tab-1"
                      type="radio"
                      name="tab"
                      className="sign-in"
                      checked
                    />
                    <label 
                    // htmlFor="tab-1"
                     className="tab">
                      Sign In
                    </label>
                    <input
                      // id="tab-2"
                      type="radio"
                      name="tab"
                      className="sign-up"
                    />
                    <label 
                    // htmlFor="tab-2" 
                    className="tab">
                    <Link to={`/register?redirect`} style={{color:"#000"}}>Sign Up</Link>
                    </label>

                    {/*  */}
                    <div className="login-space">
                      <div className="login">
                      <form className="form" onSubmit={submitHandler}>
                         
                        {loading && <LoadingBox></LoadingBox>}
                        {error && <MessageBox variant="danger">{error}</MessageBox>}



                        <div className="form-group name-group">
                       
                          <img
                            src="images/users.png"
                            className="iconleft"
                            alt=""
                          />
                          <div className="palceholder">
                            <label htmlFor="name">Mobile</label>
                            <span className="star">*</span>
                          </div>
                          
                    
                          <input
                           className="form-control inputfield"
                            type="text"
                            id="mobile"
                            placeholder="Enter Mobile/Dealer Code"
                            required
                             minLength={10} maxLength={20}
                            //onChange={(e) => setMobile(e.target.value)}

                            onChange={(e) => {
                              if(e.target.value.length==11) return false;   //limits to 10 digit entry
                              setMobile(e?.target.value);       //saving input to state
                          }
                      }


                          ></input>
                         
                        </div>
                        <span id="message"></span>
                      {/* userfield end */}
                      
                      {/* PASSword field*/}
                    { !loginwithotp && (
                    
                        <div className="form-group name-group" id="passwordverdict">
                          <img
                            src="images/password-icon.png"
                            className="iconleft"
                            alt=""
                          />
                          <div className="palceholder">
                            <label htmlFor="password">Password</label>
                            <span className="star">*</span>
                          </div>
                          <input
                           className="form-control inputfield"
                            type="password"
                            id="password"
                            placeholder="Password"
                            name="password"
                            required
                            minLength="8" maxLength="8"
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loginwithotp}
                          ></input>
                           
                        </div>
                        
                    )  }
                        {/* passwordfield end */}

                        {/*  OTP */}
                        { loginwithotp && (
                            <>
                        <div id="otpverdict" className="form-group name-group">
                        <div className="palceholder">
                            <label htmlFor="pass">Enter OTP</label>
                            <span className="star">*</span>
                          </div>
                          <input className="form-control inputfield" type="number"
                           id="otp" placeholder="Enter OTP"
                           minLength="6" maxLength="6"
                           disabled={!loginwithotp}
                           onChange={(e) => setOtp(e.target.value)}></input>
                        </div>
                        <div className="group otp_wrapper">
                        { otpbtn ? 
                          <input
                            type="button"
                            className="otp_button"
                            value="Send OTP"
                            onClick={(e) => checkOTP(e.target)}
                            disabled={!(mobile.length==10)}
                          />
                          :
                          <OtpTimer seconds= {60} minutes={0} resend={checkOTP} text="Resend OTP After" />
                        }
                        </div>
                        </>
                           ) }

                        <div className="form-group name-group">
                          <label htmlFor="otp" style={{paddingLeft:"10px"}} >Login with OTP</label>
                          <input
                             className="ml-2"
                            type="checkbox"
                            id="otp"
                            
                            
                            onChange={(e) => setLoginwithotp(!loginwithotp)}
                           
                          ></input>
                        </div>

                        <div id="otpverdictvalue"></div>
                        <div id="otpshow"></div>
                        {/* OTP end */}
                         
                        <div className="group">
                          <input
                            type="submit"
                            className="button"
                            value="Login"
                            disabled={!isEnabled}
                          />
                        </div>

                        {/* <div className="foot">
                        <p>New customer?</p>
                         
                          
                          <Link to={`/register?redirect`}>Sign UP</Link>
                        </div> */}
                      
                      {/*  */}
                      
                  </form>
                      {/*  */}
                      </div>   
                    </div>

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      
    </div>
  );
}
