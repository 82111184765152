import React from 'react'
import RevoltMap from '../components/revoltmap';

function UspChargingScreen() {
  return (
    <>
     <section id="mainproduct_evening_banner">
          <div className="charging-boxs">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="rv-videosection">
                  <video id="custvideo1" muted loop autoPlay="true">
                    <source src="https://www.revoltmotors.com/assets/video/Revolt-Video-New.mp4" type="video/mp4" />
                    <source src="https://www.revoltmotors.com/assets/video/Revolt-Video-New.ogg" type="video/ogg" />
                  </video> 
                </div>
              </div>
            </div>
            <div className="container charging-text">
              <div className="col-md-6">
                <h2>THE CHARGING</h2>
                <h1>MULTIVERSE</h1>
              </div>
              <div className="col-md-6" />
            </div>
          </div>
        </section>
        <section style={{background: '#f1f3f6'}}>
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumbs">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Charging Multiverse</li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="tabsystem_usp">
          <div className="container">
            <div className="row">
              <div className="col-md-3 pr-0">
                <ul className="nav nav-tabs left-tabs usp_chagingtab">
                  <li className="nav-item ">
                    <a className="nav-link active" href="#lorem-left" data-toggle="tab">On-board charging</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#ipsum-left" data-toggle="tab">portable charging</a>
                  </li>
                  <li className="nav-item"><a href="#revolt" className="nav-link" data-toggle="tab">Revolt Switch Stations</a>
                  </li>
                  <li className="nav-item last"><a href="#sos" className="nav-link" data-toggle="tab">SOS Delivery</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-9 pl-0">
                <div className="container pl-0">
                  <div className="tab-content">
                    <article className="tab-pane container active" id="lorem-left">
                      <h1>
                        Park It And Charge it!
                      </h1>
                      <section className="ml-4">
                        <p>If you do not have a power source near the RV400, simply remove the battery and charge it in the confines of your home or office.</p>
                        <span>Remove. Carry. Charge.</span>
                      </section>
                      <section>
                        <img src="images/tabingimage1new.jpg" />
                      </section>
                    </article>
                    <article className="tab-pane container" id="ipsum-left">
                      <h1>
                         Charge it like your phone
                      </h1>
                      <section>
                      </section>
                      <section>
                        <p>
                          If you do not have a power source near the RV400, simply remove the battery and charge it in the confines of your home or office
                        <span><br/><br/>Remove. Carry. Charge.</span>
                        </p>
                        
                        <img src="images/charger-battery-new1.JPG" />
                      </section>
                    </article>
                    <article className="tab-pane container" id="revolt">
                      <h1>
                        Grab energy within seconds
                      </h1>
                      <section>
                      </section>
                      <section>
                        <p>
                          If you are on the move and the low battery indicator is on, you can visit the nearest Revolt Switch Station through the MyRevolt app and exchange your drained-out battery for a fresh one in no time.
                          <span><br/><br/>Find. Switch. Zoom off.</span>
                        </p>
                        
                        <img src="images/charging-meter1.png" />
                      </section>
                    </article>
                    <article className="tab-pane container" id="sos">
                      <h1>
                        We always have your back
                      </h1>
                      <section>
                        <p><small>From Wikipedia, the free encyclopedia</small></p>
                      </section>
                      <section>
                        <p>
                          In case, you run out of battery charge and do not have access to any of the above battery charging options leaving you stranded, you can order a fully-charged battery through the MyRevolt app and we will come to the rescue.
                        </p>
                        <span>Grounded. Messaged. Rescued.</span>
                        <p>Delivery time: 90 minutes (may vary depending on traffic conditions)</p>
                        <img src="images/charge-car.png" />
                      </section>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>  
        <section className="arsenalrevolt">
          <div className="container">
            <h3 className="font38 hd_mbl_vw">THE ARSENAL OF<br /> REVOLT'S ENERGY</h3>
            <p>Revolt Motorcycles’ are powered by their sophisticated yet simple batteries which store the energy to move the revolution forward. Time has come to abolish the morning ritual of queuing at petrol pumps.</p>
            <div className="row">
              <div className="col-md-6">
                <img src="images/revoltcharging.jpg" alt="" />
              </div>
              <div className="col-md-6">
                <div className="media touchscree">
                  <img className="iimground mr-3" src="images/touch-safe.jpg" alt="" />
                  <div className="media-body">
                    <h5 className="mt-0">Touch-safe</h5>
                    All batteries are tested for safety and are touch safe. Although, it's durability will shock some people.
                  </div>
                </div>
                <div className="media touchscree">
                  <img className="iimground mr-3" src="images/easily-portable.jpg" alt="" />
                  <div className="media-body">
                    <h5 className="mt-0">Easily portable</h5>
                    A sleek and compatible design makes the battery easily portable to your home or office,
                  </div>
                </div>
                <div className="media touchscree">
                  <img className="iimground mr-3" src="images/waether-proffing.jpg" alt="" />
                  <div className="media-body">
                    <h5 className="mt-0">Weather proofing</h5>
                    Tested in all types of weathers. It's robust, strong and faithful.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
        <section className="arsenalrevolt" style={{background: 'url(./images/inbg_bike.jpg) no-repeat', color: '#fff', padding: '60px 0'}}>
          <div className="container">
            <h2 className="font38 hd_mbl_vw rvinner">RV400</h2>
            <h2 className="font38 hd_mbl_vw">TECHNICAL SPECS</h2>
            <div className="row">
              <div className="col-md-7">
                <table className="table table-bordered mytalestylle new-table-bs">
                  <thead>
                    <tr>
                      <td>Battery Type <span>Lithium-ion</span></td>
                      <td>Portable Charger <span>15Amp</span></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Voltage/Wattage <span>72V, 3.24kWh</span></td>
                      <td>Charging time <span>0-75% in 3 hours and<br />
                          0-100% in 4.5 hours</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-5">
                <table className="table table-bordered mytalestylle">
                  <tbody>
                    <tr>
                      <td>Warranty <span>6 years or 100,000kms
                          whatever comes first for battery warranty 2 years warranty for battery charger</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
         {/* map section starts  */}
        
         <RevoltMap></RevoltMap>
        
        {/* map section ends  */}
        <section className="test-a-ride">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 p-0">
                <a href="/test-ride" target="_blank">
                  <div className="title-n-icon d-flex align-items-center justify-content-center">
                    <span className="bikeride-v1" />
                    <span>Schedule A Test Ride</span>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 p-0">
                <a href="/notifyme" target="_blank">
                  <div className="title-n-icon d-flex align-items-center justify-content-center border-bothside">
                    <span className="bikeride-v4" />
                    <span>Unlock Your City</span>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 border-bothside p-0">
                <a href="https://www.youtube.com/channel/UC2tW9kRUr1mGhS8oA_IQEnQ" target="_blank">
                  <div className="title-n-icon d-flex align-items-center justify-content-center">
                    <span className="bikeride-v2" />
                    <span>Revolt Times </span>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-3 col-12 p-0">
                <a href="/contactus" target="_blank">
                  <div className="title-n-icon d-flex align-items-center justify-content-center">
                    <span className="bikeride-v3" />
                    <span>Contact Us</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default UspChargingScreen