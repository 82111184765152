import Axios from 'axios';
//import Razorpay from "razorpay";
//import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CheckoutSteps from '../components/CheckoutSteps';
import Pdf from "react-to-pdf";


import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
import NumberFormat from 'react-number-format';

 
export default function ThankyouCancelationScreen(props) {
 
 

  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [cancel_data, setCancel_data] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  const { id } = params;
  

  const getData = async (id) => {
    try {
      const { data } = await Axios.get(`${URL_API}/api/v1/customer/order_cancel/${id}`  );
    console.log(data.data.data);
  setCancel_data(data.data.data)
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    getData(id)
    // setTimeout(()=>{
    //   getData(id)
    //  }, 500)
    
     
   
}, []);
 

 

 
  return  (
    <>
         <section className="signinnsignup">
	  <div className="container">
	  <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumbs">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    {/* <li className="breadcrumb-item active" aria-current="page">You Details</li> */}
  </ol>
</nav>
    
	  <div className="thankyoupage">
    <div className="col-12 thankyou_heading">
        <h2 >
        <img src="/images/thankyou.png" alt=""/>   Your Order is Cancelled Successfully
        </h2>
        <div className="pdf_wrapper" >

        <Pdf targetRef={ref} filename="Reciept_Testride.pdf"    y={20} scale={0.7}>
        {({ toPdf }) => <button onClick={toPdf}>Download Reciept <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
</button>}
      </Pdf>
        </div>
         {/* <p>Your Order is cancelled successfully .</p> */}
      </div>
    
	 
	  
	  
    <div className="col-12 mt-5" ref={ref} >
        <table className="table table-striped table-bordered tableNSE">
          {/* <thead>
            <tr>
              <th>Order Id </th>
              <th style={{ background: "#f1f3f6" }}>
              {order.order[0].id}
                </th>
            </tr>
          </thead> */}
          <tbody>
          
          <tr>
              <td style={{ background: "#fff" }}>Name</td>
              <td style={{ background: "#f1f3f6" }}>
                  {cancel_data?.customer_name}
                </td>
            </tr>
            
            <tr>
              <td style={{ background: "#fff" }}>Email Id</td>
              <td style={{ background: "#f1f3f6" }}>
              {cancel_data?.email}
                </td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td style={{ background: "#f1f3f6" }}>
                { cancel_data?.mobile}
                </td>
            </tr>
            
            <tr>
              <td>Booking ID</td>
              <td style={{ background: "#f1f3f6" }}>
                
                 { cancel_data?.booking_id} 
                  
                   
                </td>
            </tr>
            <tr>
              <td>Payment ID</td>
              <td style={{ background: "#f1f3f6" }}>
                
                 { cancel_data?.razorpay_payment_id} 
                  
                   
                </td>
            </tr>
            
            <tr>
              <td> Refund ID</td>
              <td style={{ background: "#f1f3f6" }}> {cancel_data?.refund_id}</td>
            </tr>
            <tr>
              <td> Refund Status</td>
              <td style={{ background: "#f1f3f6",textTransform:"uppercase" }}> {cancel_data?.status_message}</td>
            </tr>
          <tr>
              <td> Order Date</td>
              <td style={{ background: "#f1f3f6" }}> {cancel_data?.createdAt}</td>
            </tr>
            <tr>
              <td> Cancellation Date</td>
              <td style={{ background: "#f1f3f6" }}> {cancel_data?.cancel_date}</td>
            </tr>
             
          </tbody>
        </table>
      </div>
	  </div>
	  </div>

	  
	  </section>

   
    </>
   );
}
