import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import $ from 'jquery';
import { BrowserRouter, Link, Route, Routes ,useLocation } from 'react-router-dom';
import { signout } from './actions/userActions';
import AdminRoute from './components/AdminRoute';
import PrivateRoute from './components/PrivateRoute';
import CartScreen from './screens/CartScreen';
import HomeScreen from './screens/HomeScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import OrderScreen from './screens/OrderScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductScreen from './screens/ProductScreen';
import ProfileScreen from './screens/user/UserProfileEditScreen';
import RegisterScreen from './screens/RegisterScreen';
import BookingScreen from './screens/BookingScreen';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import SigninScreen from './screens/SigninScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/admin/OrderListScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import SellerRoute from './components/SellerRoute';
import SellerScreen from './screens/SellerScreen';
import ThankyouScreen from './screens/ThankyouScreen';
//import SearchBox from './components/SearchBox';
import SearchScreen from './screens/SearchScreen';
import { listProductCategories, listStateCityHub } from './actions/productActions';
import LoadingBox from './components/LoadingBox';
import MessageBox from './components/MessageBox';
import MapScreen from './screens/MapScreen';
import DashboardScreen from './screens/DashboardScreen';
import SupportScreen from './screens/SupportScreen';
import ChatBox from './components/ChatBox';
import Footer from './components/Footer';
import Header from './components/Header';
import ProductsScreen from './screens/ProductsScreen';
import SearchbookScreen from './screens/SearchbookScreen';
import TestRideScreen from './screens/TestRideScreen';
import NotifyMeScreen from './screens/NotifyScreen';
import ThankyouScreenTestride from './screens/ThankyouScreenTestride';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactUs from './screens/ContactUsScreen';
import Mainproduct from './screens/Mainproduct';
import UspChargingScreen from './screens/UspChargingScreen';
import AboutusScreen from './screens/AboutusScreen';
import ThankyouScreenNotify from './screens/ThankyouScreenNotify';
import ThankyouCancelationScreen from './screens/ThankyouCancelationScreen';
import ThankyouContactusScreen from './screens/ThankyouContactusScreen';
import OrderDetailScreen from './screens/admin/OrderDetailScreen';
import TestrideListScreen from './screens/admin/TestrideListScreen';
import TestrideDetailScreen from './screens/admin/TestrideDetailScreen';
import NotifyListScreen from './screens/admin/NotifyListScreen';
import NotifyDetailScreen from './screens/admin/NotifyDetailScreen';
import ContactUsListScreen from './screens/admin/ContactUsListScreen';
import ContactUsDetailScreen from './screens/admin/ContactUsDetailScreen';
import TermsScreen from './screens/TermsScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import CancelBookingScreen from './screens/admin/CancelBookingScreen';
import UserDashboardScreen from './screens/user/UserDashboardScreen';
import UserBookingScreen from './screens/user/UserBookingScreen';
import UserNotifyScreen from './screens/user/UserNotifyScreen';
import UserTestrideScreen from './screens/user/UserTestrideScreen';
import UserContactusScreen from './screens/user/UserContactusScreen';
import UserProfileEditScreen from './screens/user/UserProfileEditScreen';
import ComfortScreen from './screens/ComfortScreen';
import AestheticScreen from './screens/AestheticScreen';
import SafetyScreen from './screens/SafetyScreen';
// import ReactFancyBox from 'react-fancybox';
// import 'react-fancybox/lib/fancybox.css';


require('dotenv').config()


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const cart = useSelector((state) => state.cart);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const { cartItems } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };

  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList;
  useEffect(() => {
  //  dispatch(listProductCategories());
    dispatch(listStateCityHub());
    
  }, [dispatch]);
  
  //console.log(categories);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });
  

  return (
    <BrowserRouter>
     <ScrollToTop />
      
      <main>
    
    <div className="main-sec" > 
        <Header/>
       
        {/* <div style={{background: "#f1f3f6",height: '300px'}}></div> */}
        <main 
        // style={{background: "#f1f3f6",minHeight: '300px'}}
        >
     
          <Routes>
            {/* <Route path="/dealer/:id" element={<SellerScreen />}></Route>
            <Route path="/cart" element={<CartScreen />}></Route>
            <Route path="/cart/:id" element={<CartScreen />}></Route>*/}
            <Route
              path="/product/:id"
              element={<ProductScreen />}
              exact
            ></Route>
            <Route
              path="/product/:id/edit"
              element={ProductEditScreen}
              exact
            ></Route>
            <Route path="/terms" element={<TermsScreen />}></Route>
            
            <Route path="/privacy" element={<PrivacyScreen />}></Route>
            <Route path="/aboutus" element={<AboutusScreen />}></Route>
            <Route path="/usp" element={<UspChargingScreen />}></Route>
            <Route path="/comfort" element={<ComfortScreen />}></Route>
            <Route path="/aesthetic" element={<AestheticScreen />}></Route>
            <Route path="/safety" element={<SafetyScreen />}></Route>
            <Route path="/contactus" element={<ContactUs />}></Route>
            <Route path="/mainproduct" element={<Mainproduct />}></Route>
            <Route path="/signin" element={<SigninScreen />}></Route>
            <Route path="/booking" element={<BookingScreen />}></Route>
            <Route path="/products" element={<ProductsScreen />}></Route>
            <Route path="/register" element={<RegisterScreen />}></Route>
            <Route path="/shipping" element={<ShippingAddressScreen />}></Route>
            <Route path="/payment" element={<PaymentMethodScreen />}></Route>
            <Route path="/placeorder" element={<PlaceOrderScreen />}></Route>
            <Route path="/order/:id" element={<OrderScreen />}></Route>
            <Route path="/thankyou/:id" element={<ThankyouScreen />}></Route>
            <Route path="/thankyoucancel/:id" element={<ThankyouCancelationScreen />}></Route>
            <Route path="/thankyouride/:id" element={<ThankyouScreenTestride />}></Route>
            <Route path="/thankyounotify/:id" element={<ThankyouScreenNotify />}></Route>
            <Route path="/thankyoucontact/:id" element={<ThankyouContactusScreen />}></Route>
            <Route path="/searchbooking/" element={<SearchbookScreen />}></Route>
            <Route path="/searchbooking/:id" element={<SearchbookScreen />}></Route>
            
            <Route path="/test-ride" element={<TestRideScreen />}></Route>
            <Route path="/notifyme" element={<NotifyMeScreen />}></Route>
            <Route
              path="/orderhistory"
              element={<OrderHistoryScreen />}
            ></Route>
            
            <Route
              path="/userdashboard"
              element={<UserDashboardScreen />}
            ></Route>
            <Route
              path="/usercontactus"
              element={<UserContactusScreen />}
            ></Route>
            <Route
              path="/userbooking"
              element={<UserBookingScreen />}
            ></Route>
            <Route
              path="/usernotify"
              element={<UserNotifyScreen />}
            ></Route>
            <Route
              path="/usertestride"
              element={<UserTestrideScreen />}
            ></Route>

            {/*<Route path="/search/name" element={<SearchScreen />} exact></Route>
            <Route
              path="/search/name/:name"
              element={<SearchScreen />}
              exact
            ></Route>
            <Route
              path="/search/category/:category"
              element={<SearchScreen />}
              exact
            ></Route>
            <Route
              path="/search/category/:category/name/:name"
              element={<SearchScreen />}
              exact
            ></Route>
            <Route
              path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/order/:order/pageNumber/:pageNumber"
              element={<SearchScreen />}
              exact
            ></Route>*/}

            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <UserProfileEditScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/map"
              element={
                <PrivateRoute>
                  <MapScreen />
                </PrivateRoute>
              }
            />

            <Route
              path="/productlist"
              element={
                <AdminRoute>
                  <ProductListScreen />
                </AdminRoute>
              }
            />

            <Route
              path="/productlist/pageNumber/:pageNumber"
              element={
                <AdminRoute>
                  <ProductListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/orderlist"
              element={
                <AdminRoute>
                  <OrderListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/orderdetail/:id"
              element={
                <AdminRoute>
                  <OrderDetailScreen />
                </AdminRoute>
              }
            />
             <Route
              path="/testridelist"
              element={
                <AdminRoute>
                  <TestrideListScreen />
                </AdminRoute>
              }
            />
             <Route
              path="/testridedetail/:id"
              element={
                <AdminRoute>
                  <TestrideDetailScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/notifylist"
              element={
                <AdminRoute>
                  <NotifyListScreen />
                </AdminRoute>
              }
            />
             <Route
              path="/notifydetail/:id"
              element={
                <AdminRoute>
                  <NotifyDetailScreen />
                </AdminRoute>
              }
            />
              <Route
              path="/requestlist"
              element={
                <AdminRoute>
                  <ContactUsListScreen />
                </AdminRoute>
              }
            />
             <Route
              path="/contactusdetail/:id"
              element={
                <AdminRoute>
                  <ContactUsDetailScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/userlist"
              element={
                <AdminRoute>
                  <UserListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/user/:id/edit"
              element={
                <AdminRoute>
                  <UserEditScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <AdminRoute>
                  <DashboardScreen />
                </AdminRoute>
              }
            />
             <Route
              path="/cancelbooking/:id"
              element={
                <AdminRoute>
                  <CancelBookingScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/support"
              element={
                <AdminRoute>
                  <SupportScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/productlist/dealer"
              element={
                <SellerRoute>
                  <ProductListScreen />
                </SellerRoute>
              }
            />
            <Route
              path="/orderlist/dealer"
              element={
                <SellerRoute>
                  <OrderListScreen />
                </SellerRoute>
              }
            />

            <Route path="/" element={<HomeScreen />} exact></Route>
          </Routes>
           
        </main>
        
        <Footer/>
</div></main>

      
    </BrowserRouter>
  );
}

export default App;
