import Axios from 'axios';
//import Razorpay from "razorpay";
//import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CheckoutSteps from '../components/CheckoutSteps';
import Pdf from "react-to-pdf";


import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
import NumberFormat from 'react-number-format';
export default function ThankyouScreen(props) {

  // 
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;
  // 

  const ref = React.createRef();
  const cartItem_arr=  localStorage.getItem("cartItems");
  const cartitem_myArr = JSON.parse(cartItem_arr);

  const navigate = useNavigate();
  const params = useParams();
  const { id: orderId } = params;
  const [amount, setAmount] = useState('');
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, setLoading, error } = orderDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  const dispatch = useDispatch();
  useEffect(() => {
    
    dispatch(detailsOrder(orderId));
    
   
}, []);

const slip_state = stateList.find((c) => c.state_id == order?.shippingAddress[0].state)?.state_name;
const slip_city =  cityList.find((c) => c.city_id == order?.shippingAddress[0].city)?.city_name;
 
const slip_country = order?.shippingAddress[0].country;

  // console.log(order);
  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <>
       {/* new design start */}
       <section className="signinnsignup detailsflow">
    <div className ="container">
    <div className="row justify-content-center">  
      <div className="col-12 col-sm-9 col-md-7 col-lg-11 col-xl-12 text-center p-0    ">  
     
       
        
          <div className="card px-0   pb-0    " id="msform">
                 
              {/* <form id="msform" className="form" onSubmit={submitHandler}>  */}
                  {/* <ul id="progressbar">  
                      <li className="active"  id="account"><strong>Your Details </strong></li>  
                      <li  className="active" id="personal"><strong> Choose Model & MRP</strong></li>  
                      <li className="active" id="payment"><strong> Booking Payment </strong></li>  
                      <li id="confirm"><strong> Start My Revolt Plan </strong></li>  
                  </ul>   */}

{/* 3rd tab start */}
<fieldset>
  <div className="form-card thankyoupage">
    <div className="row">
      <div className="col-12 thankyou_heading">
        <h2 >
        <img src="/images/thankyou.png" alt=""/>   THANK YOU
        </h2>
        <div className="pdf_wrapper" >

        <Pdf targetRef={ref} filename="Reciept.pdf"  y={20} scale={0.68}>
        {({ toPdf }) => <button onClick={toPdf}>Download Reciept <i className="fa fa-file-pdf-o" aria-hidden="true" ></i>
</button>}
      </Pdf>
        </div>
         
      </div>
      <div className="col-12" ref={ref}>
        <table className="table table-striped table-bordered tableNSE">
          {/* <thead>
            <tr>
              <th>Order Id </th>
              <th style={{ background: "#f1f3f6" }}>
              {order.order[0].id}
                </th>
            </tr>
          </thead> */}
          <tbody>
          <tr>
              <td style={{ background: "#fff" }}> Booking Id</td>
              <td style={{ background: "#f1f3f6" }}> {order.booking_ref_id}</td>
            </tr>
          <tr>
              <td style={{ background: "#fff" }}>Name</td>
              <td style={{ background: "#f1f3f6" }}>
                  {order.shippingAddress[0].name}
                </td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Email Id</td>
              <td style={{ background: "#f1f3f6" }}>
              {order.shippingAddress[0].email}
                </td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Mobile</td>
              <td style={{ background: "#f1f3f6" }}>
                {order.shippingAddress[0].mobile}
                </td>
            </tr>
            
            <tr>
              <td style={{ background: "#fff" }}>Address </td>
              <td style={{ background: "#f1f3f6" }}>
                 
                 {/* {order.shippingAddress[0].state}, {' '}
                 {order.shippingAddress[0].city},{' '}
                 {order.shippingAddress[0].country} */}

                 {slip_state}, {' '}
                 {slip_city}, {' '}
                 {slip_country}
                </td>
            </tr>

            <tr>
              <td style={{ background: "#fff" }}>Bike Model | Color</td>
              <td style={{ background: "#f1f3f6" }}> 
              {order.orderItems[0].name} | {order.order[0].bike_color} 
              </td>
            </tr>
          
            <tr>
              <td style={{ background: "#fff" }}>Show Room Price</td>
              <td style={{ background: "#f1f3f6" }}>
               
              <NumberFormat value={order.order[0].itemsPrice}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                {/* <NumberFormat value={cartitem_myArr.subscription[0].booking_amount}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  /> */}
                
                </td>
            </tr>

            <tr>
              <td style={{ background: "#fff" }}>Booking Amount</td>
              <td style={{ background: "#f1f3f6" }}>
              
              <NumberFormat value={order.order[0].booking_amount}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
              
                {/* <NumberFormat value={cartitem_myArr.subscription[0].booking_amount}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  /> */}
                
                </td>
            </tr>

            {/* <tr>
              <td style={{ background: "#fff" }}>Effective cost before on Road</td>
              <td style={{ background: "#f1f3f6" }}>
              &#8377;{order.order[0].itemsPrice}
                //  <NumberFormat value={cartitem_myArr.subscription[0].booking_amount}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />  
                
                </td>
            </tr> */}
            


            <tr>
              <td style={{ background: "#fff" }}> Payment Method</td>
              <td style={{ background: "#f1f3f6" }}> 
                  
              {order.paymentMethod='Razor'}
              
              
              </td>
            </tr>
            <tr>
              <td> Payment Status</td>
              <td style={{ background: "#f1f3f6" }}> 
          
              {order.order[0].isPaid===1 ? (
                <>
                  {/* // <MessageBox variant="success"> */}
                    Booking Amount Paid Successfully  
                    at {order.order[0].createdAt}<br />
                    Thanks for Booking @Revolt Team
                  {/* // </MessageBox> */}
                  </>
                ) : (
                  <>
                  {/* // <MessageBox variant="danger"> */}
                    Not Paid
                    {/* </MessageBox> */}
                    </>
                )}
              
              
              </td>
            </tr>

           

            {/* <tr>
              <td>  Order Items</td>
              <td style={{ background: "#f1f3f6" }}> 
                  
              <ul>
                  {order.orderItems.map((item) => (
                    <li key={item.product}>
                      <div className="row">
                      <div className="col-12 px-4">
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </div>

                          <div className="col-12 px-4">
                          {item.qty} x &#8377;{item.price} = &#8377;{item.qty * item.price}
                        </div>  
                        <div className="col-12 px-4">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="small"
                          ></img>
                        </div>
                        
                      </div>
                    </li>
                  ))}
                </ul>
              
              </td>
            </tr> */}
            {/* <tr>
              <td> Delivery Status</td>
              <td style={{ background: "#f1f3f6" }}> 
                  
               
               {order.order[0].isDelivered===1 ? (
                  <MessageBox variant="success">
                    Delivered at 
                    //   {order.order[0].deliveredAt}  
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">Not Delivered</MessageBox>
                )}
                   
              
              
              </td>
            </tr> */}
                

           

          </tbody>
        </table>
      </div>
    </div>
     
     
    {/*<input type="button" name="pre" className="pre action-button-pre" value="Pre" /> */}
  </div>
</fieldset>

{/* 3rd tab end  */}
                               {/* </form>   */}
          </div>  
      </div>  
  </div>  
</div>

</section>
 
{/* new design end */}

   
    </>
  );
}
