import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios'; 
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { booking } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import  { userBookingReducer } from '../reducers/userReducers';
import { URL_API } from '../constants/cartConstants';
import OtpTimer from 'otp-timer';
import CascadingDropdown from '../components/state_city_hub';
const data = {
  countries: [
      
    {
      name: "Delhi", Key:1,
      states: [
        { 
          name: "G.T. Karnal Road", 
          key:2, 
          cities: [
            {
              name:"Revolt Hub G.T. Karnal", 
              discount:"16200"
            }
          ] 
        },
        { name: "Dwarka Sec-5", key:4, cities: [{name:"Revolt Hub Dwarka", discount:"16200"}] },
         
      ]
    },
     
    {
      name: "Pune", Key:2,
      states: [
        { name: "ICC Tech Park",key:6, cities: [{name:"Revolt Hub ICC Tech Park", discount:20000}] },
        { name: "Kalyani Nagar",key:7, cities: [{name:"Revolt Hub Kalyani", discount:20000}] }
         
      ]
    },
     
    {
    name: "Ahmedabad", Key:3,
    states: [
      { name: "Naroda",key:12, cities: [{name:"Revolt Hub Naroda", discount:20000}] },
      { name: "Ashram Road",key:13, cities: [{name:"Revolt Hub Ashram", discount:20000}] }
        
    ]
  },
  
  {
      name: "Hyderabad", Key:4,
      states: [
        { name: "Jubilee Hills",key:8, cities: [{name:"Revolt Hub Jubilee", discount:20000}] },
        
          
      ]
    },
  
    {
      name: "Chennai", Key:5,
      states: [
          { name: "Velachery ",key:10, cities: [{name:"Revolt Hub Velachery", discount:20000}] },
          { name: "Anna nagar",key:11, cities: [{name:"Revolt Hub Anna", discount:20000}] }
          
      ]
      },

      {
          name: "Mumbai", Key:6,
          states: [
          { name: "Andheri(West)",key:15, cities: [{name:"Revolt Hub Andheri", discount:20000}] },
          
              
          ]
      },

      {
          name: "Bengaluru", Key:7,
          states: [
          { name: "Kormangala",key:79, cities: [{name:"Revolt Hub Kormangala", discount:20000}] },
          
              
          ]
      } ,

      {
          name: "Jaipur", Key:8,
          states: [
          { name: "Jaipur",key:59, cities: [{name:"Revolt Hub Jaipur", discount:20000}] },
          
              
          ]
      },
      {
          name: "Surat", Key:8,
          states: [
          { name: "Surat",key:81, cities: [{name:"Revolt Hub Surat", discount:20000}] },
          
              
          ]
      }   
    
  ]
};

export default function NotifyMeScreen(props) {
 
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  const stateList = stateList_myArr.state;
  const cityList = stateList_myArr.city;
  const hubList = stateList_myArr.hub;
 

  const userInfo_info = localStorage.getItem("userInfo");
  const userInfo_myArr = JSON.parse(userInfo_info);

  const navigate = useNavigate();
  const [name, setName] = useState(userInfo_myArr?.name ? userInfo_myArr.name : '');
  const [mobile, setMobile] = useState(userInfo_myArr?.mobile ? userInfo_myArr.mobile :'');
  const [email, setEmail] = useState(userInfo_myArr?.email ? userInfo_myArr.email :''); 
 const [selectModel, setSelectModel] = React.useState('');
  const [selectTestDate, setSelectTestDate] = useState('');
  const [selecttimeSlot, setSelectTimeSlot] = useState('');

  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const [selectedHub, setSelectedHub] = React.useState('');

  const [notify, setNotify] = useState([]);

  const [otp, setOtp] = useState('');
  const [otpbtn, setOtpbtn] = useState(true);

  const [statelist, setStatelist] =  useState([]);
  let availableState=[];
   
  // let availableCity =[];
  const availableCity = cityList.filter((c) => c.state_id == selectedState) ;
  //const availableHub = hubList.filter((c) => c.city_id == selectedCity) ;


  async function submitnotify(name,mobile, email,model,state, city) {
    console.log(name)
     const { data } = await Axios.post(`${URL_API}/api/v1/customer/notifyme`, 
     {name,mobile, email,model,state, city});
     console.log('data');
     setNotify(data);

     var message = document.getElementById('message');
     var goodColor = "#0C6";
     var badColor = "#ed1c24";

      if(data?.status==false){

        message.style.color = badColor;
        message.innerHTML = data.message  ;
        
      }
      else
      { 
        navigate(`/thankyounotify/${data.NotifyMe_id}`);
        }
   }


   async function checkOTP(checkbox) {

 
    let mobile_no =  document.getElementById('mobile').value;
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    if(mobile_no === "" || mobile_no === null || mobile_no.length != 10)  {
     
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid Mobile Number."  ;
    }
      else{
        const result_pay = await Axios.get(`${URL_API}/api/v1/auth/sendotp/${mobile}`)
        setOtpbtn(false);
        message.innerHTML = "";
        
      }
   

  };

   async function getstatelist() {
    //console.log(name)
     const { data } = await Axios.get(`${URL_API}/api/v1/common/getstatelist`);
     console.log('data-state');
     setStatelist(data.data);
     
      
   }




  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    var message = document.getElementById('message');
    var goodColor = "#0C6";
    var badColor = "#ed1c24";
    //verfit otp 

    try {
      const { data } = await Axios.post(`${URL_API}/api/v1/auth/verifyotp`, { mobile, otp });
      
      
       
    if(data.status === true)
    {
      message.innerHTML = "";
      dispatch(submitnotify(name, mobile, email,selectModel, selectedState,selectedCity));
    
    }
    else
    {
     
    
      message.style.color = badColor;
      message.innerHTML = "Please Enter Valid Mobile Number."  ;
      
    }

    } catch (error) {
       
    }

    // otp verify end 
      
    
  };
  useEffect(() => {
    getstatelist();
    if(notify) {
      console.log(notify);
      //alert("submit successfully!");
    }
  }, [notify]);

 
  
   
  return (
    <>
          <section className="unlockcity">
	 

	  
   </section>
   
   <div className="unlock_bg">
   
   <section>
   <div className="container">
   <nav aria-label="breadcrumb">
 <ol className="breadcrumb breadcrumbs">
   <li className="breadcrumb-item"><a href="#">Home</a></li>
   <li className="breadcrumb-item active" aria-current="page">Unlock you CIty</li>
 </ol>
</nav>
</div>
   </section>
   
       <section className="unlockcity_inner">
   <div className="container"> 
   
                 {/*  */}
              <form id="msform" className="form" onSubmit={submitHandler}> 
                   
                    
                  <fieldset>  
                      <div className="form-card">  
                          <div className="row">  
                              <div className="col-12">  
                              <h2 className="fs-title text-center"><b>Hey! Great to see you here. Register and unlock your City.</b></h2>  
        <p>Want us to bring Revolt to your city? Show us some love! Fill in the details below and nominate your city. You never know the next city could be yours.</p>  
                              </div>  
                                
                          </div> 
                      <div className="login-space">	
                      <div className="row">	
                          <div className="form-group col-md-4 name-group">
      <img src="images/users.png" className="iconleft" alt=""/>
        <div className="palceholder">
        
          <label htmlFor="name">Name</label>
           <span className="star">*</span>
        
        </div>
       
         
        <input
            type="text"
            className="form-control inputfield"
            id="name"
            placeholder="Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
      </div>
      <div className="form-group col-md-4 name-group">
         <img src="images/phone-icon.png" className="iconleft" alt=""/>
        <div className="palceholder"  >
       
          <label htmlFor="Phone">Mobile </label>
          <span className="star">*</span>
        </div>
       
         <input
            type="text"
            id="mobile"
            className="form-control inputfield"
            placeholder="Mobile"
            value={mobile}
            minLength="10" maxLength="10"
            required
            onChange={(e) => setMobile(e.target.value)}
          ></input>
      </div>
      
      <div className="form-group col-md-4 email-group">
      <img src="images/email-icon.png" className="iconleft emails" alt=""/>
        <div className="palceholder" >
        
          <label htmlFor="email">Email </label>
          <span className="star">*</span>
          
        </div>
          <input
            type="email"
            id="email"
            className="form-control inputfield"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
      </div>
      <div className="form-group col-md-4">
       
      <select  id="area"
          placeholder="Dealer Hub"
          className="form-control"
          required
          value={selectModel}
          onChange={(e) => setSelectModel(e.target.value)}
        >
          <option>Select your Preferred Model</option>
           
              <option value='RV400' key='RV400'>
              RV400
              </option>
               
             
        </select>
</div>

      <div className="form-group col-md-4">
      
      <select id="state"
      className="form-control"
          placeholder="Select State"
          required
          value={selectedState}
          
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option>Select State</option>
          {stateList.map((value, key) => {
            return (
              <option value={value.state_id} key={key}>
                {value.state_name}
              </option>
            );
          })}
        </select>

       
</div>

<div className="form-group col-md-4">
      
      <select   id="city"
      className="form-control"
          placeholder="City"
          value={selectedCity}
          
           
          onChange={(e) => setSelectedCity(e.target.value)}
              
          required
        >
          <option>Choose City</option>
         {
        (availableCity?.length != 0 && availableCity?.length != null) ?
          (availableCity.map((e, key) => {
            return (
              <option value={e.city_id} key={key}
              >
                {e.city_name}
              </option>
            );
          })

          )
          :''
          }
          
           
        </select>
</div>
 
<div className="col-md-12 whatsaapform mt-3">
<p>Get <b> <img src="images/whtsapp_icon.jpg"/>whatsApp</b> reminders & updates on everything that's relevant</p>
</div>

<div className="form-group col-md-4 checkboxihave">
<label><input type="checkbox"  /> Yes, opt me in</label>
</div>
 
</div>
</div>
{/*  */}
<div className="testride_submit_wrapper">
           {/*  */}
{/* otp field start */}
{ !otpbtn ? 
   <>
  <div className="form-group col-md-4 name-group">
         {/* <img src="images/phone-icon.png" className="iconleft" alt=""/> */}
        <div className="palceholder"  >
       
          <label  >OTP </label>
          <span className="star">*</span>
        </div>
       
         <input
            type="text"
            id="otp"
            className="form-control inputfield"
            placeholder="OTP"
           
            onChange={(e) => setOtp(e.target.value)}
            
            required
            
          ></input>
           <span id="message"></span>
      </div>

      </>
  : ''
  }

   {/* otp field end */}



            {/*  */}
            <span id="message"></span>
 <div className="otp_btn_wrapper">
{ otpbtn ? 
                          <input
                            type="button"
                            className="next action-button mb-5 "
                            value="Send OTP"
                            
                            disabled={!(mobile.length==10)}
                            onClick={(e) => checkOTP(e.target)}
                            
                          />     

                          :
                          <div className="group otp_wrapper">
                            <div>
                          <OtpTimer seconds= {5} minutes={0} resend={checkOTP} text="Resend OTP After" />
                         </div></div>
                        }
 
 </div>
{/*  */}
<input type="submit" 
     disabled={otpbtn}  
name="next" className="next action-button" value="Submit" /> 
            <p>*By clicking “Book Test ride”, I give my consent to Rvolt Motors Pvt. Ltd. and its service providers to hold my personal data and communicate with me by e-mail, WhatsApp, SMS or phone call for test ride purposes.</p>
            </div>      
            </div> 
            </fieldset>  
            
          </form>  
          {/*  */}
          </div>
	  </section>
	  
	  </div>
    </>
  );
}
