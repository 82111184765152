import React, { useEffect } from 'react';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createOrder } from '../actions/orderActions';
import CheckoutSteps from '../components/CheckoutSteps';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';

import { PRODUCT_DETAILS_RESET } from '../constants/productConstants';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import {
  ROOT_PATH
} from '../constants/cartConstants';
import NumberFormat from 'react-number-format';

export default function PlaceOrderScreen(props) {

   // 
   const statecityhub = localStorage.getItem("state_city_hub");
   const stateList_myArr = JSON.parse(statecityhub);
   const stateList = stateList_myArr.state;
   const cityList = stateList_myArr.city;
   const hubList = stateList_myArr.hub;
   // 

   
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  if (!cart.paymentMethod) {
    navigate('/payment');
  }
    const orderCreate = useSelector((state) => state.orderCreate);
    const { loading, success, error, order } = orderCreate;
    const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12
  //  cart.itemsPrice = toPrice(
   // cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0) );
     
  const bookingInfo_info = localStorage.getItem("bookinginfo");
  const bookingInfo_myArr = JSON.parse(bookingInfo_info);

  const userlogin_info = localStorage.getItem("userInfo");
   
  //console.log(userInfo_myArr)
  const shippingAddress = localStorage.getItem("shippingAddress");
  const shippingAddress_myArr = JSON.parse(shippingAddress);

   
  const cartItem_arr=  localStorage.getItem("cartItems");
  const cartitem_myArr = JSON.parse(cartItem_arr);

  //const cartitem_order =cartitem_myArr.product;
  //const subscription_myArr =cartitem_myArr.subscription;
  //const delivery_myarr =cartitem_myArr.delivery_batch;
   
  cart.shippingPrice = cart.itemsPrice > 100 ? toPrice(0) : toPrice(10);
  cart.taxPrice = toPrice(0.15 * cart.itemsPrice);
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;
  const dispatch = useDispatch();
  const placeOrderHandler = () => {
   // const order_array = userInfo_info.concat(cartitem_myArr);
   //dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
   window.productvalue =0;
   
   dispatch(createOrder({bookingInfo_info, orderItems: cartitem_myArr,dealerInfo:userlogin_info }));
  };
  
  useEffect(() => {
    if (success) {
      
             
      navigate(`/order/${order.id}`);
       
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: PRODUCT_DETAILS_RESET });
    }
  }, [dispatch, order, navigate, success]);

  const slip_state = stateList.find((c) => c.state_id == shippingAddress_myArr.state)?.state_name;
  const slip_city =  cityList.find((c) => c.city_id == shippingAddress_myArr.city)?.city_name;
  const slip_hub =  hubList.find((c) => c.hub_id == shippingAddress_myArr.area)?.hub_name;
   
  const slip_country = shippingAddress_myArr.country;
  

  return (
   
    <div>
      <CheckoutSteps step1 step2 step3></CheckoutSteps>
      {cartitem_myArr.length === 0 || cartitem_myArr === undefined ? (
          <MessageBox>
            <Link to="/">Go Product</Link>
          </MessageBox>
        ) : (
          <div> 
            {/*  */}
              {/* new design start */}
            <section className="signinnsignup detailsflow">
    <div className ="container">
    <div className="row justify-content-center">  
      <div className="col-12 col-sm-9 col-md-7 col-lg-11 col-xl-12 text-center p-0    ">  
          <div className="card px-0   pb-0  " id="msform">
                 
              {/* <form id="msform" className="form" onSubmit={submitHandler}>  */}
                  <ul id="progressbar">  
                      <li className="active"  id="account"><strong>Your Details </strong></li>  
                      <li  className="active" id="personal"><strong> Choose Model & MRP</strong></li>  
                      <li className="active" id="payment"><strong> Booking Payment </strong></li>  
                      <li id="confirm"><strong> Start My Revolt Plan </strong></li>  
                  </ul>  

{/* 3rd tab start */}
<fieldset>
  <div className="form-card">
    <div className="row">
      <div className="col-12">
        <p className="fs-title stepnumbering">
          You're one step closer to owning the future. Please pay your booking
          amount to reserve
          <br />
          your chosen RV400. Click and select your preferred payment method.
        </p>
      </div>
      <div className="col-12">
        <table className="table table-striped table-bordered tableNSE">
          <thead>
            <tr>
              <th>Name </th>
              <th style={{ background: "#f1f3f6" }}>{shippingAddress_myArr.name}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ background: "#fff" }}>Email Id</td>
              <td style={{ background: "#f1f3f6" }}>{shippingAddress_myArr.email}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td style={{ background: "#f1f3f6" }}>{shippingAddress_myArr.mobile}</td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Bike Model | Color</td>
              <td style={{ background: "#f1f3f6" }}> {cartitem_myArr.product[0].model_family_name} | {cartitem_myArr.product[0].color} </td>
            </tr>
            <tr>
              <td>Plan</td>
              <td style={{ background: "#f1f3f6" }}>Revolt Payment Plan</td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Booking Amount</td>
              <td style={{ background: "#f1f3f6" }}>
                {/* &#8377; {cartitem_myArr.subscription[0].booking_amount} */}
                <NumberFormat value={cartitem_myArr.subscription[0].booking_amount}  displayType={'text'}  thousandSeparator={true} thousandsGroupStyle="lakh" prefix="&#8377;"  />
                </td>
            </tr>
            <tr>
              <td>Revolt Hub</td>
              <td style={{ background: "#f1f3f6" }}> 
                  {/* {shippingAddress_myArr.address} 
                  {shippingAddress_myArr.city}  
                  {shippingAddress_myArr.postalCode} */}
                   
              {slip_hub}
              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  <div className="text-center pb-5">
    <button
   
   type="button"
   className="small_button   mr-5"
   value="Back"
     // onClick={(e) => checkOTP(e.target)}
     onClick={() =>  navigate(`/product/RM0400RRCP1CWBLK`)}
     >
        
   Back
        </button>
    <input
      type="button"
      name="next"
      className="next action-button paymentOption"
      defaultValue="Proceed to Payment"
      onClick={placeOrderHandler}
      disabled={cartitem_myArr.product.length === 0}
    />
     </div>
    {/*<input type="button" name="pre" className="pre action-button-pre" value="Pre" /> */}
  </div>
</fieldset>

{/* 3rd tab end  */}
                               {/* </form>   */}
          </div>  
      </div>  
  </div>  
</div>

</section>
{loading && <LoadingBox></LoadingBox>}
{error && <MessageBox variant="danger">{error}</MessageBox>}
{/* new design end */}
             {/*  */}
          



    </div>

  )}  
</div>
 /*<button
 type="button"
 className="primary block"
 onClick={loadRazorpay()}>
 Payment
</button>*/
);

}
