export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ADMIN_ORDER_DETAILS_REQUEST = 'ADMIN_ORDER_DETAILS_REQUEST';
export const ADMIN_ORDER_DETAILS_SUCCESS = 'ADMIN_ORDER_DETAILS_SUCCESS';
export const ADMIN_ORDER_DETAILS_FAIL = 'ADMIN_ORDER_DETAILS_FAIL';

export const ORDER_MINE_LIST_REQUEST = 'ORDER_MINE_LIST_REQUEST';
export const ORDER_MINE_LIST_SUCCESS = 'ORDER_MINE_LIST_SUCCESS';
export const ORDER_MINE_LIST_FAIL = 'ORDER_MINE_LIST_FAIL';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const NOTIFY_LIST_REQUEST = 'NOTIFY_LIST_REQUEST';
export const NOTIFY_LIST_SUCCESS = 'NOTIFY_LIST_SUCCESS';
export const NOTIFY_LIST_FAIL = 'NOTIFY_LIST_FAIL';

export const TESTRIDE_LIST_REQUEST = 'TESTRIDE_LIST_REQUEST';
export const TESTRIDE_LIST_SUCCESS = 'TESTRIDE_LIST_SUCCESS';
export const TESTRIDE_LIST_FAIL = 'TESTRIDE_LIST_FAIL';

export const ADMIN_TESTRIDE_DETAILS_REQUEST = 'ADMIN_TESTRIDE_DETAILS_REQUEST';
export const ADMIN_TESTRIDE_DETAILS_SUCCESS = 'ADMIN_TESTRIDE_DETAILS_SUCCESS';
export const ADMIN_TESTRIDE_DETAILS_FAIL = 'ADMIN_TESTRIDE_DETAILS_FAIL';

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET';

export const ORDER_SUMMARY_REQUEST = 'ORDER_SUMMARY_REQUEST';
export const ORDER_SUMMARY_SUCCESS = 'ORDER_SUMMARY_SUCCESS';
export const ORDER_SUMMARY_FAIL = 'ORDER_SUMMARY_FAIL';
