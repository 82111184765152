import Axios from 'axios';
import { CART_EMPTY } from '../constants/cartConstants';
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ADMIN_ORDER_DETAILS_FAIL,
  ADMIN_ORDER_DETAILS_REQUEST,
  ADMIN_ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_PAY_REQUEST,
  ORDER_PAY_FAIL,
  ORDER_PAY_SUCCESS,
  ORDER_MINE_LIST_REQUEST,
  ORDER_MINE_LIST_FAIL,
  ORDER_MINE_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL, 
  NOTIFY_LIST_REQUEST,
  NOTIFY_LIST_SUCCESS,
  NOTIFY_LIST_FAIL,
  TESTRIDE_LIST_REQUEST,
  TESTRIDE_LIST_SUCCESS,
  TESTRIDE_LIST_FAIL,
  ADMIN_TESTRIDE_DETAILS_FAIL,
  ADMIN_TESTRIDE_DETAILS_REQUEST,
  ADMIN_TESTRIDE_DETAILS_SUCCESS,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_SUMMARY_REQUEST,
  ORDER_SUMMARY_SUCCESS,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
export const createOrder = (order) => async (dispatch, getState) => {
  dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
  try {
    console.log(order); 
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.post(`${URL_API}/api/v1/customer/orders`, order, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
   
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.data });
    dispatch({ type: CART_EMPTY });
  
    localStorage.setItem('order_details', JSON.stringify(data));
    // localStorage.removeItem('cartItems');
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const adminOrderDetails = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_ORDER_DETAILS_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/adminorders/${orderId}`, {
      headers: { Authorization: `${userInfo.token}` },
    });
   // console.log(data.data);
    dispatch({ type: ADMIN_ORDER_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_ORDER_DETAILS_FAIL, payload: message });
  }
};

export const adminTestrideDetail = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_TESTRIDE_DETAILS_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/admintestride/${orderId}`, {
      headers: { Authorization: `${userInfo.token}` },
    });
   // console.log(data.data);
    dispatch({ type: ADMIN_TESTRIDE_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_TESTRIDE_DETAILS_FAIL, payload: message });
  }
};

export const detailsOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/orders/${orderId}`, {
      headers: { Authorization: `${userInfo.token}` },
    });
   // console.log(data.data);
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DETAILS_FAIL, payload: message });
  }
};

export const payOrder = (order, paymentResult) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ORDER_PAY_REQUEST, payload: { order, paymentResult } });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = Axios.put(`${URL_API}/api/v1/customer/orders/${order.id}/pay`, paymentResult, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_PAY_FAIL, payload: message });
  }
};

export const listOrderMine = (customerId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_MINE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/orders/mine/${customerId}` 
    , {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    console.log(data.data);
    dispatch({ type: ORDER_MINE_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_MINE_LIST_FAIL, payload: message });
  }
};
export const listOrders = (user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub,user_type ) => async (dispatch, getState) => {
  dispatch({ type: ORDER_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(`${URL_API}/api/v1/customer/orderlist`,
    { user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub,user_type}
    , {
      headers: { Authorization: userInfo.token },
    }
    );
    console.log(data);
    dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_LIST_FAIL, payload: message });
  }
};

export const listNotify = (user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub ) => async (dispatch, getState) => {
  dispatch({ type: NOTIFY_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(`${URL_API}/api/v1/customer/notifylist`,
    { user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub}
    , {
      headers: { Authorization: userInfo.token },
    }
    );
    
    dispatch({ type: NOTIFY_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: NOTIFY_LIST_FAIL, payload: message });
  }
};

export const listTestrides = (user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub ) => async (dispatch, getState) => {
  dispatch({ type: TESTRIDE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(`${URL_API}/api/v1/customer/testridelist`,
    { user_id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub}
    , {
      headers: { Authorization: userInfo.token },
    }
    );
    
    dispatch({ type: TESTRIDE_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: TESTRIDE_LIST_FAIL, payload: message });
  }
};

export const deleteOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DELETE_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = Axios.delete(`${URL_API}/api/v1/customer/orders/${orderId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DELETE_FAIL, payload: message });
  }
};

export const deliverOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DELIVER_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = Axios.put(
      `${URL_API}/api/v1/customer/orders/${orderId}/deliver`,
      {},
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: ORDER_DELIVER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DELIVER_FAIL, payload: message });
  }
};

export const summaryOrder = () => async (dispatch, getState) => {
  dispatch({ type: ORDER_SUMMARY_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/orders/summary`, {
      headers: { Authorization: userInfo.token },
    });
    dispatch({ type: ORDER_SUMMARY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
