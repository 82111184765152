import Axios from 'axios';
//import Razorpay from "razorpay";
//import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CheckoutSteps from '../components/CheckoutSteps';
import Pdf from "react-to-pdf";


import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
  URL_API,ROOT_PATH
} from '../constants/cartConstants';
import NumberFormat from 'react-number-format';

 
export default function ThankyouScreenTestride(props) {
 
 
  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [testride_data, setTestride_data] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  const { id } = params;
  

  const getData = async (id) => {
    try {
      const { data } = await Axios.get(`${URL_API}/api/v1/customer/testride/${id}`  );
    
  setTestride_data(data.data.testride[0])
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData(id)
   
}, []);
 

 

 
  return  (
    <>
         <section className="signinnsignup">
	  <div className="container">
	  <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumbs">
    <li className="breadcrumb-item"><a href="#">Home</a></li>
    <li className="breadcrumb-item active" aria-current="page"></li>
  </ol>
</nav>
    {console.log(testride_data)}
	  <div className="thankyoupage">
    <div className="col-12 thankyou_heading">
        <h2 >
        <img src="/images/thankyou.png" alt=""/>   THANK YOU
        </h2>
        <p >Hey, thanks for registering for your RV400 test ride. Please visit the selected Revolt Hub at your scheduled date and time to ensure a great experience.</p>
   
        <div className="pdf_wrapper" >

        <Pdf targetRef={ref} filename="Reciept_Testride.pdf"    y={20} scale={0.7}>
        {({ toPdf }) => <button onClick={toPdf}>Download Reciept <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
</button>}
      </Pdf>
        </div>
            </div>
    
	 
	  
	  
    <div className="col-12  " ref={ref} >
        <table className="table table-striped table-bordered tableNSE">
          {/* <thead>
            <tr>
              <th>Order Id </th>
              <th style={{ background: "#f1f3f6" }}>
              {order.order[0].id}
                </th>
            </tr>
          </thead> */}
          <tbody>
          
          <tr>
              <td style={{ background: "#fff" }}>Name</td>
              <td style={{ background: "#f1f3f6" }}>
                  {testride_data?.name}
                </td>
            </tr>
            
            <tr>
              <td style={{ background: "#fff" }}>Email Id</td>
              <td style={{ background: "#f1f3f6" }}>
              {testride_data?.email}
                </td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Mobile</td>
              <td style={{ background: "#f1f3f6" }}>
                { testride_data?.mobile}
                </td>
            </tr>
            
            
            <tr>
              <td style={{ background: "#fff" }}> Model</td>
              <td style={{ background: "#f1f3f6" }}> {testride_data?.bike_model}</td>
            </tr>
          <tr>
              <td style={{ background: "#fff" }}> Date</td>
              <td style={{ background: "#f1f3f6" }}> {testride_data?.ride_date}</td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Slot</td>
              <td style={{ background: "#f1f3f6" }}>
                  {testride_data?.slot}
                </td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>State</td>
              <td style={{ background: "#f1f3f6" }}>
                
                 { testride_data?.state_name} 
                  
                   
                </td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>City</td>
              <td style={{ background: "#f1f3f6" }}>
                
                 { testride_data?.city_name} 
                  
                   
                </td>
            </tr>
            <tr>
              <td style={{ background: "#fff" }}>Revolt Hub</td>
              <td style={{ background: "#f1f3f6" }}>
                  {testride_data?.hub_name}
                </td>
            </tr>
             
          </tbody>
        </table>
      </div>
	  </div>
	  </div>

	  
	  </section>

   
    </>
   );
}
