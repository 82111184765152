import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { ROOT_PATH } from '../constants/cartConstants';
import ChatBox from './ChatBox';

function Footer() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <footer className="footersec">
    <div className="container">
    <div className="row">
    <div className="col-md-2 col-sm-12 col-xs-12">
    <div className="footer_box">
    <img src={`${ROOT_PATH}/images/bikefooter.png`}/>
    <h2>Motorcycles</h2>
    <ul>
    <li><a href="/Mainproduct" >RV400</a></li>
     
    </ul>
    </div>
    
    </div>
    
    
    <div className="col-md-3 col-sm-12 col-xs-12">
    <div className="footer_box">
    <img src={`${ROOT_PATH}/images/F_Revolt_Icon.png`} />  
    <h2>Revolt</h2>
    <ul>
    <li><a href="/aboutus">Our story</a></li>
    {/* <li><a href="#">Careers</a></li>
    <li><a href="#">Media</a></li> */}
    </ul>
    </div>
    
    </div>
    
    <div className="col-md-3 col-sm-12 col-xs-12">
    <div className="footer_box ">
    <img src="/images/Legal.png"/>  
    <h2>Legal</h2>
    <ul>
    <li><a href="/terms">Terms & Conditions</a></li>
    <li><a href="/privacy">Privacy policy</a></li>
    <li><a href="/searchbooking">Cancel My Revolt</a></li>
    </ul>
    </div>
    
    </div>
    
    
    <div className="col-md-4 col-sm-12 col-xs-12">
    <div className="footer_box stay-connected"> 
    <div className="revolt-booknowtext footernotify">
    {/* <a href="/notifyme"  >Notify Me </a> */}
    <Link to="/notifyme">Notify Me </Link></div>
    <div className="social d-flex justify-content-start align-items-start"><h3>Follow us</h3>
                    <div><a href="https://www.facebook.com/revoltmotorsin/" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></div>
                    <div><a href="https://twitter.com/RevoltMotorsIN" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></div>
                    <div><a href="https://www.linkedin.com/company/revolt-intellicorp/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></div>
                    <div><a href="https://www.instagram.com/revoltmotorsin/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></div>
                    <div><a href="https://www.youtube.com/channel/UC2tW9kRUr1mGhS8oA_IQEnQ" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></div>
                </div>
    </div>
    
    </div>
    
    
    
    </div>
    <div className="text-center mt-3">
    <div style={{fontSize: "20px",color:"#fff"}}>Download the App Now 
    <a href="https://apps.apple.com/us/app/myrevolt/id1477594974">
    <img className="ml-3" src={`${ROOT_PATH}/images/app-store-icon.png`}/> 
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.myrevolt.app">
    <img src="/images/google-play-img.png"/>
    </a>
    </div>
    </div>
    </div>
    
    <div className="footerbottom">
    <div className="container text-center">
    {/* {userInfo && !userInfo.isAdmin && <ChatBox userInfo={userInfo} />} */}
      <p>All information is subject to specific conditions | Copyright © 2022 Revolt Motors. All right reserved.</p></div>
    </div>
    </footer>	

        
  )
}

export default Footer