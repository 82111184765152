import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteOrder, listNotify } from '../../actions/orderActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { ORDER_DELETE_RESET } from '../../constants/orderConstants';

export default function NotifyListScreen(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const sellerMode = pathname.indexOf('/dealer') >= 0;
  const notifyList = useSelector((state) => state.notifyList);
  const { loading, error, notifys } = notifyList;
  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete;
  const [selectfromdate, setSelectFromDate] = useState('');
  const [selecttodate, setSelectToDate] = useState('');
  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const [selectedHub, setSelectedHub] = React.useState('');
  
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  useEffect(() => {
   // console.log(userInfo);
    // dispatch({ type: ORDER_DELETE_RESET });
     
    dispatch(listNotify( userInfo.id,selectfromdate,selecttodate,selectedState,selectedCity,selectedHub ));
   //
  }, [dispatch, sellerMode, successDelete, userInfo.id]);
  // const deleteHandler = (order) => {
  //   if (window.confirm('Are you sure to delete?')) {
  //     dispatch(deleteOrder(order.order_id));
  //   }
  // };
  console.log(notifys);
  return (
    <div>
      <h1>Notify</h1>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>DATE</th>
              <th>CITY</th>
              <th>STATE</th>
              {/* <th>PAID</th>
              <th>DELIVERED</th> */}
              {/* <th>ACTIONS</th> */}
            </tr>
          </thead>
          <tbody>
            {notifys?.data?.notify?.map((notify) => (
              <tr key={notify?.id}>
                <td><a href={`/notifydetail/${notify?.id}`}>{notify?.id}</a></td>
                <td>{notify?.name}</td>
                <td>{notify?.created_on?.substring(0, 10)}</td>
                {/* <td>{notify.ride_date}</td> */}
                {/* <td>{notify.slot}</td> */}
                <td>{notify?.city_name}</td>
                <td>{notify?.state_name}</td>
                {/* <td>
                  {notify.isDelivered
                    ? notify.deliveredAt.substring(0, 10)
                    : 'No'}
                </td> */}
                {/* <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      navigate(`/thankyou/${notify.notify_id}`);
                    }}
                  >
                    Details
                  </button>
                  <button
                    type="button"
                    className="small"
                    onClick={() => deleteHandler(notify)}
                  >
                    Cancel notify
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
