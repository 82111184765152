import Axios from 'axios';
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_BOOKING_FAIL,
  USER_BOOKING_REQUEST,
  USER_BOOKING_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_TOPSELLERS_LIST_REQUEST,
  USER_TOPSELLERS_LIST_SUCCESS,
  USER_TOPSELLERS_LIST_FAIL,
  USER_STATE_LIST_REQUEST,
  USER_STATE_LIST_SUCCESS,
  USER_STATE_LIST_FAIL
} from '../constants/userConstants';
import {
  URL_API  
} from '../constants/cartConstants';

export const booking = (name, mobile, email, state,city,area,token,isDealer) => async (dispatch, getState) => {
  dispatch({ type: USER_BOOKING_REQUEST, payload: { name, mobile, email,  state,city,area,token,isDealer } });
  try {

    const {
      userSignin: { userInfo },
    } = getState();

    const { data } = await Axios.post(`${URL_API}/api/v1/customer/booking`, {
      name,
      mobile,
      email,
      state,
      city,
      area,
      token,
      isDealer
       
    });
   console.log(data);
    if(data.status == 'duplicate')
    {
      
      // localStorage.setItem('bookInfo', JSON.stringify(data))
      dispatch({
        type: USER_BOOKING_FAIL,
        payload:"User Already Registered"
      });
    }
    else{

    dispatch({ type: USER_BOOKING_SUCCESS, payload: data });
   
    if(userInfo?.isSeller){
      localStorage.setItem('bookinginfo', JSON.stringify(data));
      localStorage.setItem('shippingAddress', JSON.stringify(data)); 
    }
    else if(userInfo?.isAdmin){
      localStorage.setItem('bookinginfo', JSON.stringify(data));
      localStorage.setItem('shippingAddress', JSON.stringify(data)); 
    }
    else {
      localStorage.setItem('bookinginfo', JSON.stringify(data));
      localStorage.setItem('userInfo', JSON.stringify(data));
      localStorage.setItem('shippingAddress', JSON.stringify(data));  
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    }
  }
     
  } catch (error) {
    dispatch({
      type: USER_BOOKING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const register = (name, mobile, email, state,city,area, password) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { name, mobile, email, state,area, password } });
  try {
    const { data } = await Axios.post(`${URL_API}/api/v1/customer/register`, {
      name,
      mobile,
      email,
      state,
      city,
      area,
      password
    });
    console.log(data);
    if(data.status == 'duplicate')
    {
     
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:"User Already Registered"
      });
     
    }
    else{
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    //console.log(data);
    localStorage.setItem('userInfo', JSON.stringify(data));
    localStorage.setItem('shippingAddress', JSON.stringify(data)); 
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    }
     
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signin = (mobile, password, otp) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { mobile, password } });
  try {
    const { data } = await Axios.post(`${URL_API}/api/v1/auth/signin`, { mobile, password, otp });
     
    
    //console.log(data);
   if(data.token!=null && data.token!="")
   {
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      localStorage.setItem('shippingAddress', JSON.stringify(data));
   }
   else
   {
     
      dispatch({
        type: USER_SIGNIN_FAIL,
        payload:'Authentiction Error' 
      });
   }

  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('shippingAddress');
  localStorage.removeItem('shippingAdd');
  localStorage.removeItem('order_details');
  localStorage.removeItem('cartItems');
  dispatch({ type: USER_SIGNOUT });
  document.location.href = '/signin';
};
export const bookingout = () => (dispatch) => {
   
  dispatch({ bookingInfo: {} });
 
};
export const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/customer/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};
export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(`${URL_API}/api/v1/customer/profile`, user, {
      headers: { Authorization: userInfo.token },
    });
    if(data.status==true){
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};
export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`${URL_API}/api/v1/users/${user._id}`, user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_FAIL, payload: message });
  }
};
export const listUsers = () => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${URL_API}/api/v1/users`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: message });
  }
};
export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(`${URL_API}/api/v1/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: message });
  }
};

export const listTopSellers = () => async (dispatch) => {
  dispatch({ type: USER_TOPSELLERS_LIST_REQUEST });
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/auth/top_sellers`);
    //Axios.get('/api/v1/users/top-sellers');
    //console.log(data.data);
    dispatch({ type: USER_TOPSELLERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_TOPSELLERS_LIST_FAIL, payload: message });
  }
};

export const getstatelist = () => async (dispatch) => {
  dispatch({ type: USER_STATE_LIST_REQUEST });
  try {
    const { data } = await Axios.get(`${URL_API}/api/v1/common/getstatelist`);
     
    console.log(data.data);
    dispatch({ type: USER_STATE_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_STATE_LIST_FAIL, payload: message });
  }
};

export const getcitylist =  (state_id) => async (dispatch) => {
  dispatch({ type: USER_TOPSELLERS_LIST_REQUEST });
  try {
    const { data } = await Axios.post('/api/v1/common/getcitylist');
     
    console.log(data.data);
    dispatch({ type: USER_TOPSELLERS_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_TOPSELLERS_LIST_FAIL, payload: message });
  }
};

