import React, { useEffect, useRef, useState } from 'react';
import {
  LoadScript,
  GoogleMap,
  StandaloneSearchBox,
  Marker,
} from '@react-google-maps/api';
import LoadingBox from '../components/LoadingBox';
import Axios from 'axios';
import { USER_ADDRESS_MAP_CONFIRM } from '../constants/userConstants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const libs = ['places'];
const defaultLocation = { lat: 30.7055, lng: 76.8013 };
 
export default function MapRevolt(props) {
  const navigate = useNavigate();
  const [googleApiKey, setGoogleApiKey] = useState('');
  const [center, setCenter] = useState(defaultLocation);
  const [location, setLocation] = useState(center);
  
  const [selectedCity,setSelectedCity] =  useState(''); 
  const [selectedHub, setSelectedHub] = React.useState('');

  const mapRef = useRef(null);
  const placeRef = useRef(null);
  const markerRef = useRef(null);

  // 
  const statecityhub = localStorage.getItem("state_city_hub");
  const stateList_myArr = JSON.parse(statecityhub);
  //const stateList = stateList_myArr.state;
  const cityList = stateList_myArr?.city;
  const hubList = stateList_myArr?.hub;
    
  const availableHub = hubList?.filter((c) => c.city_id == selectedCity) ;
//console.log(availableHub);

  useEffect(() => {
    const fetch = async () => {
      // const { data } = await Axios('/api/auth/v1/google');
      // setGoogleApiKey(data);
      setGoogleApiKey("AIzaSyCS9oUxujBFNIm9qTfkCFz4KjZQ0j7ouo4");
      //getUserCurrentLocation(availableHub);
    };
    fetch();
  }, []);

  const onLoad = (map) => {
    mapRef.current = map;
  };

  const onMarkerLoad = (marker) => {
    markerRef.current = marker;
  };
  const onLoadPlaces = (place) => {
    placeRef.current = place;
  };
  const onIdle = () => {
    setLocation({
      lat: mapRef.current.center.lat(),
      lng: mapRef.current.center.lng(),
    });
  };
  const onPlacesChanged = () => {
    const place = placeRef.current.getPlaces()[0].geometry.location;
    setCenter({ lat: place.lat(), lng: place.lng() });
    setLocation({ lat: place.lat(), lng: place.lng() });
  };
  const dispatch = useDispatch();
  const onConfirm = () => {
    const places = placeRef.current.getPlaces();
    if (places && places.length === 1) {
      // dispatch select action
      dispatch({
        type: USER_ADDRESS_MAP_CONFIRM,
        payload: {
          lat: location.lat,
          lng: location.lng,
          address: places[0].formatted_address,
          name: places[0].name,
          vicinity: places[0].city,
          googleAddressId: places[0].id,
        },
      });
      alert('location selected successfully.');
     // navigate('/shipping');
    } else {
      alert('Please enter your address');
    }
  };

  const getUserCurrentLocation = () => {
    if (!navigator.geolocation) {
      alert('Geolocation os not supported by this browser');
    } else {
        
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const markers = [
    {
      id: 1,
      name: "Chicago, Illinois",
      position: { lat: 28.6387759, lng: 77.1203312 }
    },
    {
      id: 2,
      name: "Denver, Colorado",
      position: { lat: 28.5269146, lng: 77.2782732 }
    },
    {
      id: 3,
      name: "Los Angeles, California",
      position: { lat: 28.5269146, lng: 77.2782732 }
    },
    {
      id: 4,
      name: "New York, New York",
      position: { lat: 24.5437577, lng: 73.71530829999999 }
    }
  ];

  return (
       
    <section className="experiencenear">
          <div className="container">
            <h3 className="font38 hd_mbl_vw">EXPERIENCE THE MOTORCYCLE AT REVOLT HUB NEAR YOU</h3>
            <div className="row">
              <div className="col-md-3 col-sm-12 col-xs-12 mt-4 pl-0 pr-0"> 
                    <p className="fs-title stepnumbering">
                        <select   id="city"
                        className="form-control"
                            placeholder="City"
                            value={selectedCity}
                            name="city"
                            
                            onChange={(e) => setSelectedCity(e.target.value)}
                                
                            required
                            >
                            <option>--Choose City--</option>
                            {cityList?.map((e, key) => {
                                return (
                                <option value={e.city_id} key={key}
                                >
                                    {e.city_name}
                                </option>
                                );
                            })}
                            </select>
 
                    </p>
                

                <div className="delhilocations">
                  {/* <div className="locationwise topposition">
                    <h2>  </h2>
                    <div className="media">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      <div className="media-body">
                        <h5 className="mt-0"></h5>
                      </div>
                    </div>
                    <div className="media">
                      <i className="fa fa-phone" aria-hidden="true" />
                      <div className="media-body">
                        <h5 className="mt-0"> </h5>
                      </div>
                    </div>
                    <div className="media">
                      <i className="fa fa-clock-o" aria-hidden="true" />
                      <div className="media-body">
                        <h5 className="mt-0">  </h5>
                      </div>
                    </div>
                  </div> */}
                </div>


              </div>
              <div className="col-md-9 col-sm-12 col-xs-12 mt-3"> 
           
            {  googleApiKey ? (
    
              
                <LoadScript libraries={libs} googleMapsApiKey={googleApiKey}>
                <GoogleMap
                id="smaple-map"
                mapContainerStyle={{ height: '400px', width: '5 0%' }}
                center={center}
                zoom={15}
                onLoad={onLoad}
                onIdle={onIdle}
                >
                <StandaloneSearchBox
                    onLoad={onLoadPlaces}
                    onPlacesChanged={onPlacesChanged}
                >
                    <div className="map-input-box">
                    <input type="text" placeholder="Enter your address"></input>
                    <button type="button" className="primary" onClick={onConfirm}>
                        Confirm
                    </button>
                    </div>
                </StandaloneSearchBox>
                {markers.map(({ id, name, position }) => (
                      <Marker
                        key={id}
                        position={position}
                        onLoad={onMarkerLoad}
                        >
                        
                        </Marker>
                    ))}
                </GoogleMap>
            </LoadScript>
          

            ) : (
            <LoadingBox></LoadingBox>
            )}
                
                </div>
            </div>
            <div className="row">
              <div className="delhilocations alignfooteraddress">

              {availableHub?.map((e, key) => {
                 return (

                <div className="locationwise" >
                  <h2> {e.hub_name}</h2>
                  <div className="media">
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    <div className="media-body">
                      <h5 className="mt-0">{e.dealer_address}</h5>
                    </div>
                  </div>
                  <div className="media">
                    <i className="fa fa-phone" aria-hidden="true" />
                    <div className="media-body">
                      <h5 className="mt-0">{e.dealer_number}</h5>
                    </div>
                  </div>
                  <div className="media">
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    <div className="media-body">
                      <h5 className="mt-0">{e.office_time}</h5>
                    </div>
                  </div>
                </div>
 
            );
          })}
 
              </div>
            </div>
          </div>
        </section>
 
  )
}
