import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes,useParams,useNavigate } from 'react-router-dom';
import { listProductCategories } from '../../actions/productActions';
import { signout } from '../../actions/userActions';
 
// import LoadingBox from '../../components/LoadingBox';
// import MessageBox from '.../../components/MessageBox';
import Axios from 'axios';
import {
  URL_API,ROOT_PATH
} from '../../constants/cartConstants';

function UserTestrideScreen() {

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
};
  const [testride_list, setTestride_list] =  useState([]);
  const navigate = useNavigate();
  const params = useParams();
 
  // const { id } = params;
  const deleteHandler = async(testride_id) => {

    
    if (window.confirm('Are you sure to cancel your ride?')) {
      
      try {
        const { data } = await Axios.delete(`${URL_API}/api/v1/customer/deletetestride/${testride_id}`  );
        console.log(data);
         
    
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        
      }
    }
  };

  const getData = async () => {
    try {
      const { data } = await Axios.post(`${URL_API}/api/v1/customer/testridelist/${userInfo.mobile}`  );
      console.log(data);
      setTestride_list(data.data)
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
    }
  };
  
  
  useEffect(() => {
    
    
     getData()
   
}, []);
 

 

  return (
    <>
    <section className="signinnsignup">
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-12 col-xs-12">
        <div className="dashboard">
          <a href="/userdashboard">
            <div className="media">
              <img className="mr-3" src="images/sadboard-icon.png" alt="" />
              <div className="media-body">
                <h5 className="mt-0"> Hello</h5>
                <p>{userInfo.name}</p>
              </div>
            </div>
          </a>
          <div className="bookingride">
            <ul>
              <a href="/userbooking">
                <li>
                  <img src="images/bookingimg.png" /> <span>Bookings</span>
                </li>
              </a>
              <a href="/usertestride">
                <li className="active">
                  <img src="images/testrideimg.png" /> <span>Test Rides</span>
                </li>
              </a>
              <a href="/usercontactus">
                <li>
                  <img src="images/requestimg.png" /> <span>Request</span>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-9 col-sm-12 col-xs-12">
        <div className="requestid">
          <div className="row">

          {testride_list?.map((testride) => (

            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="requestride tablerideT">
                <table>
                  <tbody>
                    <tr>
                      <td>Modal</td>
                      <td className="fwtd"> : {testride.bike_model}</td>
                    </tr>
                    <tr>
                      <td>City/State</td>
                      <td className="fwtd"> : {testride.city_name} / {testride.state_name}</td>
                    </tr>
                    <tr>
                      <td>Revolt Hub</td>
                      <td className="fwtd"> : {testride.hub_name}</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td className="fwtd"> : {testride.ride_date}</td>
                    </tr>
                    <tr>
                      <td>Time</td>
                      <td className="fwtd"> : {testride.slot}</td>
                    </tr>
                  </tbody>
                </table>
                {testride.status=='Cancelled'?
                
                (

                  <div className="editcancel mt-4 cancelled" >
                  <a  

                   disabled
                  
                  >Cancelled</a>
                </div>
                )
                
                :
                (

                  <div className="editcancel mt-4 " >
                  <a  

                   onClick={() => deleteHandler(testride.id)}
                  
                  >Cancel</a>
                </div>
                )
                }


                 
              </div>
            </div>
            
          ))}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default UserTestrideScreen