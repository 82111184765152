import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { listProductCategories } from '../../actions/productActions';
import { signout } from '../../actions/userActions';
import { ROOT_PATH } from '../../constants/cartConstants';
// import LoadingBox from '../../components/LoadingBox';
// import MessageBox from '.../../components/MessageBox';

function UserDashboardScreen() {


  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  return (
   <>
  <section className="signinnsignup">
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-12 col-xs-12">
        <div className="dashboard">
          <a href="/userdashboard">
            <div className="media">
              <img className="mr-3" src="images/sadboard-icon.png" alt="" />
              <div className="media-body">
                <h5 className="mt-0"> Hello</h5>
                <p>{userInfo.name}</p>
              </div>
            </div>
          </a>

          {(userInfo?.isAdmin==false && userInfo?.isSeller==false)  && (
          <div className="bookingride">
            <ul>
              <a href="/userbooking">
                <li>
                  <img src="images/bookingimg.png" /> <span>Bookings</span>
                </li>
              </a>
              <a href="/usertestride">
                <li>
                  <img src="images/testrideimg.png" /> <span>Test Rides</span>
                </li>
              </a>
              <a href="/usercontactus">
                <li className="active">
                  <img src="images/requestimg.png" /> <span>Request</span>
                </li>
              </a>
            </ul>
          </div>
          )}

        </div>
      </div>
      <div className="col-md-9 col-sm-12 col-xs-12">
  <div className="requestid">
    <div className="personalinfo">
      <h5>Personal Information</h5>
      <h4 className="float-right editoption"><a href="/profile">Edit</a></h4>
      <table>
        <tbody>
          <tr>
            <td>
              <b>Name</b>
            </td>
            <td className="fwtd"> : {userInfo.name}</td>
          </tr>
          <tr>
            <td>
              <b>Phone</b>
            </td>
            <td className="fwtd"> : {userInfo.mobile}</td>
          </tr>
          <tr>
            <td>
              <b>Email</b>
            </td>
            <td className="fwtd"> : {userInfo.email}</td>
          </tr>
          <tr>
            <td>
              <b>City</b>
            </td>
            <td className="fwtd"> : {userInfo.city_name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

    </div>
  </div>
</section>


   </>
  )
}

export default UserDashboardScreen